<script>
	// Components
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";

	// Helpers
	import { language, patient, user } from "../../../helpers/storage/stores";
	import { currentSession } from "../../../helpers/storage/sessions";
	import { formatDate } from "../../../helpers/helpers";
	import {
		utils_files_get,
		generic_clinical_history_get,
		generic_exam_detail_get,
		generic_interview_detail_get,
		generic_questionnaire_results_get,
	} from "../../../helpers/apiBackend";

	// Svelte
	import { _ } from "svelte-i18n";
	import { onMount } from "svelte";

	// Export to PDF
	import { jsPDF } from "jspdf";

	// Variables
	let loading = true;
	let complexItemsStatus = [];
	let type = "";
	let dataAdjunto = [];
	let myHistoryClinicList = [];
	let selectedEvolution = "";
	let modalEvolution = false;
	let myPatientId = "";
	let errorMessage = "";
	let resultsAnswer = [];
	let modalViewAnswer = false;
	let error = null;
	let patientFullName = "";

	async function setSelectData(data) {
		console.log({ data });
		let result;
		if (data.type == "i") {
			result = await generic_interview_detail_get(data.Id, $language);
			selectedEvolution = result.data.data;
		}
		if (data.type == "e") {
			result = await generic_exam_detail_get(data.Id, $language);
			selectedEvolution = result.data.data;
		}
		if (data.type == "q") {
			result = await generic_questionnaire_results_get(data.Id);
			selectedEvolution = result.data;
		}
		console.log("setSelectData", selectedEvolution);
		return result;
	}

	/**
	 * @description
	 */
	onMount(async () => {
		if ($user.hasOwnProperty("Especialidades__r")) {
			myPatientId = $patient.Id;
		} else {
			myPatientId = $user.Id;
		}
		patientFullName = $patient.name || 'patient'; // Asegúrate de ajustar según el nombre correcto en el store
		complexItemsStatus = $_("ClinicHistory.select_status_detail");
		console.log("ComplexItem", complexItemsStatus);
		document.querySelector("#buscar").click();
	});

	// FN para generar pdf pero desde un json
	function generateHistoryPDF_FromJSon(historyData = null) {
		const doc = new jsPDF();
		const margin = 10; // Margen en la página
		const pageWidth = doc.internal.pageSize.width; // Ancho de la página
		const pageHeight = doc.internal.pageSize.height; // Altura de la página
		let y = margin; // Posición vertical inicial
		const lineHeight = 8; // Altura de línea
		const contentWidth = pageWidth - 2 * margin;

		function splitByLength(str, length) {
			let result = [];
			for (let i = 0; i < str.length; i += length) {
				result.push(str.slice(i, i + length));
			}
			return result;
		}

		// Función para agregar texto con opciones de alineación
		function addText(text, x, lineHeight, fontSize = 12, bold = false, align = "left") {
			if (text.length > 100) {
				let array = splitByLength(text, 100);
				array.forEach((newText) => addText(newText, x, lineHeight, fontSize, bold, align));
			} else {
				if (y + lineHeight > pageHeight - margin) {
					doc.addPage();
					y = margin;
				}
				if (bold) {
					doc.setFont("helvetica", "bold");
				} else {
					doc.setFont("helvetica", "normal");
				}
				doc.setFontSize(fontSize);

				let textX = x; // Posición inicial del texto

				// Alinear texto según la opción dada
				if (align === "center") {
					textX = pageWidth / 2;
					doc.text(text, textX, y, { align: "center" });
				} else if (align === "right") {
					textX = pageWidth - margin;
					doc.text(text, textX, y, { align: "right" });
				} else {
					doc.text(text, textX, y);
				}

				y += lineHeight;
			}
		}

		// Agregar título centrado
		doc.setFontSize(16);
		addText("Historia Clínica: " + patientFullName, margin, lineHeight, 16, true, "center");
		addText("------------------------------------------------------------------------------------------------", margin, lineHeight, 12, false, "center");
		y += 4; // Espacio para el título

		// Agregar contenido del JSON alineado a la izquierda
		historyData.forEach((item) => { 
			let activity_label = "";
			if (item.type == "i") {
				activity_label = "Cita / Entrevista";
			}
			if (item.type == "e") {
				activity_label = "Estudio";
			}
			if (item.type == "q") {
				activity_label = "Cuestionario";
			}
			if (item.type == "m") {
				activity_label = "Medicamento";
			}

			addText(`Actividad: ${activity_label}`, margin, lineHeight, 12, false, "left");
			y -= lineHeight;
			addText(`Fecha: ${item.dateCompleted}`, margin, lineHeight, 12, false, "right");

			addText(`Detalle: ${item.name}`, margin, lineHeight, 12, false, "left");
			y -= lineHeight;
			addText(`Evolucionado por: ${item.evolvedByName}`, margin, lineHeight, 12, false, "right");

			addText(`Evolución: ${item.evolution}`, margin, lineHeight, 12, false, "left");

			addText("------------------------------------------------------------------------------------------------", margin, lineHeight, 12, false, "center");
		});

		// Descargar el PDF
		doc.save(`Historia Clinica - ${patientFullName}.pdf`);
	}

	// Función para generar el PDF del historial clínico
	function generateHistoryPDF() {
		const doc = new jsPDF();
		const margin = 10; // Margen en la página
		const pageWidth = doc.internal.pageSize.width; // Ancho de la página
		const pageHeight = doc.internal.pageSize.height; // Altura de la página
		let y = margin; // Posición vertical inicial
		const lineHeight = 10; // Altura de línea

		// Obtener el contenido HTML del div con historial clínico
		const element = document.querySelector(".container.container-history");
		if (!element) {
			console.error("Elemento no encontrado: .container.container-history");
			return;
		}

		// Convertir el contenido HTML a texto
		const content = element.innerText;

		function addText(text, x, lineHeight, fontSize = 12, bold = false) {
			if (y + lineHeight > pageHeight - margin) {
				doc.addPage();
				y = margin;
			}
			if (bold) {
				doc.setFont("helvetica", "bold");
			} else {
				doc.setFont("helvetica", "normal");
			}
			doc.setFontSize(fontSize);
			doc.text(text, x, y);
			y += lineHeight;
		}

		// Agregar título
		doc.setFontSize(16);
		addText("Historial Clínico", margin, lineHeight, 16, true);
		y += 12; // Espacio para el título

		// Agregar contenido
		addText(content, margin, lineHeight);

		// Descargar el PDF
		doc.save("historial_clinico.pdf");
	}

	function generatePDF() {
		const doc = new jsPDF();
		const margin = 10; // Margen en la página
		const pageWidth = doc.internal.pageSize.width; // Ancho de la página
		const pageHeight = doc.internal.pageSize.height; // Altura de la página
		let y = margin; // Posición vertical inicial
		const lineHeight = 10; // Altura de línea
		const questionWidth = pageWidth - 2 * margin - 100; // Ancho disponible para la pregunta (ajustado)
		const answerMargin = 20; // Espacio entre pregunta y respuesta

		// Agregar título
		doc.setFontSize(16);
		doc.text("Resultados del cuestionario", margin, y);
		y += 12; // Espacio para el título

		function addText(text, x, lineHeight, fontSize = 12, bold = false) {
			if (y + lineHeight > pageHeight - margin) {
				doc.addPage();
				y = margin;
			}
			if (bold) {
				doc.setFont("helvetica", "bold");
			} else {
				doc.setFont("helvetica", "normal");
			}
			doc.setFontSize(fontSize);
			doc.text(text, x, y);
			y += lineHeight;
		}

		function addQuestionAndAnswer(question, answers, isBold = false) {
			// Calcular líneas necesarias para la pregunta
			const questionLines = doc.splitTextToSize(question, questionWidth);
			questionLines.forEach((line, index) => {
				addText(line, margin, lineHeight, 12, isBold);
				if (index === 0) {
					answers.forEach((answer) => {
						const answerText = `- ${answer}`;
						if (y + lineHeight > pageHeight - margin) {
							doc.addPage();
							y = margin;
						}
						addText(answerText, margin + questionWidth + answerMargin, lineHeight);
					});
				}
			});
		}

		function addTable(question, answers) {
			const tableWidth = pageWidth - 2 * margin;
			const cellPadding = 5;
			const rowHeight = 10;
			const colWidths = [tableWidth * 0.8, tableWidth * 0.2]; // Anchos de columnas

			// Agregar filas de la tabla
			answers.forEach((answer) => {
				if (y + rowHeight > pageHeight - margin) {
					doc.addPage();
					y = margin;
				}
				doc.rect(margin, y, tableWidth, rowHeight);
				doc.text(question, margin + cellPadding, y + rowHeight / 2);
				doc.text(answer, margin + colWidths[0] + cellPadding, y + rowHeight / 2);
				y += rowHeight;
			});
		}

		// Agregar resultados
		if (resultsAnswer && resultsAnswer.length > 0) {
			resultsAnswer
				.filter((resultado) => resultado.questionType != "inform")
				.forEach((resultado) => {
					if (resultado.questionType === "rta" && resultado.answerTexts.length > 0) {
						addQuestionAndAnswer(resultado.questionText, resultado.answerTexts);
					}

					if (resultado.questionType === "grid" && resultado.options.length > 0) {
						addText(resultado.questionDescription, margin, lineHeight, 12); // Pregunta principal en negrita

						resultado.options.forEach((option) => {
							addTable(option.questionText, option.answerTexts);
						});

						y += lineHeight; // Espacio adicional después de cada opción
					}
				});

			// Agregar informe
			const informResults = resultsAnswer.filter((resultado) => resultado.questionType === "inform");
			if (informResults.length > 0) {
				addText("Informe de resultados:", margin, lineHeight, 14, true); // Título del informe en negrita

				informResults.forEach((resultado) => {
					if (resultado.answerTexts && resultado.answerTexts.length > 0) {
						addTable(resultado.questionText.slice(1), resultado.answerTexts);
					}
				});
			}
		} else {
			addText("No hay resultados disponibles.", margin, lineHeight);
		}

		// Descargar el PDF
		doc.save("resultados_cuestionario.pdf");
	}

	function getViewAnswer(questionnaireId) {
		//modalLoadingAnimation = true; // Mostrar animación de carga

		// Llamar a la función de la API para obtener los resultados del cuestionario
		generic_questionnaire_results_get(questionnaireId)
			.then((res) => {
				console.log({ res });
				if (res.statusCode === 200) {
					console.log("Resultados del cuestionario obtenidos correctamente");
					//modalLoadingAnimation = false; // Ocultar animación de carga
					resultsAnswer = res.data; // Asignar los resultados a la variable resultsAnswer
					modalViewAnswer = true; // Mostrar el modal con los resultados
					console.log({ resultsAnswer });
				} else {
					console.error("Error al obtener los resultados del cuestionario:", res.message);
					modalLoadingAnimation = false; // Ocultar animación de carga en caso de error
					// Manejar el error de acuerdo a tus necesidades
				}
			})
			.catch((err) => {
				console.error("Error al obtener los resultados del cuestionario:", err);
				modalLoadingAnimation = false; // Ocultar animación de carga en caso de error
				// Manejar el error de acuerdo a tus necesidades
			});
	}
</script>

<section>
	<div class="d-flex justify-content-between form-group shadow p-4 rounded myCustom-colorBlue">
		<div class="container">
			<div class="border-bottom text-muted">
				<h3>{$_("ClinicHistory.text_title")}</h3>
			</div>

			<div class="container_filter">
				<div
					class="date-info"
					style="width: 100%;/* margin-right: 5%; */margin: 2%;display: flex;align-items: center;justify-content: space-between;align-content: center;">
					<div style="display: flex;align-items: center;width: 80%;margin-right: 2%;">
						<label
							for="filtro-altas"
							style="
    width: 100px;
">{$_("ClinicHistory.text_date_from")}:</label>
						<input
							class="input-log"
							type="date"
							id="fecha_desde"
							pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
							name="trip-start" />
					</div>
					<div style="display: flex;align-items: center;width: 80%;margin-right: 2%;">
						<label
							for="filtro-altas"
							style="
    width: 100px;
">{$_("ClinicHistory.text_date_to")}:</label>
						<input
							class="input-log"
							type="date"
							id="fecha_hasta"
							pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
							name="trip-start" />
					</div>
					<div style="display: flex;align-items: center;width: 80%;margin-right: 2%;">
						<label for="filtro-altas">{$_("ClinicHistory.text_Professional")}:</label>
						<input id="profesional" class="input-log" type="text" />
					</div>
				</div>

				<div
					class="date-info"
					style="
    width: 30%;
">
					<div
						style="
    display: flex;
    justify-content: flex-end;
">
						<button
							id="buscar"
							class="btnBlue"
							on:click={async () => {
								//Invalid date: 2022-12-23
								let fecha_desde_parseada =
									document.querySelector("#fecha_desde").value.length > 0
										? `${document.querySelector("#fecha_desde").value.split("-")[2]}/${
												document.querySelector("#fecha_desde").value.split("-")[1]
											}/${document.querySelector("#fecha_desde").value.split("-")[0]}`
										: "";
								let fecha_hasta_parseada =
									document.querySelector("#fecha_hasta").value.length > 0
										? `${document.querySelector("#fecha_hasta").value.split("-")[2]}/${
												document.querySelector("#fecha_hasta").value.split("-")[1]
											}/${document.querySelector("#fecha_hasta").value.split("-")[0]}`
										: "";
								await generic_clinical_history_get(
									myPatientId,
									fecha_desde_parseada,
									fecha_hasta_parseada,
									"",
									"",
									//document.querySelector("#tipo_actividad").value,
									//document.querySelector("#sub_categoria").value,
									document.querySelector("#profesional").value,
									$language
								)
									.then((data) => {
										console.log(data);
										if (data.statusCode == 404 || data.error == true) {
											myHistoryClinicList = [];
											errorMessage = data.message;
										} else {
											myHistoryClinicList = data.data;
											console.log("JSON Historia", { myHistoryClinicList });
										}
									})
									.catch((getClinicHistoryError) => {
										console.log({ getClinicHistoryError });
									})
									.finally(() => {
										loading = false;
									});
							}}>{$_("ClinicHistory.button_filter")}</button>
						<button
							id="descargar-hist"
							class="btnBlue"
							style="padding: 10px 20px;"
							on:click={async () => {
								console.log("Descargar Historial");
								generateHistoryPDF_FromJSon(myHistoryClinicList); // Llama a la función para generar el PDF del historial clínico
							}}>
							{$_("Descargar")}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div />
	</div>
	<div class="container container-history bg-white border p-2 rounded shadow">
		<!-- list -->
		{#if myHistoryClinicList.length > 0}
			<div class="d-flex justify-content-between">
				<div style="width: 17vw;">
					<small
						class="text-muted"
						style="
    margin-left: 4%;
">{$_("ClinicHistory.text_activity")}</small>
				</div>
				<div style="width: 15vw;">
					<small class="text-muted">{$_("ClinicHistory.text_Subcategory")}</small>
				</div>
				<div style="width: 16vw;">
					<small class="text-muted">{$_("ClinicHistory.text_date")}</small>
				</div>
				<div style="width: 17vw;">
					<small class="text-muted">{$_("ClinicHistory.text_createdby")}</small>
				</div>
				<div style="width: 14vw;">
					<small class="text-muted">{$_("ClinicHistory.text_evolved_by")}</small>
				</div>

				<div>
					<small class="text-muted">{$_("ClinicHistory.text_viewDetails")}</small>
				</div>
			</div>
		{/if}
		<div>
			{#if loading}
				<div class="p-2 text-center">
					<Loader text={false} />
					<p class="text-muted">{$_("ClinicHistory.loading")}</p>
				</div>
			{:else if myHistoryClinicList.length == 0}
				<div class="py-3 text-center">
					<p class="m-0 text-muted">{$_("ClinicHistory.text_not_history")}</p>
				</div>
			{:else}
				<div class="my-2">
					{#each myHistoryClinicList as story}
						<div class="d-flex justify-content-between myCustom-clinicHistory p-2 my-1">
							<p class="m-0" style="width: 14vw;">
								{complexItemsStatus[story.type]}
							</p>
							<p class="m-0" style="width: 13vw;">
								{story.name ? story.name : "not data"}
							</p>
							<p class="m-0" style="width: 14vw;">{formatDate(story.dateCompleted, $language)}</p>

							<p class="m-0" style="width: 14vw;">
								{story.createdByName ? story.createdByName : "Sin comentarios"}
							</p>
							<p class="m-0" style="width: 14vw;">
								{story.evolvedByName ? story.evolvedByName : "N/A"}
							</p>
							<button
								on:click={async () => {
									if (story.type == "q") {
										resultsAnswer = getViewAnswer(story.Id);
										modalViewAnswer = true;
									} else {
										type = "";
										selectedEvolution = setSelectData(story);
										console.log({ selectedEvolution });
										modalEvolution = true;
										console.log({ story });
										dataAdjunto = await utils_files_get(story.Id);
										if (
											dataAdjunto.data.length > 0 &&
											dataAdjunto.data[0].hasOwnProperty("Link__c")
										) {
											type = dataAdjunto.data[0].Link__c.split("/")[3];
											type = type.split(".")[1];
											console.log({ type });
										} else {
											type = "none";
										}
									}
								}}
								style="background: none; border: none; padding: 0; cursor: pointer;">
								<img
									class="cursor-pointer"
									id="passworImag"
									src="/img/show-password1.png"
									alt="#!"
									style="heigth:25px;" />
							</button>
						</div>
					{/each}
				</div>
			{/if}
		</div>
		<div class="my-2 border-top">
			<!-- <a class="mx-2" href="#/Plan">Plan</a> -->
		</div>
	</div>
</section>

<!-- Loading Modal -->
<LayoutModal
	isOpenModal={modalEvolution}
	tam="40"
	on:closeModal={() => {
		modalEvolution = false;
	}}>
	<div class="p-3">
		{#if type !== "" && dataAdjunto}
			<h4>{$_("ClinicHistory.text_title_Evolution")}:</h4>
			<small
				>{$_("ClinicHistory.text_Made_by_the")}
				{selectedEvolution.evolvedByName ? selectedEvolution.evolvedByName : "no data"}:</small>
			<div class="container bg-white border p-2 rounded shadow">
				{#if selectedEvolution.diagnosis || selectedEvolution.evolution}
					<p class="m-0">
						{selectedEvolution.evolution ? selectedEvolution.evolution : selectedEvolution.diagnosis}
					</p>
					<!-- <p class="m-0">{selectedEvolution.Id}</p> -->
				{:else}
					<p class="m-0">{$_("ClinicHistory.text_not_evolution")}</p>
				{/if}
			</div>
			<br />
			<!-- <div class="container bg-white border p-2 rounded shadow">
				<h4>{$_("ClinicHistory.text_attach")}:</h4>
				{#if type !== "pdf" && dataAdjunto.data.length > 0 && dataAdjunto.data[0].Link__c}
					<img
						class="cursor pointer"
						src={dataAdjunto.data[0].Link__c}
						alt="Document"
						width="20%"
						height="20%" /><a href={dataAdjunto.data[0].Link__c} download
						>{$_("ClinicHistory.button_download")}</a>
				{/if}
				{#if type == "pdf" && dataAdjunto.data.length > 0 && dataAdjunto.data[0].Link__c}
					<img class="cursor pointer" src="img/pdfIcon.png" alt="PDF" width="5%" height="5%" /><a
						href={dataAdjunto.data[0].Link__c}
						download>{$_("ClinicHistory.button_download")}</a>
				{/if}
				{#if type == "none"}
					<small>{$_("ClinicHistory.text_not_attach")}</small>
				{/if}
			</div> -->

			<div>
				<br />
				<button
					on:click={() => {
						modalEvolution = false;
					}}
					class="btnWhite py-2">{$_("ClinicHistory.button_close")}</button>
			</div>
		{:else}
			<div class="p-2 text-center">
				<Loader text={false} />
				<p class="text-muted">{$_("ClinicHistory.loading")}</p>
			</div>
		{/if}
	</div>
</LayoutModal>

<!-- Modal view answer -->
<LayoutModal
	tam="60"
	isOpenModal={modalViewAnswer}
	on:closeModal={() => {
		modalViewAnswer = false;
	}}>
	<div id="pdf-content" class="p-3 text-start">
		{#if resultsAnswer && resultsAnswer.length > 0}
			<div>
				<h2 class="text-color" style="color: #112c4a;">Resultados del cuestionario</h2>
				<hr style="border: 1px dashed #ccc;" />

				<h4 class="text-color" style="color: #112c4a;">Respuestas:</h4>
				{#each resultsAnswer.filter((resultado) => resultado.questionType != "inform") as resultado}
					{#if resultado.questionType === "rta" && resultado.answerTexts.length > 0}
						<div><strong>{resultado.questionText}</strong></div>
						{#each resultado.answerTexts as answer}
							{#if answer != "Continuar." && answer != "Continuar"}
								<div>>>> {answer}</div>
							{/if}
						{/each}
						<hr style="border: 1px dashed #ccc;" />
					{/if}

					{#if resultado.questionType === "grid" && resultado.options.length > 0}
						<div><strong>{resultado.questionDescription}</strong></div>

						<table class="table table-bordered table-striped">
							<tbody>
								{#each resultado.options as option}
									<tr>
										<td>
											<div class="card-shadow rounded-xl" style="font-size: 10px;">
												<div class="inline-flex items-start">
													<div class="flex items-center gap-3">
														<div>
															<div
																class="w-7 h-7 rounded-full flex items-center justify-center"
																style="width: 20px; background-color: rgb(40, 114, 161);">
															</div>
														</div>
														<div
															class="text-color"
															style="
														color: #215273;
														font-family: &quot;Roboto&quot;, sans-serif;
														font-size: 12px;
														font-weight: bold;
													">
															{option.questionText}
														</div>
													</div>
												</div>
											</div>
										</td>
										<td>
											<div class="">
												<div class="inline-flex items-start">
													<div class="flex items-center gap-3">
														<div
															class="text-color"
															style="margin-left: 4%; font-size: 12px;">
															<!-- Mostrar cada respuesta en una nueva línea -->
															{option.answerTexts[0]}
														</div>
													</div>
												</div>
											</div>
										</td>
									</tr>
								{/each}
							</tbody>
						</table>
						<hr style="border: 1px dashed #ccc;" />
					{/if}
				{/each}

				{#if resultsAnswer.filter((resultado) => resultado.questionType === "inform").length > 0}
					<h4 class="text-color" style="color: #112c4a;">Informe de resultados:</h4>

					{#each resultsAnswer.filter((resultado) => resultado.questionType === "inform") as resultado}
						{#if resultado.answerTexts && resultado.answerTexts.length > 0}
							<div><strong>{resultado.questionText.slice(1)}</strong></div>
							{#each resultado.answerTexts as answer}
								{#if answer != "Continuar." && answer != "Continuar"}
									<div>>>> {answer}</div>
								{/if}
							{/each}
						{/if}
					{/each}
				{/if}

				<div class="button-container">
					<button
						on:click={() => {
							modalViewAnswer = false;
						}}
						class="btnClose btn btn-secondary">Cerrar</button>
					<button on:click={generatePDF} class="btnClosepdf">Descargar PDF</button>
					<!-- Botón para generar el PDF -->
				</div>
			</div>
		{:else if error !== null}
			<p>Ocurrió un error al obtener los resultados: {error}</p>
		{:else}
			<p>Cargando...</p>
		{/if}
	</div>
</LayoutModal>

<style>
	.button-container {
		display: flex;
		justify-content: center; /* Centra los botones horizontalmente */
		gap: 15px; /* Añade separación entre los botones */
		margin-top: 20px; /* Opcional: Añade margen superior para separación del contenido anterior */
	}

	.btnClose,
	.btnClosepdf {
		margin-top: 10px;
		letter-spacing: 1px;
		border-radius: 10px;
		color: #ffffff;
		background-color: var(--my-dark-blue);
	}

	.container-history {
		max-height: 400px;
		overflow-y: scroll;
	}
	#passworImag {
		height: 25px;
	}
	.myCustom-clinicHistory {
		border: solid 1px var(--my-dark-blue);
		border-radius: 10px;
	}
	.container {
		margin-left: 1%;
		max-width: 98%;
		height: auto;
	}
	.input-log {
		margin: 1%;
		height: var(--height, 42px);
		padding-left: 9px;
		border-radius: 20px;
		letter-spacing: 1px;
	}

	.container_filter {
		display: flex;
		align-items: center;
	}
</style>
