<script>
	import { onMount } from "svelte";
	import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import {
		generic_questionnaire_detail_get,
		generic_questionnaire_detail_update,
		generic_questionnaire_results_get,
		questionnaire_details_update_postcomplete,
	} from "../../../helpers/apiBackend";
	import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { _ } from "svelte-i18n";
	import { language, registerDetail, user, patient } from "../../../helpers/storage/stores";
	import { formatDate } from "../../../helpers/helpers";
	import { currentSession } from "../../../helpers/storage/sessions.js";
    import { jsPDF } from 'jspdf'; // Importa jsPDF

	let questionnaire_detail = {};
	//let progress = "100";
	let isOpen = false;
	let surveyView = false;
	let resultsAnswer = [];
	let error = null;
	let currentPage = 0;
	let totalPages = 0;
	let paginatedResults = [];
	const questionsPerPage = 3;
	let modalViewAnswer = false;
	let surveyViewButton = false;
	let patching = true;
	let myType = "";
	let myScope = "";
	let accountId = $user.Id;
	//let originalRecord = "";
	let myParams = "";
	let myToken = "";
	let modalLoadingAnimation = false;
	let modalEdit = false;
	let modalRequired = false;
	let requested = false;
	let dateSuggested = "";
	let linkQuestionPro = "";

	if ($currentSession.hasOwnProperty("signInUserSession")) {
		myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
	} else {
		alert("Error!");
	}

	const requestQuestionnaire = (data) => {
		console.log("Validar...", data);
		if (!data || !data.dateSuggested || data.dateSuggested === "") {
			console.error("Error: Se requieren todos los datos en el objeto 'requestQuestionnaire'");
			modalLoadingAnimation = false;
			modalRequired = true;
			const inputElement = document.querySelector(".input-log");
			inputElement.style.borderBottom = "2px solid red";
			setTimeout(() => {
				inputElement.style.borderBottom = "2px solid #116ac9";
			}, 3000);
		} else {
			modalLoadingAnimation = true;
			modalRequired = false;

			console.log("Solicitando...", data);
			let dataQuestionnaire = {
				cpqdu: {
					Id: questionnaire_detail.Id,
					requested: data.requested,
					completed: questionnaire_detail.completed,
					dateSuggested: data.dateSuggested,
					dateCompleted: questionnaire_detail.dateCompleted,
				},
			};
			console.log({ dataQuestionnaire });
			generic_questionnaire_detail_update(dataQuestionnaire)
				.then((res) => {
					console.log({ res });
					if (res.statusCode === 201) {
						console.log("Actualizado correctamente");
						modalLoadingAnimation = false;
						questionnaire_detail.requested = data.requested;
						questionnaire_detail.dateSuggested = data.dateSuggested;

						// Realizar acciones adicionales si es necesario
					} else {
						console.error("Error al actualizar:", res.message);
						// Manejar el error de acuerdo a tus necesidades
					}
				})
				.catch((err) => {
					console.error("Error al actualizar:", err);
					// Manejar el error de acuerdo a tus necesidades
				});
		}
	};

	const editQuestionnaire = (data) => {
		console.log("Editando...", data);
		modalLoadingAnimation = true;
		modalEdit = false;
		if ((data.requested && data.dateSuggested == null) || data.dateSuggested == "") {
			console.error("Error: Se requiere la fecha sugerida cuando la solicitud está activa");
			// Marcar el input con id="startPersonBirthdate" en rojo
			modalLoadingAnimation = false;
			modalEdit = true;
			const inputElement = document.querySelector(".input-log-edit");
			inputElement.style.borderBottom = "2px solid red";
			setTimeout(() => {
				inputElement.style.borderBottom = "2px solid #116ac9";
			}, 3000);
		} else {
			console.log(data.dateSuggested);
			let dataQuestionnaire = {
				cpqdu: {
					Id: questionnaire_detail.Id,
					requested: data.requested,
					completed: questionnaire_detail.completed,
					dateSuggested: data.dateSuggested ? data.dateSuggested : questionnaire_detail.dateSuggested,
					dateCompleted: questionnaire_detail.dateCompleted,
				},
			};
			console.log({ dataQuestionnaire });
			generic_questionnaire_detail_update(dataQuestionnaire)
				.then((res) => {
					console.log({ res });
					if (res.statusCode === 201) {
						console.log("Actualizado correctamente");
						modalLoadingAnimation = false;
						questionnaire_detail.requested = data.requested;
						questionnaire_detail.dateSuggested = data.dateSuggested;

						// Realizar acciones adicionales si es necesario
					} else {
						console.error("Error al actualizar:", res.message);
						// Manejar el error de acuerdo a tus necesidades
					}
				})
				.catch((err) => {
					console.error("Error al actualizar:", err);
					// Manejar el error de acuerdo a tus necesidades
				});
		}

		// Agregar lógica para editar aquí
	};
	function updatePaginatedResults() {
		let startIndex = currentPage * questionsPerPage;
		let endIndex = startIndex + questionsPerPage;
		console.log({ startIndex });
		console.log({ endIndex });
		totalPages = Math.ceil(resultsAnswer.length / questionsPerPage);
		paginatedResults = resultsAnswer.slice(startIndex, endIndex);
		console.log({ paginatedResults });
	}

	function changePage(step) {
		currentPage += step;
		console.log({ currentPage });
		if (currentPage < 0) {
			currentPage = 0;
		} else if (currentPage >= totalPages) {
			currentPage = totalPages > 0 ? totalPages - 1 : 0;
		}
		console.log({ totalPages });
		updatePaginatedResults();
	}

	function closeMenu() {
		isOpen = false;
	}

	async function validateQuestionnaireId(questionnaireId) {
		console.log({ questionnaireId });
		//modalLoadingAnimation = true;
		var today = new Date();
		
		let data = {
			Id: questionnaireId,
			accountId: $user.Id
		};

		try {
			//modalLoadingAnimation = true;
			const res = await questionnaire_details_update_postcomplete(data);
			console.log("UpdatePostComplete",{res})
			if (res.statusCode == 201) {
				//questionnaire_detail["completed"] = true;
				//modalLoadingAnimation = false;
				return true; // Retorna true si la actualización se realiza correctamente
			} else {
				//modalLoadingAnimation = false;
				return false; // Retorna false si hay algún error en la actualización
			}
		} catch (error) {
			//modalLoadingAnimation = false;
			console.error("Error al realizar la solicitud:", error);
			return false; // Retorna false si ocurre algún error
		}
	}

	// Función para cerrar el iframe

	function checkVisibility() {
		try {
			var thankYouAd = document.querySelector(".thank-you-ad");
			if (thankYouAd) {
				var rect = thankYouAd.getBoundingClientRect();
				console.log("El elemento thank-you-ad está visible en la pantalla:", rect);
			} else {
				console.log(
					"El elemento thank-you-ad no está presente en el DOM o no tiene la propiedad scrollHeight definida."
				);
			}
		} catch (error) {
			// Captura la excepción y muestra un mensaje de error en la consola
			surveyViewButton = false;
			console.log("Finaliza ACAAAA");
		}
	}
	function getViewAnswer(questionnaireId) {
		//modalLoadingAnimation = true; // Mostrar animación de carga

		// Llamar a la función de la API para obtener los resultados del cuestionario
		generic_questionnaire_results_get(questionnaireId)
			.then((res) => {
				console.log({ res });
				if (res.statusCode === 200) {
					console.log("Resultados del cuestionario obtenidos correctamente");
					//modalLoadingAnimation = false; // Ocultar animación de carga
					resultsAnswer = res.data; // Asignar los resultados a la variable resultsAnswer
					modalViewAnswer = true; // Mostrar el modal con los resultados
					console.log({ resultsAnswer });
				} else {
					console.error("Error al obtener los resultados del cuestionario:", res.message);
					modalLoadingAnimation = false; // Ocultar animación de carga en caso de error
					// Manejar el error de acuerdo a tus necesidades
				}
			})
			.catch((err) => {
				console.error("Error al obtener los resultados del cuestionario:", err);
				modalLoadingAnimation = false; // Ocultar animación de carga en caso de error
				// Manejar el error de acuerdo a tus necesidades
			});


	}

	onMount(async () => {
		let Id = $registerDetail["detail"]["Id"];

		await validateQuestionnaireId(Id);

		myType = "Cuestionarios";
		// Validate Parameters (RecordType / Scope)
		delete $registerDetail["Actividad_Detalles__r"];
		myParams = document.location.href.split("?")[1];
		if (myParams.indexOf("&") > -1) {
			myParams = myParams.split("&");
			myType = myParams[0].split("=")[1];
			myScope = myParams[1].split("=")[1];
		}
		if (myScope == undefined || myScope == "" || myType == undefined || myType == "") {
			window.location.href = "#/GeneralError";
		}
		
		await generic_questionnaire_detail_get(Id, $language).then((res) => {
			console.log({ res });
			if (res.statusCode == 200) {
				questionnaire_detail = res.data;
				linkQuestionPro = questionnaire_detail["qestionnaireCareplan"][0].linkQuestionPro;
			}
			console.log({ questionnaire_detail });
			console.log("linkQuestion", linkQuestionPro);
			patching = false;
		});
		requested = questionnaire_detail.requested;
		dateSuggested = questionnaire_detail.dateSuggested;
		//checkVisibility();
		
		updatePaginatedResults();
		//setInterval(checkVisibility, 5000);
	});
	
	function generatePDF() {
    const doc = new jsPDF();
    const margin = 10; // Margen en la página
    const pageWidth = doc.internal.pageSize.width; // Ancho de la página
    const pageHeight = doc.internal.pageSize.height; // Altura de la página
    let y = margin; // Posición vertical inicial
    const lineHeight = 10; // Altura de línea
	const questionWidth = pageWidth - 2 * margin - 100; // Ancho disponible para la pregunta (ajustado)
    const answerMargin = 20; // Espacio entre pregunta y respuesta

    // Agregar título
    doc.setFontSize(16);
    doc.text('Resultados del cuestionario', margin, y);
    y += 12; // Espacio para el título

    function addText(text, x, lineHeight, fontSize = 12, bold = false) {
      if (y + lineHeight > pageHeight - margin) {
        doc.addPage();
        y = margin;
      }
      if (bold) {
        doc.setFont("helvetica", "bold");
      } else {
        doc.setFont("helvetica", "normal");
      }
      doc.setFontSize(fontSize);
      doc.text(text, x, y);
      y += lineHeight;
    }

    function addQuestionAndAnswer(question, answers, isBold = false) {
     
      // Calcular líneas necesarias para la pregunta
      const questionLines = doc.splitTextToSize(question, questionWidth);
      questionLines.forEach((line, index) => {
        addText(line, margin, lineHeight, 12, isBold);
        if (index === 0) {
          answers.forEach(answer => {
            const answerText = `- ${answer}`;
            if (y + lineHeight > pageHeight - margin) {
              doc.addPage();
              y = margin;
            }
            addText(answerText, margin + questionWidth + answerMargin, lineHeight);
          });
        }
      });
      
    }

    function addTable(question, answers) {
      const tableWidth = pageWidth - 2 * margin;
      const cellPadding = 5;
      const rowHeight = 10;
      const colWidths = [tableWidth * 0.8, tableWidth * 0.2]; // Anchos de columnas

     

      // Agregar filas de la tabla
      answers.forEach(answer => {
        if (y + rowHeight > pageHeight - margin) {
          doc.addPage();
          y = margin;
        }
        doc.rect(margin, y, tableWidth, rowHeight);
        doc.text(question, margin + cellPadding, y + rowHeight / 2);
        doc.text(answer, margin + colWidths[0] + cellPadding, y + rowHeight / 2);
        y += rowHeight;
      });

    }

    // Agregar resultados
    if (resultsAnswer && resultsAnswer.length > 0) {
      resultsAnswer.filter(resultado => resultado.questionType != "inform").forEach(resultado => {
        if (resultado.questionType === "rta" && resultado.answerTexts.length > 0) {
          addQuestionAndAnswer(resultado.questionText, resultado.answerTexts);
        }

        if (resultado.questionType === "grid" && resultado.options.length > 0) {
          addText(resultado.questionDescription, margin, lineHeight, 12); // Pregunta principal en negrita

          resultado.options.forEach(option => {
            addTable(option.questionText, option.answerTexts);
          });

          y += lineHeight; // Espacio adicional después de cada opción
        }
      });

      // Agregar informe
      const informResults = resultsAnswer.filter(resultado => resultado.questionType === "inform");
      if (informResults.length > 0) {
        addText('Informe de resultados:', margin, lineHeight, 14, true); // Título del informe en negrita

        informResults.forEach(resultado => {
          if (resultado.answerTexts && resultado.answerTexts.length > 0) {
            addTable(resultado.questionText.slice(1), resultado.answerTexts);
          }
        });
      }
    } else {
      addText('No hay resultados disponibles.', margin, lineHeight);
    }

    // Descargar el PDF
    doc.save('resultados_cuestionario.pdf');
  }
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	{#if patching}
		<div class="p-5 text-center">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailQuestion.loading")}</p>
		</div>
	{:else}
		<!-- <LayoutPatient> -->
		<div class="content">
			<div class="conteiner justify-content-start">
				<div class="my-record">
					<div class="conteiner-detail py-2 border rounded shadow-sm bg-white">
						<div class="shadow-sm" style="width: 100%; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);">
							<h5
								class="text-reduce text-muted"
								style="
    margin-left: 2%;
">
								{myType} | {questionnaire_detail.name}
							</h5>
						</div>

						{#if questionnaire_detail != {} && !surveyView}
							<div class="form-detail">
								<div class="row">
									<!-- requested -->
									<div class="column">
										<p class="requested">
											{$_("RegisterDetailQuestion.text_required")}
										</p>
										{#if questionnaire_detail["requested"]}
											<!-- <i class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
											<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{:else}
											<!-- <i class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
											<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">

										{/if}
									</div>
									<!-- completed -->
									<div class="column">
										<p class="text_complete">
											{$_("RegisterDetailQuestion.text_complete")}
										</p>
										{#if questionnaire_detail["completed"]}
										<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
										{:else}
										<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
										{/if}
									</div>
									<!-- createdByName -->
									<div class="column">
										<p class="createdByName">
											{$_("RegisterDetailQuestion.text_th_createdby")}
										</p>
										{#if questionnaire_detail.hasOwnProperty("createdByName")}
											{questionnaire_detail["createdByName"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_not_State")}</span>
										{/if}
									</div>
								</div>
							</div>
							<div class="form-detail">
								<div class="row">
									<div class="column">
										<p class="requested">
											{$_("RegisterDetailQuestion.text_dateSuggested")}
										</p>
										{#if questionnaire_detail["dateSuggested"]}
											{formatDate(questionnaire_detail["dateSuggested"], $language)}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_not_assigned")}</span>
										{/if}
									</div>
									<div class="column">
										<p class="dateCompleted">
											{$_("RegisterDetailQuestion.text_survey_date_complete")}
										</p>
										{#if questionnaire_detail.hasOwnProperty("dateCompleted") && questionnaire_detail["dateCompleted"] != "" && questionnaire_detail["dateCompleted"] != null}
											{formatDate(questionnaire_detail["dateCompleted"], $language)}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailProf.text_th_session_not_date")}</span>
										{/if}
									</div>
									<!-- dateSuggested -->

									<div class="column">
										<p class="dateReal">
											{$_("RegisterDetailQuestion.text_survey_date_create")}
										</p>
										{#if questionnaire_detail["dateReal"]}
											{formatDate(questionnaire_detail["dateReal"], $language)}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_th_not_dateReal")}</span>
										{/if}
									</div>
								</div>
							</div>

							<div class="form-detail">
								<div class="row">
									<div class="column">
										<p class="requested">
											{$_("RegisterDetailQuestion.text_Picklist_Scale_Person")}
										</p>
										{#if $registerDetail["register"]["Picklist_Scale_Person"]}
											{$registerDetail["register"]["Picklist_Scale_Person"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailQuestion.text_not_assigned")}</span>
										{/if}
									</div>
									<div class="column">
										<p class="dateCompleted">
											{$_("RegisterDetailQuestion.text_toWhom")}
										</p>
										{#if $registerDetail["register"]["toWhom"]}
											{$registerDetail["register"]["toWhom"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailProf.text_th_session_not_date")}</span>
										{/if}
									</div>
									<!-- dateSuggested -->

									<div class="column">
										<p class="dateReal">
											{$_("RegisterDetailQuestion.text_variable")}
										</p>
										{#if $registerDetail["register"]["variable"]}
											{$registerDetail["register"]["variable"]}
										{:else}
											<span class="text-muted"
												>{$_("RegisterDetailProf.text_th_session_not_date")}</span>
										{/if}
									</div>
								</div>
							</div>

							<div class="form-detail">
								<br />
								<div class="d-flex justify-content-center">
									{#if !questionnaire_detail.requested}
										<button
											on:click={() => {
												modalRequired = true;
											}}
											class="btnAdd">
											{$_("RegisterDetailQuestion.button_Request")}</button>
									{/if}

									{#if !questionnaire_detail.completed}
										<button
											on:click={() => {
												requested = questionnaire_detail.requested;
												dateSuggested = questionnaire_detail.dateSuggested;
												modalEdit = true;
											}}
											class="btnWhite ml-3">{$_("RegisterDetailQuestion.button_Edit")}</button>
									{/if}

									{#if questionnaire_detail.requested && !questionnaire_detail.completed && questionnaire_detail.externalLink != null}
										<button
											on:click={() => {
												if (questionnaire_detail.externalId === null) {
													window.open(questionnaire_detail.externalLink, "_blank");
													window.location.href = "#/ViewCuestionarioProf?type=Cuestionarios";
												} else {
													window.open(linkQuestionPro, "_blank");
													window.location.href = "#/ViewCuestionarioProf?type=Cuestionarios";
												}
											}}
											class="btnBlue ml-3"
											>{$_("RegisterDetailQuestion.button_to_complete")}</button>
									{/if}
									{#if questionnaire_detail.completed}
										<button
											on:click={() => {
												//	console.log("Completar", myType);
												//	modalComplete = true;
												resultsAnswer = getViewAnswer(questionnaire_detail.Id);
												modalViewAnswer = true;
											}}
											class="btnBlue ml-3">{$_("RegisterDetailQuestion.button_answers")}</button>
									{/if}
								</div>
							</div>
						{:else}
							<div class="container-miIframe">
								<!-- svelte-ignore a11y-missing-attribute -->
								<iframe id="miIframe" src={linkQuestionPro} width="100%" height="500px"></iframe>
							</div>
							<!-- <div class="finally_button_contein">
										{#if surveyViewButton}
											<button
												class="finally_button"
												style="cursor: pointer; padding: 0.8rem 0px;"
												on:click={() => {
													completeQuestionnaireValidate();
												}}>Finalizar</button>
										{/if}
									</div> -->
						{/if}
					</div>

					<div class=" d-flex justify-content-center" style="vertical-align: center;">
						{#if myType}
							{#if $user.hasOwnProperty("Especialidades__r")}
								{#if !surveyView}
									<a class="mx-2" style="padding: 1rem 0;" href="#/ViewCuestionarioProf?type={myType}"
										>Volver a {myType}</a>
								{:else}
									<!-- svelte-ignore a11y-missing-attribute -->
									<a
										class="mx-2"
										style="cursor: pointer; padding: 1rem 0px;"
										on:click={() => {
											questionnaire_detail["completed"] = validateQuestionnaireId(
												questionnaire_detail["Id"]
											);
											surveyView = false;
										}}>Volver atras</a>
								{/if}
							{:else}
								<a
									class="mx-2"
									style="padding: 1rem 0;"
									href="#/Patient/ViewCuestionarioPatient?type={myType}">Volver a {myType}</a>
							{/if}
						{/if}
						<a class="mx-2" style="padding: 1rem 0;" href="#/Plan">Volver al plan</a>
					</div>
				</div>
			</div>
		</div>
		<!-- </LayoutPatient> -->
	{/if}
	<!-- </LayoutNav> -->
</section>

<!-- Modal Cargando -->
<LayoutModal
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="p-3 text-center">
		<Loader text={false} />
		<p class="text-muted">{$_("RegisterDetailQuestion.loading")}</p>
	</div>
</LayoutModal>

<!-- Modal Edit -->
<LayoutModal isOpenModal={modalEdit}>
	<div>
		{#if questionnaire_detail.hasOwnProperty("name")}
			<div class="p-3 text-center">
				<h5 class="text-muted">{$_("RegisterDetailQuestion.button_Edit")} {questionnaire_detail["name"]}</h5>
				<div
					class="p-2"
					style="
    display: flex;
    flex-direction: column;
">
					<div class="d-flex" style="display: flex;justify-content: space-between;margin-top: 2%;">
						<small class="text-muted">{$_("RegisterDetailQuestion.text_required")}</small>
						<p class="m-0 mx-2">
							{#if questionnaire_detail.hasOwnProperty("requested")}
								{#if requested}
									<!-- <i
										on:click={() => {
											requested = false;
										}}
										class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
										<button
												on:click={() => {
													requested = false;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleon.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{:else}
										<button
										on:click={() => {
											requested = true;
										}}
										style="background: none; border: none; padding: 0; cursor: pointer;">
										<img src="./img/toggleoff.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
									</button>
								{/if}
							{/if}
						</p>
					</div>

					<div
						class="form-group"
						style="
    display: flex;
    justify-content: space-between;
">
						<small class="text-muted">{$_("RegisterDetailQuestion.text_dateSuggested")}</small>
						<input
							class="input-log-edit"
							type="date"
							id="startPersonBirthdate"
							pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
							name="trip-start"
							bind:value={dateSuggested} />
					</div>
				</div>
			</div>
		{/if}
		<div class="d-flex justify-content-center">
			<button
				on:click={() => {
					editQuestionnaire({ dateSuggested, requested });
				}}
				class="py-0 mx-2 py-2 btnBlue">{$_("RecordDoctor.button_Send")}</button>

			<button
				class="py-0 mx-2 py-2 btnWhite"
				on:click={() => {
					modalEdit = false;
				}}>{$_("RecordDoctor.button_cancel")}</button>
		</div>
	</div>
</LayoutModal>

<LayoutModal isOpenModal={modalRequired}>
	<div>
		{#if questionnaire_detail.hasOwnProperty("name")}
			<div class="p-3 text-center">
				<h5 class="text-muted">{$_("RegisterDetailQuestion.button_Request")} {questionnaire_detail["name"]}</h5>
				<div
					class="p-2"
					style="
    display: flex;
    flex-direction: column;
">
					<div class="d-flex" style="display: flex;justify-content: space-between;margin-top: 2%;">
						<small class="text-muted">{$_("RegisterDetailQuestion.text_required")}</small>
						<p class="m-0 mx-2">
							{#if questionnaire_detail.hasOwnProperty("requested")}
								{#if requested}
									<!-- <i
										on:click={() => {
											requested = false;
										}}
										class="cursor-pointer myCustom-toggle gg-toggle-off myCustom-toggleOn" /> -->
										<button
												on:click={() => {
													requested = false;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleon.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{:else}
									<!-- <i
										on:click={() => {
											requested = true;
										}}
										class="myCustom-toggle gg-toggle-on myCustom-toggleOff" /> -->
										<button
												on:click={() => {
													requested = true;
												}}
												style="background: none; border: none; padding: 0; cursor: pointer;">
												<img src="./img/toggleoff.svg" alt="Toggle Off" style="width: 32x; height: 32px;">
											</button>
								{/if}
							{/if}
						</p>
					</div>
					<div class="form-group" style="display: flex;justify-content: space-between;margin-top: 2%;">
						<small class="text-muted">{$_("RegisterDetailQuestion.text_dateSuggested")}</small>
						<input
							class="input-log"
							type="date"
							id="startPersonBirthdate"
							pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
							name="trip-start"
							bind:value={dateSuggested} />
					</div>
				</div>
			</div>
		{/if}
		<div class="d-flex justify-content-center">
			<button
				on:click={() => {
					requestQuestionnaire({ dateSuggested, requested });
				}}
				class="py-0 mx-2 py-2 btnBlue">{$_("RecordDoctor.button_Send")}</button>

			<button
				class="py-0 mx-2 py-2 btnWhite"
				on:click={() => {
					modalRequired = false;
				}}>{$_("RecordDoctor.button_cancel")}</button>
		</div>
	</div>
</LayoutModal>

<LayoutModal
    tam="60"
    isOpenModal={modalViewAnswer}
    on:closeModal={() => {
        modalViewAnswer = false;
    }}>
    <div class="p-3 text-start">
        {#if resultsAnswer && resultsAnswer.length > 0}
            <div>
                <h2 class="text-color" style="color: #112c4a;">Resultados del cuestionario</h2>
				<hr style="border: 1px dashed #ccc;">
				<h4 class="text-color" style="color: #112c4a;">Respuestas:</h4>
				{#each resultsAnswer.filter(resultado => resultado.questionType != "inform") as resultado}
					{#if resultado.questionType === "rta" && resultado.answerTexts.length > 0}
						<div><strong>{resultado.questionText}</strong></div>
							{#each resultado.answerTexts as answer}
                                                            <div>>>> {answer}</div>
                            {/each}
						<hr style="border: 1px dashed #ccc;">

					{/if}

					{#if resultado.questionType === "grid" && resultado.options.length > 0}
						<div><strong>{resultado.questionDescription}</strong></div>

						<table class="table table-bordered table-striped">
							<tbody>
								{#each resultado.options as option}
								<tr>
                                    <td>
                                        <div class="card-shadow rounded-xl" style="font-size: 10px;">
                                            <div class="inline-flex items-start">
                                                <div class="flex items-center gap-3">
                                                    <div>
                                                        <div
                                                            class="w-7 h-7 rounded-full flex items-center justify-center"
                                                            style="width: 20px; background-color: rgb(40, 114, 161);">
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="text-color"
                                                        style="
                                                            color: #215273;
                                                            font-family: &quot;Roboto&quot;, sans-serif;
                                                            font-size: 12px;
                                                            font-weight: bold;
                                                        ">
                                                        {option.questionText}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="">
                                            <div class="inline-flex items-start">
                                                <div class="flex items-center gap-3">
                                                    <div class="text-color" style="margin-left: 4%; font-size: 12px;">
                                                        <!-- Mostrar cada respuesta en una nueva línea -->
                                                        {option.answerTexts[0]}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                           		{/each}
							</tbody>
						</table>
						<hr style="border: 1px dashed #ccc;">

					{/if}
					

				{/each}

				{#if resultsAnswer.filter(resultado => resultado.questionType === "inform").length > 0}
					<h4 class="text-color" style="color: #112c4a;">Informe de resultados:</h4>
					
					{#each resultsAnswer.filter(resultado => resultado.questionType === "inform") as resultado}
						{#if resultado.answerTexts && resultado.answerTexts.length > 0}
						<div><strong>{resultado.questionText.slice(1)}</strong></div>
							{#each resultado.answerTexts as answer}
                                                            <div>>>> {answer}</div>
                            {/each}
						{/if}
					{/each}
				{/if}

                <!-- <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th scope="col" style="font-size: 14px;" >Pregunta</th>
                            <th scope="col" style="font-size: 14px;" >Respuestas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {#each resultsAnswer as resultado}
                            {#if resultado.answerTexts && resultado.answerTexts.length > 0}
                                <tr>
                                    <td>
                                        <div class="card-shadow rounded-xl" style="font-size: 10px;">
                                            <div class="inline-flex items-start">
                                                <div class="flex items-center gap-3">
                                                    <div>
                                                        <div
                                                            class="w-7 h-7 rounded-full flex items-center justify-center"
                                                            style="width: 20px; background-color: rgb(40, 114, 161);">
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="text-color"
                                                        style="
                                                            color: #215273;
                                                            font-family: &quot;Roboto&quot;, sans-serif;
                                                            font-size: 12px;
                                                            font-weight: bold;
                                                        ">
												{#if resultado.questionDescription != ""}
												{resultado.questionDescription} -
												{/if}
														
                                                        {resultado.questionText}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="">
                                            <div class="inline-flex items-start">
                                                <div class="flex items-center gap-3">
                                                    <div class="text-color" style="margin-left: 4%; font-size: 12px;">
                                                        {#each resultado.answerTexts as answer}
                                                            <div>{answer}</div>
                                                        {/each}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            {/if}
                        {/each}
                    </tbody>
                </table> -->
				<div class="button-container">
                        <button
                            on:click={() => {
                                modalViewAnswer = false;
                            }}
                            class="btnClose btn btn-secondary">Cerrar</button>
							<button on:click={generatePDF} class="btnClosepdf">Descargar PDF</button> <!-- Botón para generar el PDF -->	
                
                </div>
            </div>
        {:else if error !== null}
            <p>Ocurrió un error al obtener los resultados: {error}</p>
        {:else}
            <p>Cargando...</p>
        {/if}
    </div>
</LayoutModal>

<style>
	.form-detail {
		margin: 1%;
		margin-left: 3%;
		width: 100%;
	}

	.container-miIframe {
		width: 100%;
		height: 60vh;
		overflow: hidden; /* Oculta la barra de desplazamiento */
		position: relative; /* Para posicionar el iframe dentro del contenedor */
	}
	.container-miIframe iframe {
		width: calc(100% + 17px); /* Ajusta el ancho para compensar el espacio ocupado por la barra de desplazamiento */
		height: 100%;
		border: none; /* Elimina el borde del iframe */
		position: absolute; /* Posiciona el iframe */
		top: 0;
		left: 0;
	}
	.button-container {
    display: flex;
    justify-content: center; /* Centra los botones horizontalmente */
    gap: 15px; /* Añade separación entre los botones */
    margin-top: 30px; /* Opcional: Añade margen superior para separación del contenido anterior */
	}
	.btnClose, .btnClosepdf {
	margin-top: 10px;
	letter-spacing: 1px;
    border-radius: 10px;
    color: #ffffff;
    background-color: var(--my-dark-blue);
	}

	.column {
		flex-basis: 33.33%; /* Para dividir equitativamente en tres columnas */
		padding: 10px;
		box-sizing: border-box;
	}
	.btnAdd {
		color: #215273;
		border-color: #215273;
		font-weight: 500;
		border-radius: 10px;
		background-color: #ffffff;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.text-reduce {
		width: 900px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: initial;
	}
	.requested {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}

	.input-log-edit,
	.input-log {
		border: none; /* Quitar el borde */
		outline: none; /* Quitar el contorno al enfocar */
		padding: 8px; /* Añadir padding para separar el texto del borde */
		background-color: transparent; /* Hacer el fondo transparente */
		border-bottom: 2px solid #116ac9; /* Agregar una línea azul debajo del input */
	}

	.text_complete {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.createdByName,
	.dateCompleted,
	.dateReal {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.row {
		display: flex;
		width: 100%;
	}

	.myCustom-toggle {
		transform: scale(1.25);
	}

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #ffff;
		background-color: #787f87;
	}

	.my-record {
		max-height: 55vh;
		/* overflow: auto; */
	}

	.conteiner {
		padding-left: 5%;
		width: 90%;
	}
	.table {
        width: 100%;
        margin-bottom: 1rem;
        color: #212529;
    }

    .table-bordered {
        border: 1px solid #dee2e6;
    }

    .table-bordered td, .table-bordered th {
        border: 1px solid #dee2e6;
    }
  
</style>