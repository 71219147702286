<script>
	import { currentSession } from "../helpers/storage/sessions";
	import { patient, user, language, registerDetail, patientLayout } from "../helpers/storage/stores";
	import Loader from "../components/Loader.svelte";
	import LayoutModal from "../components/Layouts/Layout_Modal.svelte";
	import HelpFomr from "../components/HelpFomr.svelte";
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";
	// import dataLabel from "../helpers/dataLabel.json";
	// const Menu = dataLabel.Menu;

	//console.log({ Menu });

	// Vars
	let loading = true;
	let modalSuccessHelp = false;
	let viewSelectLang = false;
	// let selectedLanguage = "es"
	let fotoPerfil = "";
	let fotoPerfilProf = "";
	$: messageMedicoC = $_("Menu.text_messageMedicoC");
	$: medicoCabecera = "";
	let [specialities, dataEsp, esp] = [[], [], []];
	let [nombreMedico, paciente, nombre, rol] = ["", null, "", ""];
	rol = $currentSession.attributes.profile;
	//Paciente / Cuidador / Profesional_salud
	let langSelect = "es";
	if ($language == "es" || $language == "en") {
		console.log("ingreso");
		langSelect = $language;
	}
	function closeHelpForm() {
		modalSuccessHelp = false;
	}
	console.log({ langSelect });

	function removeResources() {
		try {
			localStorage.removeItem("pathologies");
		}
		catch {		
		}
		try {
			localStorage.removeItem("monitoringPlans");
		}
		catch {		
		}
		try {
			localStorage.removeItem("prepaidMedicine");
		}
		catch {		
		}
		try {
			localStorage.removeItem("specialties");
		}
		catch {		
		}
		try {
			localStorage.removeItem("frecuencies");
		}
		catch {		
		}
		try {
			localStorage.removeItem("languages");
		}
		catch {		
		}try {
			localStorage.removeItem("country");
		}
		catch {		
		}
		try {
			localStorage.removeItem("state");
		}
		catch {		
		}
	}

	function changeLanguage(lang) {
		$language = lang;
		removeResources();
		window.location.reload();
	}

	onMount(async () => {
		console.log("Abre el menu", $user);
		if (Object.keys($user).length > 0) {
			if ($user.FirstName) {
				nombre = $user.FirstName + " " + $user.LastName;
			}

			if (rol == "Profesional_de_Salud") {
				if ($user.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $user.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfil = element.Link__c;
						}
					});
				}
				// if ($user.Especialidades__r && $user.Patolog_as2__r) {
				// 	dataEsp = $user.Patolog_as2__r.records;
				// }

				// dataEsp = $user.Patolog_as2__r
				// 	? $user.Patolog_as2__r.records
				// 	: $user.Especialidades__r
				// 	? $user.Especialidades__r.records
				// 	: [];

				dataEsp = $user.Especialidades__r.records;
				console.log({ dataEsp });
				dataEsp.forEach((element) => {
					if (dataEsp.length > 0) {
						if ($language == "es") {
							esp.push(element.Name_ES__c);
						} else {
							esp.push(element.Name_EN__c);
						}
					} else if (element.Name) {
						console.log("Name");
						esp.push(element.Name);
					}
				});
				console.log({ esp });
				specialities = esp;
			}
			if (rol == "Paciente") {
				if ($user.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $user.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfil = element.Link__c;
						}
					});
				}

				medicoCabecera = $user["Estado_Medico_de_cabecera__c"];
				nombreMedico = $user?.Medico_cabecera?.Name;
				if (medicoCabecera == "Pendiente de aprobación") {
					messageMedicoC = `${medicoCabecera}`;
				} else {
					console.log({ medicoCabecera });
					messageMedicoC = $_("Menu.text_messageMedicoC");
				}
				if ($user.Medico_cabecera && $user.Medico_cabecera.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $user.Medico_cabecera.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfilProf = element.Link__c;
							console.log({ fotoPerfilProf });
						}
					});
				}
			}
			if (rol == "Familiar_Cuidador") {
				if ($user.hasOwnProperty("Archivos_S3__r")) {
					let fileAd = $user.Archivos_S3__r.records;
					fileAd.forEach((element) => {
						if (element.Tipo__c == "Foto de perfil" && element.hasOwnProperty("Link__c")) {
							fotoPerfil = element.Link__c;
						}
					});
				}
				console.log("LLego hasta aca en el menu");
			}
		} else {
			console.log("Explota aca");
			window.location.href = "#/LoginError";
		}
		//console.log({fotoPerfil})
		loading = false;
	});

	// Cerrar Sesion
	const closeSession = () => {
		window.sessionStorage.clear();
		[$currentSession, $user, $patient, $registerDetail] = [{}, {}, {}, {}];
		window.location.href = "/";
	};
</script>

<!-- Component HTML -->
<section class="background" style="height: 100vh;">
	<div
		class="help-icon"
		data-title={$_("Menu.button_help")}
		on:click={() => {
			modalSuccessHelp = true;
		}}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="1"
			stroke-linecap="round"
			stroke-linejoin="round">
			<circle cx="12" cy="12" r="10" fill="none" />
			<text x="12" y="15" text-anchor="middle" font-size="12">?</text>
		</svg>
	</div>
	<div class="languege-icon" data-title={$_("Menu.button_language")}>
		<img
			src="./img/globo.png"
			alt="Icono del mundo"
			style="width: 7%;color: white;margin-left: 2%;background: aliceblue;border: 2px;border-radius: 50%;"
			on:click={() => {
				viewSelectLang = !viewSelectLang;
			}} />
	</div>
	{#if viewSelectLang}
		<select
			bind:value={langSelect}
			on:change={() => {
				changeLanguage(langSelect);
			}}>
			<option value="es">{$_("Login.spanish")}</option>
			<option value="en">{$_("Login.englis")}</option>
			<!-- Agrega más opciones de idioma según tus necesidades -->
		</select>
	{/if}
	{#if loading}
		<div class="text-center py-3">
			<Loader text={false} />
			<p class="text-muted">{$_("Menu.loading")}</p>
		</div>
	{:else}
		<!-- Usuario -->
		<div class="container flex justify-center items-center h-screen w-screen mx-auto">
			<div class="p-2">
				<!-- <div class="border rounded shadow py-2 mt-4"> -->
				<div class="logo text-center">
					<!-- <img src="./img/Frame 106.png" alt="logo" width="15%" height="15%" /> -->
					<img src="./img/new_logo_redondo.png" alt="logo" width="15%" height="15%" />
				</div>
				<div class="menu-info">
					<!-- <img src="./img/perfilNone.png" alt="logo" width="35%" height="35%"/> -->
					<!-- <p class="m-0">Bienvenido:</p> -->

					<div class="perfilIMG my-3">
						<img
							on:click={() => {
								// tiene que llamar a la vista de perfil de acuerdo al rol
								if (rol == "Paciente") {
									console.log("Ver perfil");
									$patient = $user;
									window.location.href = "#/Patient";
								} else {
									$patient = {};
									window.location.href = "#/ProfessionalProfile";
								}
							}}
							class="imgPerfil cursor-pointer"
							style="width:108px; height:108px;"
							src={fotoPerfil ? fotoPerfil : "./img/perfil_none.png"}
							alt="" />
					</div>
					<div class="roles">
						<p class="m-0">{nombre}</p>
						{#if rol == "Cuidador"}
							<p class="m-0">{$_("Menu.rolSelect_Carier")}</p>
						{/if}
						{#if rol == "Paciente"}
							<p class="m-0">{$_("Menu.rolSelect_Pat")}</p>
						{/if}
						{#if rol == "Profesional_de_Salud"}
							<p class="m-0">{$_("Menu.rolSelect_Prof")}</p>
							<br />
							{#each specialities as spe}
								<p class="m-0">{spe}</p>
							{/each}
						{/if}
					</div>

					<!-- Medico Cabecera -->
					<br />
					{#if (medicoCabecera == "Aún no se eligió medico" || medicoCabecera == "Rechazado") && rol == "Paciente"}
						<div class="medicoCabecera">
							<p class="m-0 text-danger">{messageMedicoC}</p>
						</div>
					{:else if rol == "Paciente" && medicoCabecera == "Aprobado"}
						<p class="m-0">{$_("Menu.chief_medic")}:</p>
						<div class="medicoCabecera">
							<img
								on:click={() => {
									console.log("Ver perfil");
									//window.location.href = "#/Patient";
								}}
								class="imgPerfil cursor-pointer"
								style="width:70px; height:70px;"
								src={fotoPerfilProf ? fotoPerfilProf : "./img/perfil_none.png"}
								alt="" />
							<p class="m-0">{nombreMedico}</p>
						</div>
					{/if}
					{#if rol == "Paciente" && medicoCabecera == "Pendiente de aprobación"}
						<div class="medicoCabecera">
							<p class="m-0 text-danger">{messageMedicoC}</p>
						</div>
					{/if}
					{#if rol == "Familiar_Cuidador"}
						<div class="py-2 m-2">
							<div style="color: #112C4A;">
								<!-- Familiar Cuidador -->
								<div class="p-2">
									<div class=" py-2">
										<p class="m-0" style="color: #ffff;">{$_("Menu.text_patient")}:</p>
										{#if true}
											<p class="m-0" style="color: #ffff;">
												({$_("Menu.text_notselectPatient")})
											</p>
										{:else}
											<p class="m-0">{paciente}</p>
											<div class="d-flex justify-content-center">
												<a href="/account" class="btnBlue py-0 m-1">{$_("Menu.text_Change")}</a>
												{#if true}
													<button class="btnWhite py-0 m-1"
														>{$_("Menu.button_remove")}</button>
												{/if}
											</div>
										{/if}
									</div>
								</div>
							</div>
						</div>
					{/if}
				</div>
				<!-- </div> -->
			</div>
		</div>

		<!-- Opciones -->
		<div class="text-center">
			{#if rol == "Paciente" && medicoCabecera == "Aprobado"}
				<br />
				<button
					class="btnPaciente"
					on:click={() => {
						window.location.href = "#/Patient/Plan";
					}}>{$_("Menu.button_plan")}</button>
			{:else if rol == "Profesional_de_Salud"}
				<div class="botones_paciente">
					<button
						class="btnPaciente"
						on:click={() => {
							$patient = {};
							$patientLayout = {};
							window.location.href = "#/Doctor";
						}}>{$_("Menu.button_patient")}</button>
				</div>
				<div class="botones_altas">
					<button
						class="btnAltas"
						on:click={() => {
							window.location.href = "#/Altas";
						}}>{$_("Menu.button_altas")}</button>
				</div>
			{/if}
			{#if rol == "Familiar_Cuidador"}
				<button class="btnMisPacientes"><span>{$_("Menu.button_my_patients")} </span></button>
			{/if}
			{#if false}
				<div class="conteiner-setting">
					<button
						class="btnSetting"
						on:click={() => {
							//closeSession();
						}}>{$_("Menu.button_setting")}</button>
				</div>
			{/if}
			{#if false}
				<div class="conteiner-help">
					<button
						class="btnHelp"
						on:click={() => {
							//closeSession();
						}}>{$_("Menu.button_help")}</button>
				</div>
			{/if}

			<div class="cerrar_S">
				<button
					class="btnCerrar"
					on:click={() => {
						closeSession();
					}}>{$_("Menu.button_logout")}</button>
			</div>
		</div>
	{/if}
</section>
<LayoutModal
	tam="30"
	isOpenModal={modalSuccessHelp}
	on:closeModal={() => {
		modalSuccessHelp = false;
	}}>
	<HelpFomr on:closeHelpForm={closeHelpForm} />
</LayoutModal>

<style>
	.conteiner-help {
		padding-top: 5%;
		text-align: center;
	}
	.conteiner-setting {
		padding-top: 5%;
		text-align: center;
	}
	.btnHelp {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #112c4a;
	}
	.btnSetting {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #112c4a;
	}
	.help-icon {
		position: absolute;
		top: -5px;
		cursor: pointer;
		color: white;
		font-size: 24px;
		margin-left: 5px;
	}

	.help-icon[data-title]:hover::after {
		content: attr(data-title);
		background-color: #a6c8de;
		color: white;
		padding: 2px 6px; /* Valores más pequeños para el relleno */
		border-radius: 2px; /* Valores más pequeños para el radio del borde */
		position: absolute;
		bottom: -20px; /* Distancia desde abajo más pequeña */
		left: 50px;
		transform: translateX(-50%);
		font-size: 13px;
		z-index: 1;
	}

	.languege-icon[data-title]:hover::after {
		content: attr(data-title);
		background-color: #a6c8de;
		color: white;
		padding: 2px 6px; /* Valores más pequeños para el relleno */
		border-radius: 2px; /* Valores más pequeños para el radio del borde */
		bottom: -20px; /* Distancia desde abajo más pequeña */
		left: 50px;
		transform: translateX(-50%);
		font-size: 13px;
		z-index: 1;
	}
	.cerrar_S {
		padding-top: 15%;
	}
	.botones_paciente {
		padding-top: 10%;
		text-align: center;
	}
	.botones_altas {
		padding-top: 5%;
		text-align: center;
	}
	.btnMisPacientes {
		width: 40%;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #112c4a;
	}
	.btnCerrar {
		/* width: 40%; */
		width: 150px;
		border-radius: 35px;
		background-color: #112c4a;
		color: #fff;
	}
	.btnPaciente {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #112c4a;
	}
	.btnAltas {
		width: 150px;
		border-radius: 35px;
		background-color: rgb(255, 249, 249);
		color: #112c4a;
	}
	.background {
		color: #fff;
		background: #112c4a;
		width: 100%;
	}
	.logo {
		position: relative;
	}
	.perfilIMG {
		right: 10%;
	}
	.imgPerfil {
		width: 150px;
		border-radius: 50%;
		background-size: 120%;
	}
	.imgPerfil:hover {
		transform: scale(1.05);
		transition: 0.2s;
	}
	.roles {
		text-align: center;
	}
	.medicoCabecera {
		align-items: center;
		text-align: center;
	}
	.menu-info {
		align-items: center;
		text-align: center;
	}
</style>
