<script>
	import { onMount } from "svelte";
	//import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import {
		generic_account_get,
		utils_resources_get,
		// assignPlan,
		professional_patient_accept_reject,
		generic_interview_update,
		patient_patient_update,
		ROLES,
	} from "../../../helpers/apiBackend";
	import { getEdad } from "../../../helpers/helpers";
	import { patient, user, language } from "../../../helpers/storage/stores";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { currentSession } from "../../../helpers/storage/sessions";
	import PersonalInformation from "../../../components/Register/Personal-Information.svelte";
	//import dataLabel from "../../../helpers/dataLabel.json";
	import { _ } from "svelte-i18n";

	// const GralInformation = dataLabel.GralInformation;

	//console.log({ GralInformation });
	localStorage.setItem("language", JSON.stringify($language));
	console.log({ $language });

	let monitoringPlans = [];
	let fotoPerfil = "";
	let edad = "";
	let fecha = "";
	let dataPatient = [];
	let load = false;
	let modalDecline = false;
	let modalAccept = false;
	let modalEditPatient = false;
	let selectedPlan = "none";
	let langSelect = $language != {} ? $language : "es";
	let myConfirm = false;
	let doctorId = $user;
	const complexItemsStatus = $_("GralInformation.select_status");

	/**
	 * @description
	 */
	const handleSessionSolicitude = (header, status, selectedPlan) => {
		console.log("Plan Seleccionado", selectedPlan)
		return new Promise(async function (resolve, reject) {
			let data = {
				patientId: dataPatient.Id,
				doctorId: doctorId.Id,
				planId: selectedPlan,
				header: header,
				accept: status,
				sessionId: $patient.session,
				suggestedProfessional: "Sin dato",
			};
			let body = { data };
			await professional_patient_accept_reject(body).then((data) => {
				console.log({ data });
				if (data.statusCode == 201) {
					if (status) {
						window.location.href = "#/Doctor";
					} else {
						window.location.href = "#/Altas";
					}

					resolve();
				} else {
					reject(data);
					//TODO: manejo de error
				}
			});
		});
	};

	/**
	 * @description
	 */
	const handleSessionRequest = (action, interviewDetailId, assignedProfessional) => {
		return new Promise(async function (resolve, reject) {
			let data = {
				action,
				interviewDetailId,
				assignedProfessional,
			};
			await generic_interview_update(data).then((data) => {
				console.log({ data });
				if (data.statusCode == 201) {
					if (action == "accept") {
						window.location.href = "#/Plan";
					} else {
						window.location.href = "#/Altas";
					}
					resolve();
				} else {
					reject(data);
					//TODO: manejo de error
				}
			});
		});
	};
	/**
	 * @description
	 */
	// function updateAssign() {
	// 	unAssignPlan($patient.id).then((data) => {
	// 		if (data.statusCode == 200) {
	// 			window.location.href = "#/Altas";
	// 		}
	// 	});
	// }
	/**
	 * @description
	 */
	// async function asignar() {
	// 	console.log("call asignar()");
	// 	try {
	// 		if (myConfirm) {
	// 			console.log("call ", myConfirm);
	// 			let cpsr = {
	// 				plan: selectedPlan,
	// 				doctor: $user.Id,
	// 				patient: dataPatient.Id,
	// 			};
	// 			console.log({ cpsr });
	// 			if (cpsr.plan) {
	// 				console.log("paso el if");
	// 				await assignPlan(cpsr, $currentSession).then((result) => {
	// 					console.log({ result });
	// 					if (result.statusCode == 201) {
	// 						window.location.href = "#/Doctor";
	// 					} else {
	// 						window.location.href = "#/loginError";
	// 					}
	// 				});
	// 			}
	// 		}
	// 	} catch (assignError) {
	// 		console.log({ assignError });
	// 	}
	// }
	/**
	 * @description
	 */
	onMount(async () => {
		console.log({ $patient });
		modalEditPatient = false;
		if ($patient.head) {
			console.log({ $language });
			console.log("utils_resources_get - Patient");
			monitoringPlans = await utils_resources_get("monitoringPlans", $language);
			console.log({ monitoringPlans });
		} else {
			//$patient.Id = $patient.Id;
		}
		
		
		//[dataPatient] = await Promise.all([generic_account_get("", $patient.Id, ROLES.patient)]);
		// if ($patient.PersonEmail) {
		// 	[dataPatient] = await Promise.all([generic_account_get($patient.PersonEmail, $patient.Id, "Patient", $patient.DNI__c)]);
		// } else {
		// 	console.log("Paso por el patient");
		// 	[dataPatient] = await Promise.all([generic_account_get('', $patient.Id, "Patient", '')]);

		// }
		// console.log('($user.Id : ' , $user.Id , 'dataPatient.Id : ' ,dataPatient.Id);
		// if ($user.Id == dataPatient.Id) {
		// 	$user = dataPatient;
		// }

		// Verifica si $user ya tiene la información de dataPatient
		if ($user && $user.Id === $patient.Id || $user.Id === $dataPatient.Id ) {
			console.log('El paciente ya está cargado como usuario actual.');
		} else {
			const email = $patient.PersonEmail || '';
			const dni = $patient.DNI__c || '';
			[dataPatient] = await Promise.all([generic_account_get(email, $patient.Id, "Patient", dni)]);
			console.log('($user.Id :', $user.Id, 'dataPatient.Id :', dataPatient.Id);
		}

		// console.log({ dataPatient });
		fecha = dataPatient.PersonBirthdate.split("-").reverse().join("-");
		// console.log({ fecha });
		edad = await getEdad(fecha);
		// console.log({ edad });
		if (dataPatient.hasOwnProperty("Archivos_S3__r")) {
			dataPatient.Archivos_S3__r.records.map((element) => {
				console.log(element);
				if (element.Link__c && element.Tipo__c == "Foto de perfil") {
					fotoPerfil = element.Link__c;
				}
			});
		}
	});
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	{#await dataPatient}
		<div class="container text-center py-5 bg-white">
			<Loader text={false} />
			<p class="text-muted">{$_("GralInformation.loading")}</p>
		</div>
	{:then dataPatient}
		{#if dataPatient.FirstName}
			<div class="border p-2 bg-white my-2 rounded">
				<div class="d-flex justify-content-between">
					<div class="border shadow-sm rounded p-3 justify-content-around" style="width: 49%;">
						<div class="text-center">
							<h4 class="title">{$_("GralInformation.title_page")}</h4>
						</div>
						<div style="display: inline-flex; width: 100%;">
							<div class="col-sm-6">
								<div class="perfilIMG text-center my-3">
									<img
										class="imgPerfil Patient cursor-pointer"
										style="width:190px; height:190px;"
										src={fotoPerfil ? fotoPerfil : "./img/perfil_none.png"}
										alt="imgPerfil Patient" />
								</div>
							</div>
							<div class="col-sm-6">
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_name")}:</p>
									<br />
									{#if dataPatient.FirstName != null}
										<span class="text-muted">
											{" " + dataPatient.FirstName + " " + dataPatient.LastName}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_birthdate")}:</p>
									<br />
									{#if dataPatient.PersonBirthdate != null}
										<span class="text-muted"> {fecha}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_age")}:</p>
									<br />
									{#if edad != ""}
										<span class="text-muted"> {edad}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_addres")}:</p>
									<br />
									{#if dataPatient.BillingStreet != null}
										<span class="text-muted"> {dataPatient.BillingStreet}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_billingState")}:</p>
									<br />
									{#if dataPatient.BillingState != null}
										<span class="text-muted"> {dataPatient.BillingState}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_phone")}:</p>
									<br />
									{#if dataPatient.PersonMobilePhone != null}
										<span class="text-muted"> {dataPatient.PersonMobilePhone}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								<div class="name d-flex" style="text-align: end;justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_coverage")}:</p>
									<br />
									{#if dataPatient.Cobertura_Salud__c != null}
										<span class="text-muted"> {dataPatient.Cobertura_Salud__c}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								<div class="name d-flex" style="justify-content: space-between;">
									<p class="name">{$_("GralInformation.text_affiliateNumber")}:</p>
									<br />
									{#if dataPatient.Nro_de_afiliado__c != null}
										<span class="text-muted"> {dataPatient.Nro_de_afiliado__c}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
								{#if !$user.hasOwnProperty("Especialidades__r")}
									<div class="text-center border shadow-sm rounded my-2 p-3">
										<div>
											<button
												class="btnBluEdit"
												on:click={() => {
													modalEditPatient = true;
												}}>{$_("GralInformation.button_edit")}</button>
										</div>
									</div>
								{/if}
							</div>
						</div>
					</div>
					<div class="border shadow-sm rounded my-2 p-3" style="width: 49%;">
						<div class="text-center">
							<h4 class="title">{$_("GralInformation.title_page_path")}</h4>
						</div>
						{#if dataPatient.hasOwnProperty("Patolog_as2__r") && dataPatient.Patolog_as2__r.hasOwnProperty("records")}
							{#each dataPatient.Patolog_as2__r.records as patologias}
								<div class="name d-flex" style="justify-content: space-between;">
									{#if patologias.Name_ES__c != null}
										{#if $language == "es"}
											<span class="text-muted"> {patologias.Name_ES__c}</span>
										{:else}
											<span class="text-muted"> {patologias.Name_EN__c}</span>
										{/if}
									{:else if patologias.hasOwnProperty("Name")}
										<span class="text-muted"> {patologias.Name}</span>
									{:else}
										<span class="text-muted">{$_("GralInformation.text_not_data")}</span>
									{/if}

									{#if patologias.Estado_patologia__c != null}
										<span class="text-muted">
											{complexItemsStatus[patologias.Estado_patologia__c]}</span>
									{:else}<span class="text-muted">{$_("GralInformation.text_not_data")}</span>{/if}
								</div>
							{/each}
						{:else}
							<p class="m-0">
								<span class="text-muted">&#8226;&nbsp;&nbsp;</span>{$_("GralInformation.text_not_epof")}
							</p>
						{/if}
						{#if dataPatient.hasOwnProperty("Archivos_S3__r") && dataPatient.Archivos_S3__r.hasOwnProperty("records")}
							<div class="text-center">
								<h4 class="title">{$_("GralInformation.text_attach")}</h4>
							</div>
							{#each dataPatient.Archivos_S3__r.records as data}
								{#if data.Tipo__c != "Foto de perfil"}
									<div class="Completado">
										<img
											class="cursor pointer"
											on:click={() => {
												console.log({ data });
											}}
											src="img/pdfIcon.png"
											style="width:50px; height:50px;"
											alt="PDF"
											width="auto" /><a href={data.Link__c} download
											>{$_("GralInformation.button_download")}</a>
										<br />
									</div>
								{/if}
							{/each}
							{#if !dataPatient.Archivos_S3__r.records[1]}
								<div style="margin: 10%; text-align: center;">
									<p class="m-0">
										<span class="text-muted" />{$_("GralInformation.text_not_attach")}
									</p>
								</div>
							{/if}
						{/if}
					</div>
				</div>
				{#if $user.hasOwnProperty("Especialidades__r")}
					<div class="text-center border shadow-sm rounded my-2 p-3">
						<div>
							<p>
								¿{$_("GralInformation.text_message")}
								{dataPatient.FirstName + " " + dataPatient.LastName}?
							</p>
						</div>
						<div>
							<button
								class="btnWhite"
								on:click={() => {
									//updateAssign();

									modalDecline = true;
								}}>{$_("GralInformation.button_reject")}</button>
							<button
								class="btnBlue"
								on:click={() => {
									//updateAssign();
									if ($patient.head) {
										modalAccept = true;
										//handleSessionSolicitude($patient.head, true, null); Sali revision 28.06
									} else {
										modalAccept = true;
										load = true;
										handleSessionRequest("accept", $patient.session, null);
									}
								}}>{$_("GralInformation.button_acept")}</button>
						</div>
					</div>
					<div class="d-flex justify-content-center mx-1 border-top">
						<a href="#/Altas">{$_("GralInformation.button_return")}</a>
					</div>
				{:else}
					<div class="d-flex justify-content-center mx-1 border-top">
						<a href="#/Patient/Plan">{$_("GralInformation.button_return")}</a>
					</div>
				{/if}
			</div>
		{:else}
			<div class="container text-center py-5 bg-white">
				<Loader text={false} />
				<p class="text-muted">{$_("GralInformation.loading")}</p>
			</div>
		{/if}
	{/await}
	<!-- </LayoutNav> -->
</section>

<LayoutModal
	tam="30"
	isOpenModal={modalDecline}
	on:closeModal={() => {
		modalDecline = false;
	}}>
	{#if load}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("GralInformation.loading")}</p>
		</div>
	{:else}
		<div class="text-center p-3">
			<p>{$_("GralInformation.text_reject_res")}</p>
			<div class="d-flex justify-content-center">
				<button
					on:click={() => {
						modalDecline = false;
					}}
					class="btnWhites">
					{$_("GralInformation.text_not")}
				</button>
				<button
					on:click={() => {
						load = true;

						if ($patient.head) {
							//updateAssign();
							handleSessionSolicitude($patient.head, false, null);
						} else {
							handleSessionRequest("reject", $patient.session, null);
						}
						//modalDecline = false;
					}}
					class="btnBlues">
					{$_("GralInformation.text_yes")}
				</button>
			</div>
		</div>
	{/if}
</LayoutModal>

<LayoutModal
	isOpenModal={modalEditPatient}
	tam="70"
	on:closeModal={() => {
		modalDecline = false;
		modalEditPatient = false;
	}}>
	{#if load}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("GralInformation.loading")}</p>
		</div>
	{:else}
		<h4 style="color: rgb(17, 44, 74);text-align: center;">
			<!-- Iniciemos tu registro como Profesional de la Salud, por favor completá los siguientes datos: -->
			{$_("GralInformation.text_edit_inf_gral")}
		</h4>
		<PersonalInformation edit={true} />
		<div class="d-flex justify-content-center mx-1 border-top">
			<a
				href="#/Patient"
				on:click={() => {
					//Prueba
					modalEditPatient = false;
				}}>{$_("GralInformation.button_return")}</a>
		</div>
	{/if}
</LayoutModal>

<LayoutModal
	isOpenModal={modalAccept}
	tam="40"
	on:closeModal={() => {
		modalAccept = false;
	}}>
	{#if load}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("GralInformation.loading")}</p>
		</div>
	{:else}
		<h4 class="title">{$_("GralInformation.text_asigne")}{dataPatient.FirstName}</h4>
		<div class="my-4">
			<!-- <p class="m-0">Paciente:&nbsp;&nbsp;{dataPatient.FirstName}</p> -->

			<!-- Monitoring Plans List -->
			{#if monitoringPlans.length == 0}
				<Loader text={false} />
				<p class="text-muted">{$_("GralInformation.loading")}</p>
			{:else if monitoringPlans.length == 0}
				<p class="text-muted">{$_("GralInformation.text_not_plan")}</p>
			{:else}
				<p class="m-0">{$_("GralInformation.text_Select_plan")}</p>
				<select bind:value={selectedPlan} class="form-control rounded-0" name="plan-select" id="plan-select">
					<option value="none"
						><span class="text-muted">{$_("GralInformation.text_Select_p")}... </span></option>
					{#each monitoringPlans as plan}<option class="py-0" value={plan.Id}>{plan.label}</option>{/each}
				</select>
			{/if}
		</div>
		{#if selectedPlan != "none"}
			<div class="text-center">
				<label>
					<input type="checkbox" bind:checked={myConfirm} />
					{$_("GralInformation.text_question_confirm")}</label>
			</div>
		{/if}
		<!-- Actions -->
		<div class="mt-4 d-flex" style="justify-content: center;">
			<button
				on:click={() => {
					modalAccept = false;
				}}
				class="py-1 btnWhites">
				{$_("GralInformation.button_cancel")}</button>
			{#if selectedPlan != "none"}
				{#if myConfirm}
					<button
						class="btnBlues"
						on:click={() => {
							load = true;
							//asignar();
							handleSessionSolicitude($patient.head, true, selectedPlan);
						}}>{$_("GralInformation.button_Assign")}</button>
				{:else}
					<button class="btnBlues" disabled style="color:darkgray"
						>{$_("GralInformation.button_Assign")}</button>
				{/if}
			{/if}
		</div>
	{/if}
</LayoutModal>

<style>
	.perfilIMG {
		right: 10%;
	}
	.title {
		color: #215273;
		text-align: center;
	}
	.imgPerfil {
		width: 150px;
		border-radius: 50%;
		background-size: 120%;
	}
	.btnWhites {
		letter-spacing: 1px;
		color: var(--my-dark-blue);
		border-radius: 10px;
		background-color: #ffffff;
		width: 30%;
		margin: 1%;
	}
	.btnBlues {
		letter-spacing: 1px;
		border-radius: 10px;
		width: 7%;
		color: #ffffff;
		background-color: var(--my-dark-blue);
		width: 30%;
		margin: 1%;
	}
	.btnBlue {
		width: 7%;
	}
	.btnBluEdit {
		letter-spacing: 1px;
		border-radius: 10px;
		width: 7%;
		color: #ffffff;
		background-color: var(--my-dark-blue);
		width: 50%;
		margin: 1%;
	}
	.btnWhite {
		width: 7%;
	}
</style>
