<script>
	import { onMount } from "svelte";
	//TODODELETE import LayoutNav from "../../../components/Layouts/Layout_Nav.svelte";
	import LayoutModal from "../../../components/Layouts/Layout_Modal.svelte";
	import { _ } from "svelte-i18n";
	//TODODELETE import AddDetailsMedications from "../../../components/addDetailsMedications.svelte";
	//TODODELETE import LayoutPatient from "../../../components/Layouts/Layout_Patient.svelte";
	import SearchDoctorGral from "../../../components/SearchDoctorGral.svelte";
	import ModalNotePatient from "../../../components/ModalNotePatient.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { registerDetail, language, user, patient } from "../../../helpers/storage/stores";
	import { currentSession } from "../../../helpers/storage/sessions.js";
	import { formatDate } from "../../../helpers/helpers";
	import ModalComplete from "../../../components/ModalComplete.svelte";
	import {
		//TODODELETE utils_files_get,
		professional_exam_pdf_add,
		// professional_medications_pdf_add,
		utils_files_add,
		generic_exam_detail_update,
		generic_exam_detail_complete,
		generic_exam_detail_get,
		generic_interview_detail_notes_add,
		generic_interview_detail_update,
		generic_interview_detail_complete,
		generic_interview_detail_get,
		//utils_files_get,
	} from "../../../helpers/apiBackend";

	//let typeAttachment = "Patologia_Detalle__c";
	//TODODELETE let idTypeAttachment = "";
	//let file_type = "Certificado patologia";
	//TODODELETE let fileAttPatologia = {};
	let dateSession = "";
	let modalAssing = false;
	let selectedDoctor = { Name: "", Id: "" };
	//TODODELETE let type = "";
	let patching = true;
	let suggestedDate = "";
	let modalCompleteLog = false;
	let modalComplete = false;
	let modalAddNote = false;
	//TODODELETE let modalBibliogra = false;
	let byFile = false;
	let dataAdjunto = [];
	let complexItems = [];
	let complexItemsRol = [];
	let complexItemsStatus = [];
	let myType, myScope, myParams, originalRecord, myHeaders, myToken, myRecomendedDoctor;
	let datesuggested_data = ""; //new Date().toISOString().split("T")[0];
	let [editRealDate, modalLoadingAnimation, modalSuccess] = [false, false, false];
	let modalEdit = false;
	let clarifications = "";
	let diagnosis = "";
	let objectName = "";
	let firstLetter = "";
	// VALIDAR QUE LO CONSEGUIMOS
	//const AWS_HOST = process.env.HOST_DEV;
	if ($currentSession.hasOwnProperty("signInUserSession")) {
		myToken = $currentSession["signInUserSession"]["accessToken"]["jwtToken"];
	} else {
		alert("Error!");
	}
	function openProfessionalsModal() {
		// Lógica para cambiar el estado de modalAssing
		modalSolicitar = !modalSolicitar;
		modalAssing = !modalAssing;
	}
	function clearSelectedDoctor() {
		// Lógica para borrar el profesional seleccionado
		selectedDoctor = { name: "", id: "" };
	}

	const completeSession = async (e) => {
		modalCompleteLog = true;
		modalAddNote = false;

		if (e.detail.hasOwnProperty("body")) {
			let dataBody = e.detail.body;
			// console.log({ e });
			// console.log({ dataBody });

			if (myType == "Estudios" && modalComplete) {
				// console.log("Estudios");
				let myBody = {
					Id: $registerDetail["detail"].Id,
					completed: true,
					evolvedBy: $user.Id,
					evolvedByType: "D", //P or D
					evolution: dataBody.Evolucion__c,
					dateCompleted: dataBody.Fecha_de_sesion__c,
				};
				await generic_exam_detail_complete(myBody).then((data) => {
					// console.log({ data });
					if (data.status == 200) {
						modalComplete = false;
						modalSuccess = true;
					} else {
						//modalComplete = false;
						modalError = true;
					}
					// modalLoading = false;
				});
			}
			if (myType !== "Estudios" && modalComplete) {
				// console.log("Citas");
				let myBody = {
					id: $registerDetail["detail"].Id,
					completed: true,
					evolvedBy: $user.Id,
					evolvedByType: "D", //P or D
					evolution: dataBody.Evolucion__c,
					dateCompleted: dataBody.Fecha_de_sesion__c,
				};
				await generic_interview_detail_complete(myBody).then((data) => {
					// console.log({ data });
					if (data.status == 200) {
						modalComplete = false;
						modalSuccess = true;
					} else {
						//modalComplete = false;
						modalError = true;
					}
					// modalLoading = false;
				});
			}

			if (myType !== "Estudios" && !modalComplete) {
				//if (!modalComplete) {
				// console.log("Notas");
				let data = {
					id: $registerDetail["detail"].Id,
					typeNote: "Note_professional",
					evolvedBy: $user.Id,
					description: dataBody.notePatient,
				};
				// console.log({ data });
				const result = await generic_interview_detail_notes_add(data);
				// console.log({ result });

				if (result.status === 200) {
					modalLoadingAnimation = false;
					modalComplete = false;
					modalSuccess = true;
				} else {
					//modalComplete = false;
					modalError = true;
				}
			}
		}
	};

	// const professional_medications_pdf_add_b = async () => {
	// 	modalLoadingAnimation = true;
	// 	await professional_medications_pdf_add($registerDetail["detail"]["Id"], $user.Id, $language).then((data) => {
	// 		window.location.reload();
	// 		console.log(data);
	// 	});
	// };

	onMount(async () => {
		complexItems = $_("RegisterDetailProf.select_activity");
		complexItemsRol = $_("RegisterDetailProf.select_rol");
		complexItemsStatus = $_("RegisterDetailProf.select_status_detail");

		if (Object.keys($registerDetail).length > 0) {
			//Busca si tienen adjuntos
			//dataAdjunto = await utils_files_get($registerDetail["detail"]["id"], $currentSession);
			// console.log({ $registerDetail });

			// if (dataAdjunto.data.length > 0 && dataAdjunto.data[0].hasOwnProperty("Link__c")) {
			// 	type = dataAdjunto.data[0].Link__c.split("/")[3];
			// 	type = type.split(".")[1];
			// 	console.log({ type });
			// 	dataAdjunto = dataAdjunto.data;
			// }
			// Validate Parameters (RecordType / Scope)
			delete $registerDetail["Actividad_Detalles__r"];
			myParams = document.location.href.split("?")[1];
			if (myParams.indexOf("&") > -1) {
				myParams = myParams.split("&");
				myType = myParams[0].split("=")[1];
				myScope = myParams[1].split("=")[1];
			}
			if (myScope == undefined || myScope == "" || myType == undefined || myType == "") {
				window.location.href = "#/GeneralError";
			}

			// Delete nulls
			Object.keys($registerDetail["register"]).forEach((key) => {
				if ($registerDetail["register"][key] == null || $registerDetail["register"][key] == undefined) {
					$registerDetail["register"][key] = "";
				}
			});
			if ($registerDetail.hasOwnProperty("detail")) {
				Object.keys($registerDetail["detail"]).forEach((key) => {
					if ($registerDetail["detail"][key] == null || $registerDetail["detail"][key] == undefined) {
						$registerDetail["detail"][key] = "";
					}
				});
			}

			// await utils_files_get($user?.DNI__c).then((data) => {
			// 	console.log("utils_files_get");
			// 	console.log({ data });
			// 	byFile = data.exists;
			// 	console.log({ byFile });
			// });

			// Get resources and back up original record
			originalRecord = JSON.parse(JSON.stringify($registerDetail));
			patching = false;
			// console.log({ myType });
			// console.log({ myType });
			if (myType == "Estudios") {
				objectName = "ExamsDetailCareplan__c";
				// console.log("generic_exam_detail_get");
				await generic_exam_detail_get($registerDetail["detail"]["Id"], $language).then((result) => {
					// console.log("Exam Data", {result})
					console.log('RegisterDetail - generic_exam_detail_get - result.data ', result.data);
					if (result.data.hasOwnProperty("data")) {
						// console.log("Tiene data", {result})
						$registerDetail["detail"] = result.data.data;
					}
				});
			} else {
				objectName = "InterviewDetailCareplan__c";
				console.log("generic_interview_detail_get");
				await generic_interview_detail_get($registerDetail["detail"]["Id"], $language).then((result) => {
					console.log("Interview Data", {result})
					if (result.data.hasOwnProperty("data")) {
						console.log("Tiene data", {result})
						$registerDetail["detail"] = result.data.data;
					}
				});
			}
			console.log("REG DETAIL",$registerDetail)
			if ($registerDetail["detail"].hasOwnProperty("files")) {
				dataAdjunto = $registerDetail["detail"].files;
			}
			selectedDoctor.Name = $registerDetail["detail"].assignedProfessionalName;
			selectedDoctor.Id = $registerDetail["detail"].assignedProfessionalId;

			// console.log({ selectedDoctor });
			// console.log({ myType });
			firstLetter = myType.substring(0, 1);
			// console.log({ firstLetter });
		}
	});

	// Generate body to send
	let myBody = {};
	// const createBody = (key, value) => {
	// 	myBody[key] = value;
	// 	console.log({ myBody });
	// };

	// Update
	// PATCH (204 Only / Return code with body)
	let modalPatch = false;

	const updateRecord = async () => {
		// await update_activity_detail(myBody, $registerDetail["detail"].id).then((data) => {
		// 	console.log({ data });
		// 	if (data.status == 204) {
		// 		modalSuccess = true;
		// 	} else {
		// 		modalError = true;
		// 	}
		// 	modalLoading = false;
		// });
	};

	// Solicitar
	let [panelSolicitar, modalSolicitar] = [false, false];
	let bodySolicitar = {};
	// if ($registerDetail.hasOwnProperty("detail")) {
	// 	if ($registerDetail["detail"].hasOwnProperty("nueva_Accion_sugerida__c")) {
	// 		if (
	// 			$registerDetail["detail"]["nueva_Accion_sugerida__c"] == "Solicitar" &&
	// 			$registerDetail["detail"]["requested"] == false
	// 		) {
	// 			panelSolicitar = true;
	// 		}
	// 	}
	// }
	if ($registerDetail.hasOwnProperty("detail") && $registerDetail["detail"]["requested"] == false) {
		panelSolicitar = true;
	}

	//let solicitarUrl = `${AWS_HOST}/generic/update_activity_detail?activity_id=${$registerDetail["detail"]["id"]}`;
	console.log($registerDetail);
	console.log('RegisterDetail linea 292');
	console.log({ dataAdjunto });
	//myHeaders = { Authorization: myToken };

	const solicitarCall = async () => {
		modalLoadingAnimation = true;
		modalSolicitar = false;
		clarifications = $registerDetail["detail"]["clarifications"];
		diagnosis = $registerDetail["detail"]["diagnosis"];

		if (myType == "Estudios") {
			bodySolicitar = {
				id: $registerDetail["detail"]["Id"],
				suggestedMedicalCenter: myRecomendedDoctor,
				dateSuggested: suggestedDate,
				specification: $registerDetail["detail"]["specification"],
				clarifications,
				diagnosis,
				role: "Professional",
			};
			// console.log({ bodySolicitar });
			await generic_exam_detail_update(bodySolicitar)
				.then(async (data) => {
					// console.log("Rta solicitar", { data });
					if (data.status == 200) {
					//if (data.updated != "") {
						modalLoadingAnimation = !modalLoadingAnimation;
						modalSuccess = !modalSuccess;
						//window.location.href = "#/Records?type=" + myType;
					} else {
						modalLoadingAnimation = false;
					}
				})
				.catch((solicitarError) => {
					console.log({ solicitarError });
				})
				.finally(() => {});
		} else {
			// console.log({ selectedDoctor });
			bodySolicitar = {
				Id: $registerDetail["detail"]["Id"],
				suggestedProfessional: myRecomendedDoctor,
				dateSuggested: datesuggested_data,
				assignedProfId: selectedDoctor.Id ? selectedDoctor.Id : "",
				statusAssigned: selectedDoctor.Id ? "A" : "",
				typeUser: "D",
			};
			await generic_interview_detail_update(bodySolicitar)
				.then(async (data) => {
					// console.log({ data });
					if (data.statusCode == 200) { //TODO: Refactorizar el back pq no me viene el status 200
					//if (data.updated != "") {
						modalLoadingAnimation = !modalLoadingAnimation;
						modalSuccess = !modalSuccess;
						//window.location.href = "#/Records?type=" + myType;
					} else {
						modalLoadingAnimation = false;
					}
				})
				.catch((solicitarError) => {
					console.log({ solicitarError });
				})
				.finally(() => {});
		}
	};

	let sendChanges = false;
	$: {
		if (originalRecord != undefined) {
			if (JSON.stringify(originalRecord) != JSON.stringify($registerDetail)) {
				sendChanges = true;
			} else {
				sendChanges = false;
			}
		}
		if (myScope == "detail") {
			if (dateSession !== "") {
				$registerDetail["detail"]["dateSuggested"] = dateSession;
			}
		}
	}
</script>

<section>
	<!-- <LayoutNav secure={true} rol="Profesional_de_Salud"> -->
	<!-- <LayoutPatient> -->
	{#if $registerDetail["detail"]["name"] && !patching}
		<div class="conteiner-detail py-2 border rounded shadow-sm my-3 bg-white">
			<div class="Title-detail">
				<!-- {#if myType == "Estudios"}
							<p class="title">{myType}|{$registerDetail["detail"]["name"]}</p>
						{/if}
						{#if myType == "Citas/Entrevistas"}
							<p class="title">
								
								{myType | $registerDetail["detail"]["name"]}
							</p>
						{/if} -->
				<h5 class="title text-muted" style="margin-left: 2%;">
					{myType} | {$registerDetail["detail"]["name"]}
				</h5>
			</div>
			<hr />

			<div class="form-detail">
				<div class="row">
					<!-- requested -->
					<div class="column">
						<p class="Completado_p">{$_("RegisterDetailProf.text_required")}</p>
						{#if $registerDetail["detail"]["requested"]}
						<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
						{:else}
						<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
						{/if}
					</div>
					<!-- completed -->
					<div class="column">
						<p class="Completado_p">{$_("RegisterDetailProf.text_complete")}</p>
						{#if $registerDetail["detail"]["completed"]}
						<img src="./img/toggleon.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
						{:else}
						<img src="./img/toggleoff.svg" alt="Flecha hacia abajo" style="width: 32px; height: 32px;">
						{/if}
					</div>
					<!-- createdByName -->
					<div class="column">
						<p class="Creado_p">{$_("RegisterDetailProf.text_th_createdby")}</p>
						{#if $registerDetail["detail"].hasOwnProperty("createdByName")}
							{$registerDetail["detail"]["createdByName"]}
						{:else}
							<span class="text-muted">{$_("RegisterDetailProf.text_not_State")}</span>
						{/if}
					</div>
				</div>
			</div>
			{#if myType !== "Estudios"}
				<div class="form-detail">
					<div class="row">
						<!-- dateSuggested -->
						<div class="column">
							<p class="Completado_p">{$_("RegisterDetailProf.text_date_suggested")}</p>
							{#if $registerDetail["detail"]["dateSuggested"] && $registerDetail["detail"]["dateSuggested"] !== ""}
								{formatDate($registerDetail["detail"]["dateSuggested"], $language)}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_State")}</span>
							{/if}
						</div>
						<!-- assignedProfessionalStatus -->
						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailProf.text_th_suggested")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("assignedProfessionalSuggestion") && $registerDetail["detail"]["assignedProfessionalSuggestion"] != "" && $registerDetail["detail"]["assignedProfessionalSuggestion"] != null}
								{$registerDetail["detail"]["assignedProfessionalSuggestion"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
							{/if}
						</div>

						<div class="column">
							<p class="Completado_p">
								{$_("RegisterDetailProf.text_th_assigned")}
							</p>
							{#if $registerDetail["detail"]["assignedProfessionalName"]}
								{$registerDetail["detail"]["assignedProfessionalName"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
							{/if}
						</div>
					</div>
				</div>

				<!-- <div class="form-detail">
							<div class="row">
								
								<div class="column">
									<p class="Completado_p">{$_("RegisterDetailProf.text_date_suggested")}</p>
									{#if $registerDetail["detail"]["dateSuggested"] && $registerDetail["detail"]["dateSuggested"] !== ""}
										{formatDate($registerDetail["detail"]["dateSuggested"], $language)}
									{:else}
										<span class="text-muted">{$_("RegisterDetailProf.text_not_State")}</span>
									{/if}
								</div>
								
								<div class="column">
									<p class="Creado_p">{$_("RegisterDetailProf.text_th_suggested")}</p>
									{#if $registerDetail["detail"].hasOwnProperty("assignedProfessionalSuggestion") && $registerDetail["detail"]["assignedProfessionalSuggestion"] != "" && $registerDetail["detail"]["assignedProfessionalSuggestion"] != null}
										{$registerDetail["detail"]["assignedProfessionalSuggestion"]}
									{:else}
										<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
									{/if}
								</div>

								<div class="column">
									<p class="Completado_p">
										{$_("RegisterDetailProf.text_th_assigned")}
									</p>
									{#if $registerDetail["detail"]["assignedProfessionalName"]}
										{$registerDetail["detail"]["assignedProfessionalName"]}
									{:else}
										<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
									{/if}
								</div>
							</div>
						</div> -->
			{/if}

			{#if $registerDetail["detail"]["requested"] == true && $registerDetail["detail"].hasOwnProperty("assignedProfessionalName")}
				<div class="form-detail">
					<div class="row">
						<!-- assignedProfessionalStatus -->
						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailProf.text_th_session_date")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("dateCompleted") && $registerDetail["detail"]["dateCompleted"] != "" && $registerDetail["detail"]["dateCompleted"] != null}
								{formatDate($registerDetail["detail"]["dateCompleted"], $language)}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
							{/if}
						</div>
						<!-- dateSuggested -->

						<div class="column">
							<p class="Completado_p">{$_("RegisterDetailProf.text_evolution_date")}</p>
							{#if $registerDetail["detail"]["dateReal"]}
								{formatDate($registerDetail["detail"]["dateReal"], $language)}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
							{/if}
						</div>

						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailProf.text_State")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("assignedProfessionalStatus") && $registerDetail["detail"]["assignedProfessionalStatus"] != "" && $registerDetail["detail"]["assignedProfessionalStatus"] != null}
								{complexItemsStatus[$registerDetail["detail"]["assignedProfessionalStatus"]]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
							{/if}
						</div>

						{#if $registerDetail["detail"]["evolvedByName"] && $registerDetail["detail"]["evolvedByType"] == "D"}
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_evolved_by")}</p>
								{#if $registerDetail["detail"]["evolvedByName"]}
									{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
										$registerDetail["detail"]["evolvedByType"]
									]}
								{:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
								{/if}
							</div>
						{/if}
						<!-- assignedProfessionalStatus -->
					</div>
				</div>
			{/if}
			{#if $registerDetail["detail"]["status"] == "WithNote"}
				<div class="form-detail">
					<div class="row">
						<!-- notesPatient -->
						{#if $registerDetail["detail"]["notesPatient"] != null}
							<div class="column">
								<p class="Creado_p">{$_("RegisterDetailProf.text_th_session_Note_P")}</p>
								{#if $registerDetail["detail"].hasOwnProperty("notesPatient")}
									{$registerDetail["detail"]["notesPatient"]}
								{:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
								{/if}
							</div>
						{/if}
						{#if $registerDetail["detail"]["evolvedByName"] && $registerDetail["detail"]["evolvedByType"] !== "D"}
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_note_by")}</p>

								{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
									$registerDetail["detail"]["evolvedByType"]
								]}
							</div>
						{/if}
					</div>
				</div>
			{/if}

			{#if $registerDetail["detail"]["notesProfessional"]}
				<div class="form-detail">
					<div class="row">
						<!-- notesPatient -->
						<div class="column">
							<p class="Creado_p">{$_("ModalComplete.text_title_note_center")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("notesProfessional")}
								{$registerDetail["detail"]["notesProfessional"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
							{/if}
						</div>
						{#if $registerDetail["detail"]["evolvedByName"] && $registerDetail["detail"]["evolvedByType"] !== "D"}
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_note_by")}</p>

								{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
									$registerDetail["detail"]["evolvedByType"]
								]}
							</div>
						{/if}
					</div>
				</div>
			{/if}

			{#if $registerDetail["detail"].hasOwnProperty("suggestedMedicalCenter")}
				<!-- ESTUDIO -->
				<div class="form-detail">
					<div class="row">
						<!-- dateSuggested -->
						<div class="column">
							<p class="Completado_p">{$_("RegisterDetailProf.text_date_suggested")}</p>
							{#if $registerDetail["detail"]["dateSuggested"] && $registerDetail["detail"]["dateSuggested"] !== ""}
								{formatDate($registerDetail["detail"]["dateSuggested"], $language)}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
							{/if}
						</div>
						<!-- assignedProfessionalStatus -->
						<div class="column">
							<p class="Creado_p">{$_("RegisterDetailProf.text_Suggested_Medical")}</p>
							{#if $registerDetail["detail"].hasOwnProperty("suggestedMedicalCenter") && $registerDetail["detail"]["suggestedMedicalCenter"] != "" && $registerDetail["detail"]["suggestedMedicalCenter"] != null}
								{$registerDetail["detail"]["suggestedMedicalCenter"]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_not_assigned")}</span>
							{/if}
						</div>
						<!-- 
								<div class="column">
									
								</div> -->
						<div class="column">
							<p class="Completado_p">{$_("RegisterDetailProf.text_evolved_by")}</p>
							{#if $registerDetail["detail"]["evolvedByName"]}
								{$registerDetail["detail"]["evolvedByName"]} - {complexItemsRol[
									$registerDetail["detail"]["evolvedByType"]
								]}
							{:else}
								<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
							{/if}
						</div>
					</div>
				</div>
				<!-- {#if $registerDetail["detail"]["requested"]} -->
				<div class="form-detail">
					<p class="Completado_p">{$_("RegisterDetailProf.text_specification")}</p>
					{#if $registerDetail["detail"]["specification"]}
						{$registerDetail["detail"]["specification"]}
					{:else}
						<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
					{/if}
				</div>
				<div class="form-detail">
					<p class="Completado_p">{$_("RegisterDetailProf.text_clarifications")}</p>
					{#if $registerDetail["detail"]["clarifications"]}
						{$registerDetail["detail"]["clarifications"]}
					{:else}
						<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
					{/if}
				</div>
				<div class="form-detail">
					<p class="Completado_p">{$_("RegisterDetailProf.text_diagnosis")}</p>
					{#if $registerDetail["detail"]["diagnosis"]}
						{$registerDetail["detail"]["diagnosis"]}
					{:else}
						<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
					{/if}
				</div>
				<!-- {/if} -->

				{#if $registerDetail["detail"]["completed"]}
					<div class="form-detail">
						<div class="row">
							<!-- assignedProfessionalStatus -->
							<div class="column">
								<p class="Creado_p">{$_("RegisterDetailProf.text_th_session_date")}</p>
								{#if $registerDetail["detail"].hasOwnProperty("dateCompleted") && $registerDetail["detail"]["dateCompleted"] != "" && $registerDetail["detail"]["dateCompleted"] != null}
									{formatDate($registerDetail["detail"]["dateCompleted"], $language)}
								{:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_session_not_date")}</span>
								{/if}
							</div>
							<div class="column">
								<p class="Completado_p">{$_("RegisterDetailProf.text_evolution_date")}</p>
								{#if $registerDetail["detail"]["dateReal"]}
									{formatDate($registerDetail["detail"]["dateReal"], $language)}
								{:else}
									<span class="text-muted">{$_("RegisterDetailProf.text_th_not_evoled")}</span>
								{/if}
							</div>
						</div>
					</div>
				{/if}
			{/if}
			{#if $registerDetail["detail"]["evolution"]}
				<div class="form-detail">
					<p class="Completado_p">{$_("RegisterDetailProf.text_title_Evolution")}</p>
					{#if $registerDetail["detail"]["evolution"]}
						{$registerDetail["detail"]["evolution"]}
					{:else}
						<span class="text-muted">...</span>
					{/if}
				</div>
			{/if}
			{#if dataAdjunto.length > 0}
				<div class="form-detail">
					<!-- {#if dataAdjunto.length > 0} -->
						<p class="Completado_p">{$_("RegisterDetailProf.text_attach")}</p>
						{#each dataAdjunto as file}
							{#if file.type != "Orden"}
								
								{#if file.link.includes(".pdf")}
									<!-- <img
										class="cursor pointer"
										on:click={() => {}}
										src={file.link}
										alt="Document"
										width="3%" /><a href={file.link} download
										>{$_("RegisterDetailProf.button_download")}</a>
								{:else}
									<img
										class="cursor pointer"
										on:click={() => {}}
										src="img/pdfIcon.png"
										alt="PDF"
										width="3%" /><a href={file.link} download
										>{$_("RegisterDetailProf.button_download")}</a> -->
								
									 <!-- Mostrar ícono PDF si el archivo es un PDF -->
									<img
									class="cursor pointer"
									on:click={() => {}}
									src="img/pdfIcon.png"
									alt="PDF"
									width="3%" />
								<a href={file.link} download>{$_("RegisterDetailProf.button_download")}</a>
								{:else}
								<!-- Mostrar la imagen del archivo si no es un PDF -->
								<img
									class="cursor pointer"
									on:click={() => {}}
									src={file.link}
									alt="Document"
									width="3%" />
								<a href={file.link} download>{$_("RegisterDetailProf.button_download")}</a>
								{/if}
							{:else if file.type === "Orden"}

								{#if file.link.includes(".pdf")}
									<img
									class="cursor pointer"
									on:click={() => {}}
									src="img/pdfIcon.png"
									alt="PDF"
									width="3%" />
									<a href={file.link} download>{$_("RegisterDetailProf.button_download")}</a>
								{/if}

							{/if}
						{:else}
							<span class="text-muted">{$_("RegisterDetailProf.text_not_attach")}</span>
						{/each}
					<!-- {/if} -->
				</div>
			{/if}
			<div class="form-detail">
				{#if panelSolicitar}
					<div class="d-flex justify-content-center">
						<button
							on:click={() => {
								modalSolicitar = true;
								datesuggested_data = new Date($registerDetail.detail.dateSuggested)
									.toISOString()
									.split("T")[0];
								myRecomendedDoctor = $registerDetail["detail"].assignedProfessionalSuggestion
									? $registerDetail["detail"].assignedProfessionalSuggestion
									: $registerDetail["detail"].suggestedMedicalCenter;
								suggestedDate = datesuggested_data;
							}}
							class="btnSolicitar">{$_("RegisterDetailProf.button_Request")}</button>
					</div>
				{/if}

				{#if $registerDetail["detail"]["completed"] == false && $registerDetail["detail"]["requested"] == true}
					<br />
					<div class="d-flex justify-content-center">
						{#if myType !== "Estudios"}
							<button
								on:click={() => {
									// console.log("Completar", myType);
									modalAddNote = true;
								}}
								class="btnAdd">
								+ {$_("RegisterDetailProf.button_add_note")}</button>
						{/if}

						<button
							on:click={() => {
								// console.log("Completar", myType);
								modalSolicitar = true;
								modalEdit = true;
								datesuggested_data = new Date($registerDetail.detail.dateSuggested)
									.toISOString()
									.split("T")[0];
								myRecomendedDoctor = $registerDetail["detail"].assignedProfessionalSuggestion
									? $registerDetail["detail"].assignedProfessionalSuggestion
									: $registerDetail["detail"].suggestedMedicalCenter;
								suggestedDate = datesuggested_data;
								clarifications = $registerDetail["detail"].clarifications;
								diagnosis = $registerDetail["detail"].diagnosis;
							}}
							class="btnWhite ml-3">{$_("RegisterDetailProf.button_Edit")}</button>

						<button
							on:click={() => {
								// console.log("Completar", myType);
								modalComplete = true;
							}}
							class="btnBlue ml-3">{$_("RegisterDetailProf.button_to_complete")}</button>
					</div>
				{/if}
			</div>
		</div>

		<div class="text-center">
			{#if myType}
				<a class="mx-2" style="padding: 1rem 0;" href="#/Records?type={myType}"
					>{$_("RegisterDetailProf.button_return")} {complexItems[myType]}</a>
			{/if}
			<a class="mx-2" style="padding: 1rem 0;" href="#/Plan">{$_("RegisterDetailProf.button_return_plan")}</a>
			<div />
		</div>
	{:else}
		<div class="p-5 text-center">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailMedicationProf.loading")}</p>
		</div>
	{/if}
	<!-- </LayoutPatient> -->
	<!-- </LayoutNav> -->
</section>

<!-- Confirm -->
<LayoutModal isOpenModal={modalPatch} on:closeModal={(modalPatch = false)}>
	<div class="p-3 text-center">
		<p class="m-0">{$_("RegisterDetailProf.text_request_change")}</p>
		<div class="d-flex justify-content-between my-3">
			<!-- svelte-ignore component-name-lowercase -->
			<button
				on:click={() => {
					modalPatch = false;
				}}
				class="mx-2 btnWhite py-0">{$_("RegisterDetailProf.button_Cancel")}</button>
			<!-- svelte-ignore component-name-lowercase -->
			<button
				on:click={() => {
					updateRecord();
				}}
				class="mx-2 btnBlue py-0">{$_("RegisterDetailProf.button_Send")}</button>
		</div>
	</div>
</LayoutModal>

<LayoutModal
	tam="30"
	isOpenModal={modalAddNote}
	on:closeModal={() => {
		modalAddNote = false;
	}}>
	<ModalNotePatient
		idTypeAttachment={$registerDetail["detail"]["Id"]}
		objectName={myType}
		on:complete={(e) => {
			completeSession(e);
			modalLoadingAnimation = true;
		}}
		on:cancel={() => {
			modalAddNote = false;
		}} />
</LayoutModal>

<!-- Modal Solicitar -->
<!-- IN USE!! -->
<LayoutModal
	tam="40"
	isOpenModal={modalSolicitar}
	on:closeModal={() => {
		modalSolicitar = false;
	}}>
	<div class="p-3 text-center">
		<div class="my-2">
			{#if myType !== "Estudios"}
				<br />
				<div class="form-group-interview">
					<!-- <div class="container border shadow"> -->
					<div class="container-title">
						<h2 class="Title-detail">
							{modalEdit ? $_("RegisterDetailProf.button_Edit") : $_("RegisterDetailProf.button_Request")}
							{$registerDetail["detail"]["name"]}
						</h2>
					</div>

					<div class="datesuggested">
						<label for="datesuggested">{$_("RegisterDetailProf.text_date_suggested")}:</label>
						<input
							name="datesuggested"
							bind:value={datesuggested_data}
							class="form-control form-control-sm"
							type="date" />
					</div>
					<br />
					<div class="profSuggested">
						<label for="sugerir-doctor">{$_("RegisterDetailProf.text_prof_suggested")} S:</label>
						<input
							name="sugerir-doctor"
							bind:value={myRecomendedDoctor}
							class="form-control form-control-sm"
							type="text" />
					</div>
					<br />
					<div class="profAssing">
						<div
							style="
          width: 100%;
          text-align: justify;
        ">
							<label for="Assing-profesional">{$_("RegisterDetailProf.text_th_assigned")}:</label>
						</div>
						{#if selectedDoctor.Name}
							<div class="selected-doctor-container">
								<div class="selected-doctor">
									<span>{selectedDoctor.Name}</span>
									<button class="btnDeleted" on:click={clearSelectedDoctor}>Borrar</button>
								</div>
							</div>
						{:else}
							<div
								style="
          width: 100%;
          text-align: center;
        ">
								<!-- svelte-ignore a11y-label-has-associated-control -->
								<label class="link-select" on:click={openProfessionalsModal}
									>{$_("RegisterDetailProf.select_prof_assing")}</label>
							</div>
						{/if}
					</div>
				</div>
			{:else}
				<div class="form-group-exam">
					<div class="record-request-section">
						<div class="container-title">
							<h2 class="Title-detail">
								{modalEdit
									? $_("RegisterDetailProf.button_Edit")
									: $_("RegisterDetailProf.button_Request")}
								{$registerDetail["detail"]["name"]}
							</h2>
						</div>

						<div class="form-group text_sugetedDate">
							<div class="label-container">
								<label for="sugetedDate" class="input-label">
									{$_("List_Records_prof.text_th_date_planned")}:
								</label>
							</div>

							<input
								class="input-log"
								type="date"
								id="sugetedDate"
								pattern="^(?:0[1-9]|[1-2][0-9]|3[0-1])-(?:0[1-9]|1[0-2])-(?:19|20)\d{2}$"
								name="trip-start"
								bind:value={suggestedDate} />
						</div>

						<div class="form-group input-label-container">
							<label for="profesionalSugerido" class="input-label">
								{$_("List_Records_prof.text_SuggestedMedical")}s:
							</label>
							<input
								id="profesionalSugerido"
								type="text"
								class="input-field"
								bind:value={myRecomendedDoctor} />
						</div>

						<div class="form-group input-label-container">
							<label for="clarifications" class="input-label">
								{$_("List_Records_prof.text_specification")} :
							</label>
							<textarea
								bind:value={$registerDetail["detail"]["specification"]}
								class="form-control"
								id="evolucion"
								rows="3"
								maxlength="255" />
						</div>

						<div class="form-group input-label-container">
							<label for="clarifications" class="input-label">
								{$_("List_Records_prof.text_clarifications")}:
							</label>
							<textarea
								bind:value={$registerDetail["detail"]["clarifications"]}
								class="form-control"
								id="evolucion"
								rows="3"
								maxlength="255" />
						</div>

						<div class="form-group input-label-container">
							<label for="diagnosis" class="input-label">
								{$_("List_Records_prof.text_diagnosis")}:
							</label>

							<textarea
								bind:value={$registerDetail["detail"]["diagnosis"]}
								class="form-control"
								id="evolucion"
								rows="3"
								maxlength="255" />
						</div>
					</div>

					<br />
				</div>
			{/if}
		</div>
		<br />
		<div class="text-centrar">
			<br />
			{#if datesuggested_data != ""}
				<button
					on:click={() => {
						solicitarCall();
					}}
					class="py-0 mx-2 py-2 btnBlue">{$_("RegisterDetailProf.button_Send")}</button>
			{/if}
			<button
				on:click={() => {
					modalSolicitar = false;
				}}
				class="btnWhite py-0 mx-2 py-2">{$_("RegisterDetailProf.button_Cancel")}</button>
		</div>
	</div>
</LayoutModal>

<!-- Modal Cargando -->
<LayoutModal
	tam="30"
	isOpenModal={modalLoadingAnimation}
	on:closeModal={() => {
		modalLoadingAnimation = false;
	}}>
	<div class="p-5 text-center">
		<Loader text={false} />
		<p class="text-muted">{$_("RegisterDetailProf.loading")}</p>
	</div>
</LayoutModal>

<!-- Modal Success -->
<LayoutModal tam="30" isOpenModal={modalSuccess}>
	<div class="p-2 text-center">
		{#if myType}
			<p>{$_("RegisterDetailProf.text_success")}</p>
			<a class="mx-2" href="#/Records?type={myType}">{$_("RegisterDetailProf.button_return")} {myType}</a>
		{/if}
	</div>
</LayoutModal>

<!-- Modal Success -->

<!-- <LayoutModal
	tam="40"
	isOpenModal={modalEdit}
	on:closeModal={() => {
		modalEdit = false;
	}}>
	<AddDetailsMedications
		nameMed={$registerDetail["register"]["Farmaco__c"]}
		patientId={$registerDetail["register"]["Paciente__c"]}
		planId={$registerDetail["register"]["Paciente__c"]}
		actividadCabeceraId={$registerDetail["register"]["Id"]}
		actividadDetalleId={$registerDetail["detail"]["Id"]}
		editar="true" />
</LayoutModal> -->

<LayoutModal
	tam="30"
	isOpenModal={modalComplete}
	on:closeModal={() => {
		modalComplete = false;
	}}>
	{#if modalCompleteLog}
		<div class="p-5 text-center">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailProf.loading")}</p>
		</div>
	{:else}
		<ModalComplete
			idTypeAttachment={$registerDetail["detail"]["Id"]}
			objectName={firstLetter}
			on:complete={(e) => {
				completeSession(e);
			}}
			on:cancel={() => {
				modalComplete = false;
			}} />
	{/if}
</LayoutModal>
{#if modalAssing}
<LayoutModal
	tam="80"
	isOpenModal={modalAssing}
	on:closeModal={() => {
		modalAssing = false;
	}}>
	<div class="text-center">
		<div class="text-center">
			<SearchDoctorGral
				specialty={$registerDetail["register"]["name"]}
				country={$patient["country"]}
				stateCabecera={""}
				on:dateProf={(e) => {
					console.log("EVENTO RECIBIDO: ", e.detail);
					if (e.detail.hasOwnProperty("Name")) {
						selectedDoctor = e.detail;
						modalAssing = false;
						modalSolicitar = true;
					}
				}} />
		</div>

		<!-- {#if selectedDoctor}
			<div class="border shadow-sm rounded">
				<div>
					<p>{$_("RegisterDetailPatient.text_Professional")}</p>

					
					<label>{selectedDoctor.Name}</label>
					<p>{$_("RegisterDetailPatient.text_date_suggested")}</p>
					<input
						class="input-log"
						type="date"
						pattern="\d{(1, 2)}/\d{(1, 2)}/\d{4}"
						name="trip-start"
						bind:value={suggestedDate} />
				</div>
			</div>
		{/if} -->
	</div>
</LayoutModal>
{/if}
<style>
	.form-detail {
		margin: 1%;
		margin-left: 3%;
		width: 100%;
	}
	.conteiner-detail {
		display: flex;
		flex-wrap: wrap;
		margin-left: 5%;
		width: 90%;
		max-height: 65vh;
		overflow: auto;
	}

	.row {
		display: flex;
		width: 100%;
	}

	.column {
		flex-basis: 33.33%; /* Para dividir equitativamente en tres columnas */
		padding: 10px;
		box-sizing: border-box;
	}
	hr {
		border: none;
		height: 1px; /* Grosor de la línea */
		background-color: #ccc; /* Color de la línea */
		margin: 20px 0; /* Espacio alrededor de la línea */
	}
	.link-select {
		color: #116ac9;
		text-decoration: underline; /* Subrayado por defecto */
		cursor: pointer;
		transition: color 0.3s ease; /* Efecto de transición suave para el color */
	}

	.link-select:hover {
		color: #094275; /* Cambio de color al pasar el ratón sobre el enlace */
	}
	.record-request-section {
		width: 100%;
	}
	.input-log {
		width: 45%;
	}
	.container-title {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		flex-direction: column;
		align-items: stretch;
		text-align: center;
	}

	.selected-doctor-container {
		border: 1px solid #ccc;
		padding: 10px;
		margin-top: 10px;
		width: 100%;
		border-radius: 10px;
		display: inline-block;
	}

	.selected-doctor {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.btnDeleted {
		letter-spacing: 1px;
		color: #fff;
		border-radius: 10px;
		background-color: #fa0900;
	}

	/* Estilos adicionales para visualización */

	.form-group {
		display: flex;
		margin-bottom: 1rem;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 3%;
	}

	.form-group-exam {
		display: flex;
		margin-bottom: 1rem;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
	}

	@media (max-width: 768px) {
		/* Estilos para pantallas más pequeñas */
		.column {
			flex-basis: 50%; /* Para dividir en dos columnas en pantallas más pequeñas */
		}
	}

	@media (max-width: 480px) {
		/* Estilos para pantallas aún más pequeñas */
		.column {
			flex-basis: 100%; /* Para ocupar todo el ancho en pantallas muy pequeñas */
		}
	}

	.myCustom-toggle {
		transform: scale(1.25);
	}
	.btnAdd {
		border: none;
		background-color: transparent;
		color: #215273;
		font-weight: 500;
		padding: 5px 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	.profSuggested {
		display: flex;
		flex-wrap: wrap;
		margin-left: 5%;
		width: 80%;
	}
	.profAssing {
		display: flex;
		flex-wrap: wrap;
		margin-left: 5%;
		width: 80%;
	}
	.datesuggested {
		display: flex;
		flex-wrap: wrap;
		margin: 5%;
		width: 80%;
	}
	/* .divAdjuntos {
		border-bottom: 1px solid rgb(2, 2, 2);
		height: 3.2rem rem;
		align-items: center;
	} */

	.myCustom-toggleOn {
		color: #fbfdff;
		background-color: #116ac9;
		transform: scale(1.25);
	}

	.myCustom-toggleOff {
		color: #787f87;
		background-color: #ffffff;
	}
	.btnSolicitar {
		margin: 10px;
		/* width: 15%; */
		height: 3rem;
		letter-spacing: 1px;
		/* text-transform: uppercase; */
		color: #ffff;
		border-radius: 10px;
		background-color: #112c4a;
		font-family: "Roboto", sans-serif;
	}

	/* .my-record {
		max-height: 55vh;
		overflow: auto;
	} */
	.Title-detail {
		color: #215273;
		width: 100%;
		font-family: "Roboto", sans-serif;
		font-weight: bold;
		font-size: 25px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
	}
	.title {
		font-size: 25px;
	}

	.Completado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}

	.Creado_p {
		color: #215273;
		font-family: "Roboto", sans-serif;
		font-size: 15px;
		font-weight: bold;
	}
	.btnBlue {
		margin-right: 2%;
	}

	@media only screen and (min-width: 960px) {
		/* styles for browsers larger than 960px; */
	}
	@media only screen and (min-width: 1440px) {
		/* styles for browsers larger than 1440px; */
	}
</style>
