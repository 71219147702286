<script>
	import { onMount } from "svelte";
	import { _ } from "svelte-i18n";
	import { user } from "../../../helpers/storage/stores";
	import { utils_files_add, professional_sudden_detail_add } from "../../../helpers/apiBackend";
	import FileAttach from "../../../components/FileAttach.svelte";
	import Loader from "../../../components/Loader.svelte";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	function closeSubForm() {
		dispatch("closeSubForm");
	}

	export let optionsSubit;
	export let myType;
	export let specification = null;

	let selectedSpecialty = null;
	let selectedFrequency = null;
	let selectedModality = null;
	let medicalCenter = null;
	let clarification = null;
	let diagnosis = null;
	let idTypeAttachment = null;

	let file_type = "Documento";
	let file_type_interv = "evolucion";
	let file_type_exams = "Orden";

	let document = "";
	const objNameList = {
		E: "ExamsDetailCareplan__c",
		C: "InterviewDetailCareplan__c",
	};

	let flagError = false;
	let showAlert = false;
	let message = $_("ModalComplete.text_errorMessages");
	let Loading = false;
	let Evolucion__c = "";
	let date_Session = new Date().toISOString().split("T")[0];

	const uniqueNames = {};

	const filteredOptions = optionsSubit.filter((record) => {
		console.log({ record });
		const { Id, name } = record;
		if (!uniqueNames[name]) {
			uniqueNames[name] = true;
			return true;
		}
		return false;
	});
	function clearSelect() {
		selectedFrequency = null;
		selectedModality = null;
		specification = null;
		const singleFrequency = frequencyOptions.length === 1 ? frequencyOptions[0].Id : null;
		modalityOptions = frequencyOptions;
		const singleModality = modalityOptions.length === 1 ? modalityOptions[0].modality : null;
		selectedModality = singleModality || null;
		selectedFrequency = singleFrequency || null;
		let result = optionsSubit.filter((record) => record.Id === selectedFrequency);
		console.log({ result });
		specification = result[0]?.specification;
	}

	$: specialtyOptions = Array.from(
		new Set(filteredOptions?.map((record) => ({ Id: record.Id, name: record.name })) || [])
	);

	$: frequencyOptions = optionsSubit.filter((record) => record.name === selectedSpecialty);
	$: modalityOptions = frequencyOptions.filter((record) => record.Id === selectedFrequency);

	console.log({ frequencyOptions });

	function isValidDate(dateString) {
		const today = new Date();
		const inputDate = new Date(dateString);

		if (inputDate <= today) {
			console.log("Fecha válida");
			flagError = false;
		} else {
			console.log("Fecha no válida");
			flagError = true;
		}
		const now = new Date();
		const timestampInSeconds = Math.floor(now.getTime() / 1000); // Timestamp en segundos
		console.log(timestampInSeconds);
	}

	async function handleSuccess() {
		Loading = true;

		console.log({ selectedFrequency });
		console.log($user.Id);
		console.log({ frequencyOptions });
		console.log({ Evolucion__c });
		console.log({ selectedModality });

		if (!selectedFrequency || !$user.Id || !date_Session || !Evolucion__c) {
			// Manejar el caso en que los campos requeridos no tienen valores
			console.error("Campos requeridos no pueden estar vacíos.");
			Loading = false;
			showAlert = true;
			setTimeout(() => {
				showAlert = false;
			}, 4000);
		} else {
			let data = {
				Id: selectedFrequency,
				doctorId: $user.Id,
				dateCompleted: date_Session,
				evolution: Evolucion__c,
				medicalCenter,
				clarification,
				diagnosis,
				specification,
			};
			const response = await professional_sudden_detail_add(data);
			if (response.statusCode === 200) {
				if (document !== "") {
					let object_name_sf = '';
					const promises = document.detail.fileData.map(async (element) => {
						console.log('response solo professional_sudden_detail_add', {response});
						console.log('response.data solo professional_sudden_detail_add', response.data.created);
						element.creator = $user.Id;
						element.attachment = response.data.created;
						let type_to_submit = '';
						console.log('myType en Subit.svelte ', myType);
						if (myType === "E") {
							// E: "ExamsDetailCareplan__c",					
							object_name_sf = "Orden";
							element.file_type = file_type_exams ;

						}	else if (myType === "C") {
							// C: "InterviewDetailCareplan__c",
							type_to_submit = file_type_interv ;
							element.file_type = "evolucion";
						}
						console.log({type_to_submit});
						await utils_files_add(element, "", type_to_submit, object_name_sf);
					});

					// Esperar a que todas las promesas se resuelvan
					await Promise.all(promises);

					// Recargar la página después de cargar todos los archivos
				}
				window.location.reload();
			} else {
				flagError = true;
			}
		}
	}

	onMount(async () => {
		selectedSpecialty = null;
		selectedFrequency = null;
		selectedModality = null;
		myType = myType.charAt(0);
	});
</script>

<div class="container">
	<div class="text-center text-muted">
		<h3>{$_("Subit.text_title_evolution")}</h3>
	</div>
	{#if !Loading}
		<div class="container py-2 border rounded shadow-sm my-3 bg-white">
			<div class="SpecialtySelect">
				<label>{$_("Subit.text_Specialty")}</label><small class="error">*</small>
				<select
					class="selected"
					bind:value={selectedSpecialty}
					on:change={() => {
						clearSelect();
					}}>
					<option disabled selected value="">{$_("Subit.text_SpecialtySelect")}</option>
					{#each specialtyOptions as specialty}
						<option value={specialty.name}>{specialty.name}</option>
					{/each}
				</select>
			</div>
			<div class="comboSelect">
				<label>{$_("Subit.text_Frequency")}</label><small class="error">*</small>
				<select class="selected" bind:value={selectedFrequency}>
					<option disabled selected value="">{$_("Subit.text_FrequencySelect")}</option>
					{#each frequencyOptions as { Id, frecuency }}
						<option value={Id}>{frecuency}</option>
					{/each}
				</select>

				<label>{$_("Subit.text_Modality")}</label><small class="error">*</small>
				<select class="selected" bind:value={selectedModality}>
					<option disabled selected value="">{$_("Subit.text_ModalitySelect")}</option>
					{#each modalityOptions as options}
						<option value={options.modality}>{options.modality}</option>
					{/each}
				</select>
			</div>
		</div>
		<div class="container py-2 border rounded shadow-sm my-3 bg-white">
			<div>
				<label for="fecha-sesion">{$_("ModalComplete.text_th_session_date")}</label><small class="error"
					>*</small>
				<input
					bind:value={date_Session}
					type="date"
					class="form-control form-control-sm"
					on:change={() => {
						isValidDate(date_Session);
					}} />
			</div>
			{#if flagError}
				<div><span class="error-message">{$_("Subit.text_message_error")}</span></div>
			{/if}
			<div class="form-group">
				<label for="evolucion">{$_("ModalComplete.text_title_Evolution")}</label><small class="error">*</small>

				<textarea bind:value={Evolucion__c} class="form-control" id="evolucion" rows="3" maxlength="255" />
			</div>
			<FileAttach
				{idTypeAttachment}
				{file_type}
				multipleState={true}
				object_name={objNameList[myType]}
				photoState={true}
				on:dataFile={async (e) => {
					document = e.detail;
				}} />
		</div>
		{#if myType == "E"}
			<div class="container py-2 border rounded shadow-sm my-3 bg-white">
				<div class="form-group input-label-container">
					<label for="medicalCenter" class="input-label">
						{$_("Subit.text_medicalCenter")}:
					</label>
					<input id="medicalCenter" type="text" class="selected" bind:value={medicalCenter} />
				</div>

				<div class="form-group input-label-container">
					<label for="clarifications" class="input-label">
						{$_("List_Records_prof.text_specification")} :
					</label>
					<textarea bind:value={specification} class="form-control" id="evolucion" rows="3" maxlength="255" />
				</div>

				<div class="form-group input-label-container">
					<label for="aclaraciones" class="input-label">
						{$_("List_Records_prof.text_clarifications")}:
					</label>
					<!-- <input id="aclaraciones" type="text" class="input-field" bind:value={aclaraciones} /> -->
					<textarea bind:value={clarification} class="form-control" id="evolucion" rows="3" maxlength="255" />
				</div>

				<div class="form-group input-label-container">
					<label for="diagnostico" class="input-label">
						{$_("List_Records_prof.text_diagnosis")}:
					</label>
					<!-- <input id="diagnostico" type="text" class="input-field" bind:value={diagnostico} /> -->
					<textarea bind:value={diagnosis} class="form-control" id="evolucion" rows="3" maxlength="255" />
				</div>
			</div>
		{/if}
		{#if showAlert}
			<div class="alert">
				<p>{message}</p>
			</div>
		{/if}

		<div class="text-center">
			<button
				class="btnBlue"
				on:click={() => {
					handleSuccess();
				}}>
				{$_("Subit.btn_acept")}
			</button>
			<button class="btnWhite" on:click={closeSubForm}> {$_("Subit.btn_cancel")} </button>
		</div>
	{:else}
		<div class="text-center p-3">
			<Loader text={false} />
			<p class="text-muted">{$_("RegisterDetailQuestion.loading")}</p>
		</div>
	{/if}
</div>

<style>
	/* Puedes agregar estilos según tus necesidades */
	.error-message {
		color: red;
		font-style: oblique;
		font-size: 11px;
	}
	.error {
		color: red;
	}
	.SpecialtySelect {
		width: 100%;
	}
	.selected {
		width: 100%;
	}
	.alert {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #f8d7da;
		color: #721c24;
		padding: 10px 15px;
		border: 1px solid #f5c6cb;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		z-index: 1000;
		display: flex;
		justify-content: space-between;
	}

	.alert p {
		margin: 0;
	}
</style>
