import { Auth } from "aws-amplify";
import awsconfig from "../../src/aws-exports";
//import jwt_decode from "jwt-decode";
//import jwt from "jsonwebtoken-promisified";

Auth.configure(awsconfig);
const HOST = process.env.HOST_PROD; 
//process.env.HOST_PROD||process.env.HOST_QA || process.env.HOST_QA

// User roles
export const ROLES = {
	patient: "Paciente",
	caregiver: "Familiar_Cuidador",
	professional: "Profesional_de_Salud",
};

// File attachment
// export const FILE_ATTACHMENT = {
// 	Account__c,
// 	Patologia_Detalle__c,
// 	RDCom_ActividadDetail__c,
// };

// File types
export const FILE_TYPE = {
	Matricula: "Matricula",
	Documento: "Documento",
	Firma: "Firma",
	Orden: "Orden",
	profilePicture: "Foto de perfil",
	pathologyCertificate: "Certificado patologia",
	evolucion : "evolucion",
	Orden : "Orden"
};

// Endpoints List
export const ENDPOINT = {
	// Utils
	utils_resources_get: "/utils/resources_get", 
	utils_resources_get_open: "/utils/resources_get_open",
	utils_files_add: "/utils/files_add", 
	utils_files_get: "/utils/files_get", 
	utils_files_update: "/utils/files_update", 

	// Patient
	patient_professional_search: "/patient/professional_search", 
	patient_patient_add: "/patient/patient_add", 
	patient_professional_head_add: "/patient/professional_head_add", 
	patient_patient_update: "/patient/patient_update", 

	// Professional
	professional_professional_add: "/professional/professional_add", 
	professional_patients_add: "/professional/patients_add", 
	professional_patient_accept_reject: "/professional/patient_accept_reject", 
	professional_account_update: "/professional/account_update", 
	professional_patients_pending_get: "/professional/patients_pending_get", 
	professional_patients_get: "/professional/patients_get", 
	professional_exam_detail_add: "/professional/exam_detail_add", 
	professional_interview_add: "/professional/interview_add", 
	professional_exam_add: "/professional/exam_add", 
	professional_interview_detail_add: "/professional/interview_detail_add", 
	professional_sudden_detail_add: "/professional/sudden_detail_add", 
	professional_interview_update: "/professional/interview_update", 
	professional_exam_update: "/professional/exam_update", 
	professional_questionnaire_update: "/professional/questionnaire_update", 
	professional_activities_master_get: "/professional/activities_master_get", 
	professional_medications_pdf_add: "/generic/medication_add",  //Falta refactorizar back [02.05.24]
	professional_careplan_add: "/professional/careplan_add", 
	professional_exam_pdf_add: "/professional/exam_pdf_add", 

	// Generic
	generic_account_get: "/generic/account_get", 
	generic_account_check: "/generic/account_check", 
	generic_account_email_check: "/generic/account_email_check", 
	generic_account_status_update: "/generic/account_status_update", 
	generic_clinical_history_get: "/generic/clinical_history_get", 
	generic_activities_get: "/generic/activities_get", 
	generic_interview_detail_update: "/generic/interview_detail_update", 
	generic_interview_detail_complete: "/generic/interview_detail_complete", 
	generic_interview_detail_notes_add: "/generic/interview_detail_notes_add", 
	generic_interview_update: "/generic/interview_update", 
	generic_interview_detail_get: "/generic/interview_detail_get", 
	generic_exam_detail_update: "/generic/exam_detail_update", 
	generic_exam_detail_complete: "/generic/exam_detail_complete", 
	generic_exam_detail_get: "/generic/exam_detail_get", 
	generic_questionnaire_detail_get: "/generic/questionnaire_detail_get", 
	generic_questionnaire_detail_update: "/generic/questionnaire_detail_update", 
	generic_questionnaire_detail_add: "/generic/questionnaire_detail_add", 
	generic_medication_add: "/generic/medication_add", 
	generic_medication_detail_update: "/generic/medication_detail_update", 
	generic_medication_detail_add: "/generic/medication_detail_add", 
	generic_medication_delete: "/generic/medication_delete", 
	generic_medication_get: "/generic/medication_get", 
	generic_medication_detail_get: "/generic/medication_detail_get", 
	generic_questionnaire_results_get: "/generic/questionnaire_results_get", //Falta refactorizar back [02.05.24]
	generic_questionnaire_details_update_postcomplete: "/generic/questionnaire_details_update_postcomplete" 

};

// Config values
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
// API Helpers

/**
 * @description
 * [WORKING]
 */
const generateAuthorizationHeader = async () => {
	const SESSION = await Auth.currentSession();
	const TOKEN = SESSION.getAccessToken().getJwtToken();
	if (TOKEN.length > 0) {
		let authorizationHeader = {
			Authorization: SESSION.getAccessToken().getJwtToken(),
		};
	
		return authorizationHeader;
	} else {
		
		throw new Error("No token found");
	}
};

const generateToken = (user) => {
	// Datos a incluir en el payload del token
	const payload = {
		sub: user,
		iat: Math.floor(Date.now() / 1000), // Obtiene la fecha y hora actual en formato de timestamp Unix
	};
	console.log({ payload });
	// Encabezado del token
	const header = {
		alg: "HS256",
		typ: "JWT",
	};
	console.log({ header });
	// Clave secreta para firmar el token
	const secretKey = "4DM1N-%#*&0732";
	console.log({ secretKey });
	// Generar el token JWT utilizando la biblioteca 'jsonwebtoken'
	const token = jwt.sign(payload, secretKey, { header });
	console.log({ token });
	return token;
};

export const utils_resources_get_open = async (object, user, lang) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.utils_resources_get_open}?object_name=${object}&lang=${lang}`;
	let token = generateToken(user);
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: { Authorization: token },
		})
			.then((response) => {
				console.log({ response });
				if (response.status !== 201) {
					return [];
				} else {
					return response.json();
				}
			})
			.then((data) => {
				return { data };
			});
	} catch (error) {
		return error;
	}
};

/**
 * @description
 * [GET]
 * [WORKING]
 * - This function is totally working for get requests and lists
 * - Expect from API list
 * @return list
 */
const getService = async (url) => {
	console.time("getService");
	return await fetch(url, {
		headers: await generateAuthorizationHeader(),
	})
		.then((data) => {
			return data.json();
		})
		.then((results) => {
			if (results.data) {
				console.timeEnd("getService");
				return results.data;
			}
		})
		.catch((err) => {
			console.timeEnd("getService");
			throw new Error(err);
		});
};

/**
 * @description
 * @return
 */
const postServiceReturnJson = async (url, body = {}) => {
	console.time("postServiceReturnJson");
	return await fetch(url, {
		headers: await generateAuthorizationHeader(),
		method: "POST",
		body: JSON.stringify(body),
	})
		.then((response) => {
			console.timeEnd("postServiceReturnJson");
			return response.json();
		})
		.catch((err) => {
			console.timeEnd("postServiceReturnJson");
			return err;
		});
};

/**
 * @description
 * @return
 */
const postServiceReturnData = async (url, body = {}) => {
	console.time("postServiceReturnData");
	return await fetch(url, {
		headers: await generateAuthorizationHeader(),
		method: "POST",
		body: JSON.stringify(body),
	})
		.then((response) => {
			console.log({ response });
			return response.json();
		})
		.then((data) => {
			console.timeEnd("postServiceReturnData");
			return data;
		})
		.catch((err) => {
			console.timeEnd("postServiceReturnData");
			return err;
		});
};

// API Helpers
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
// API Methods

/**
 * [TEST]
 */
export const saveCaregiver = async (credentials, dataUser) => {
	console.time("saveCaregiver");
	console.log({ credentials });
	console.log({ dataUser });

	let username = dataUser["attributes"].email;
	let rol = dataUser.attributes["profile"];
	let body = {
		asr: {
			account: {
				FirstName: credentials.FirstName,
				LastName: credentials.LastName,
				PersonBirthdate: credentials.PersonBirthdate,
				HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
				PersonEmail: username,
				DNI__c: credentials.DNI__c,
				billingState: credentials.BillingState.label,
				Phone: credentials.Phone,
				PersonMobilePhone: credentials.PersonMobilePhone,
				Cobertura_Salud__c: credentials.Cobertura_Salud__c,
				Nro_de_afiliado__c: credentials.Nro_de_afiliado__c,
				State__c: credentials.BillingState.id,
				R1_streetName__c: credentials.R1_streetName__c,
				R1_streetNumber__c: credentials.R1_streetNumber__c ? credentials.R1_streetNumber__c : "",
				R1_streetDetail__c: credentials.R1_streetDetail__c ? credentials.R1_streetDetail__c : "",
				CognitoSalesforce__c: true,
				Country__c: credentials.BillingCountry.id,
			},
			role: rol,
		},
	};
	console.log({ body });
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.signinCaregiver}`;

	// NEW
	console.timeEnd("saveCaregiver");
	return await postServiceReturnData(MIDDLEWARE_SERVICE, body);

	// try {
	// 	return await fetch(MIDDLEWARE_SERVICE, {
	// 		method: "POST",
	// 		headers: await generateAuthorizationHeader(),
	// 		body: JSON.stringify(body),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			return data;
	// 		});
	// } catch (error) {
	// 	return error;
	// }
};

/**
 *
 */
export const professional_professional_add = async (credentials, dataUser) => {
	console.time("professional_professional_add");
	let Obra_sociales = "Particular";
	if (credentials.coberturas) {
		credentials.coberturas.forEach((element) => {
			if (Obra_sociales == "") {
				Obra_sociales = element;
			} else {
				Obra_sociales += ";" + element;
			}
		});
	}

	//let esp = {};
	let especialities = [];
	let diseasesData = {};
	let fileArray = [];
	console.log({ credentials });
	credentials.especiality.forEach((element) => {
		especialities.push({ code: element });
	});
	diseasesData.code = credentials.patologia;
	console.log({ diseasesData });
	if (credentials.fileAttFotoPerfil) {
		fileArray.push(credentials.fileAttFotoPerfil);
	}
	if (credentials.fileAttFirma) {
		fileArray.push(credentials.fileAttFirma);
	}
	if (credentials.fileAttMatricula) {
		fileArray.push(credentials.fileAttMatricula);
	}
	console.log({ credentials });

	return new Promise(async function (resolve, reject) {
		let username = dataUser["attributes"].email;
		let rol = dataUser.attributes["profile"];
		if (rol == "Profesional_de_Salud") {
			rol = "Professional";
		}
		let body = {
			asr: {
				account: {
					FirstName: credentials.FirstName,
					LastName: credentials.LastName,
					PersonBirthdate: credentials.PersonBirthdate,
					HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
					PersonEmail: username,
					DNI__c: credentials.DNI__c,
					Numero_de_Matricula__c: credentials.Matricula__c,
					Numero_de_Matricula_provincial__c: credentials.Matricula_provincial__c,
					Phone: credentials.Phone,
					PersonMobilePhone: credentials.PersonMobilePhone,
					PersonTitle: credentials.PersonTitle,
					Universidad__c: credentials.Universidad__c,
					Postgrado__c: credentials.Postgrado__c,
					Nombre_de_consultorio__c: credentials.Nombre_de_consultorio__c,
					Celular_consultorio__c: credentials.Celular_consultorio__c,
					Telefono_consultorio__c: credentials.Telefono_consultorio__c,
					// Direccion_consultorio__c: credentials.Direccion_consultorio__c,
					R1_consultorioStreetName__c: credentials.R1_consultorioStreetName__c,
					R1_consultorioStreetNumber__c: credentials.R1_consultorioStreetNumber__c,
					R1_consultorioStreetDetail__c: credentials.R1_consultorioStreetDetail__c,
					Cobertura_Salud__c: Obra_sociales,
					Referente_en_alguna_patologia__c: credentials.refPatologia,
					Cuantos_pacientes_atendio__c: credentials.cantPaciente || "0",
					Anos_de_experiencia__c: credentials.cantExperiencia || 2,
					Realiza_investigacion__c: credentials.Realiza_investigacion
						? credentials.Realiza_investigacion
						: "No",
					Ha_realizado_publicaciones_medicas__c: credentials.realizado_publicaciones
						? credentials.realizado_publicaciones
						: "No",
					Link_de_publicaciones__c: credentials.Link_de_publicaciones || "",
					Link_de_Linkedin__c: credentials.Linkedin || "",
					BillingStreet: credentials.BillingStreet,
					BillingState: credentials.BillingState.label,
					State__c: credentials.BillingState.id,
					Country__c: credentials.BillingCountry.id,
					BillingCountry: credentials.BillingCountry.label,
					R1_streetName__c: credentials.R1_streetName__c,
					R1_streetNumber__c: credentials.R1_streetNumber__c ? credentials.R1_streetNumber__c : "",
					R1_streetDetail__c: credentials.R1_streetDetail__c ? credentials.R1_streetDetail__c : "",
					R1_consultorioStreetName__c: credentials.R1_consultorioStreetName__c,
					R1_consultorioStreetNumber__c: credentials.R1_consultorioStreetNumber__c
						? credentials.R1_consultorioStreetNumber__c
						: "",
					R1_consultorioStreetDetail__c: credentials.R1_consultorioStreetDetail__c
						? credentials.R1_consultorioStreetDetail__c
						: "",
					Medico_de_cabecera_referente__c: credentials.medicoCabecera || false,
				},
				files: [],
				languages: credentials.listLanguage,
				specializations: especialities,
				diseases: [diseasesData],
				role: rol,
			},
		};
		const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_professional_add}`;
		try {
			return fetch(MIDDLEWARE_SERVICE, {
				method: "POST",
				headers: await generateAuthorizationHeader(),
				body: JSON.stringify(body),
			})
				.then((response) => response.json())
				.then((data) => {
					console.timeEnd("professional_professional_add");
					resolve(data);
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * [PROMISE]
 * [POST]
 */
export const patient_patient_add = (credentials, dataUser) => {
	return new Promise(async function (resolve, reject) {
		//let token = dataUser["signInUserSession"].accessToken.jwtToken;
		let username = dataUser["attributes"].email;
		let rol = dataUser.attributes["profile"];
		console.log({ credentials });
		let body = {
			asr: {
				account: {
					FirstName: credentials.FirstName,
					LastName: credentials.LastName,
					PersonEmail: username,
					PersonBirthdate: credentials.PersonBirthdate,
					HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
					DNI__c: credentials.DNI__c,
					BillingState: credentials.BillingState.label,
					State__c: credentials.BillingState.id,
					R1_streetName__c: credentials.R1_streetName__c,
					R1_streetNumber__c: credentials.R1_streetNumber__c ? credentials.R1_streetNumber__c : "",
					R1_streetDetail__c: credentials.R1_streetDetail__c ? credentials.R1_streetDetail__c : "",
					Phone: credentials.Phone || "",
					PersonMobilePhone: credentials.PersonMobilePhone,
					Cobertura_Salud__c: credentials.Cobertura_Salud__c,
					Nro_de_afiliado__c: credentials.Nro_de_afiliado__c || "",
					Esta_usted_diagnosticado_con_una_EPOF__c: credentials.epofChange,
					CognitoSalesforce__c: true,
					BillingCountry: credentials.BillingCountry.label,
					Country__c: credentials.BillingCountry.id,
				},
				diseases: credentials.diseases,
				role: "Patient",
			},
		};
		const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.patient_patient_add}`;
		try {
			return fetch(MIDDLEWARE_SERVICE, {
				method: "POST",
				headers: await generateAuthorizationHeader(),
				body: JSON.stringify(body),
			})
				.then((response) => response.json())
				.then((data) => {
					resolve(data);
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * [PROMISE]
 * [POST]
 */
export const professional_careplan_add = (data, user) => {
	console.log("professional_careplan_add API");
	return new Promise(function (resolve, reject) {
		let token = user["signInUserSession"].accessToken.jwtToken;
		let body = {
			cpsr: {
				plan: data.plan,
				doctor: data.doctor,
				patient: data.patient,
			},
		};
		const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_careplan_add}`;
		try {
			return fetch(MIDDLEWARE_SERVICE, {
				method: "POST",
				headers: {
					Authorization: token,
				},
				body: JSON.stringify(body),
			})
				.then((response) => response.json())
				.then((data) => {
					resolve(data);
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * [PROMISE]
 * [POST]
 */
// export const unAssignPlan = (patientId) => {
// 	return new Promise(async function (resolve, reject) {
// 		let body = {
// 			dr: {
// 				patientId: patientId,
// 				medicoSugerido: "Conan Bondar",
// 			},
// 		};
// 		const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.doctor_generalPracticionerRejectsPatient}`;
// 		try {
// 			return fetch(MIDDLEWARE_SERVICE, {
// 				method: "POST",
// 				headers: await generateAuthorizationHeader(),
// 				body: JSON.stringify(body),
// 			})
// 				.then((response) => response.json())
// 				.then((data) => {
// 					resolve(data);
// 				});
// 		} catch (error) {
// 			reject(error);
// 		}
// 	});
// };

/**
 * [PATCH]
 */
export const requestDoctor = async (data, dataUser) => {
	let body = {
		Respuesta_profesional_asignado__c: "Pendiente",
		Profesional_Asignado__c: data.idDoctor,
		Fecha_Sugerida__c: data.suggestedDateTime,
	};
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_updateActivityDetail}?activity_id=${data.idActivity}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(body),
		}).then((response) => {
			if (response.status !== 204) {
				return { status: response.status, message: "No se pudo solicitar" };
			} else {
				return { status: response.status, message: "Cargado correctamente" };
			}
		});
	} catch (error) {
		return error;
	}
};

/**
 * POST
 */
export const patient_professional_head_add = async (doctorId, patientId) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.patient_professional_head_add}`;
	let myBody = {
		pr: {
			doctorId,
			patientId,
		},
	};
	let statusCode;
	return await fetch(MIDDLEWARE_SERVICE, {
		method: "POST",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify(myBody),
	})
		.then((data) => {
			return data.json();
		})
		.then((data) => {
			console.log("patient_professional_head_add");
			console.log("patient_professional_head_add.:", data);
			//status = data.statusCode;
			return { statusCode: data.statusCode, message: data.message };
		})
		.catch((error) => {
			console.log({ error });
		});
};

/**
 * [PATCH]
 */
// export const patient_update = async (credentials, rol) => {
// 	console.log("patient_update entry", credentials);

// 	console.log("Llega");
// 	let body = {
// 		asr: {
// 			account: {
// 				FirstName: credentials.FirstName,
// 				LastName: credentials.LastName,
// 				PersonEmail: credentials.PersonEmail,
// 				PersonBirthdate: credentials.PersonBirthdate,
// 				HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
// 				DNI__c: credentials.DNI__c,
// 				BillingStreet: credentials.BillingStreet,
// 				BillingState: credentials.BillingState.label,
// 				State__c: credentials.BillingState.id,
// 				BillingCountry: credentials.BillingCountry.label,
// 				Country__c: credentials.BillingCountry.id,
// 				R1_streetName__c: credentials.R1_streetName__c,
// 				R1_streetNumber__c: credentials.R1_streetNumber__c ? credentials.R1_streetNumber__c : "",
// 				R1_streetDetail__c: credentials.R1_streetDetail__c ? credentials.R1_streetDetail__c : "",
// 				Phone: credentials.Phone || "",
// 				PersonMobilePhone: credentials.PersonMobilePhone,
// 				Cobertura_Salud__c: credentials.Cobertura_Salud__c,
// 				Nro_de_afiliado__c: credentials.Nro_de_afiliado__c || "",
// 				Esta_usted_diagnosticado_con_una_EPOF__c: credentials.epofChange,
// 				Id: credentials.Id,
// 			},
// 			diseases: credentials.Patolog_as2__r.records,
// 			role: rol,
// 		},
// 	};
// 	console.log({ body });
// 	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.patient_update}`;
// 	return await fetch(MIDDLEWARE_SERVICE, {
// 		method: "PATCH",
// 		headers: await generateAuthorizationHeader(),
// 		body: JSON.stringify(body),
// 	})
// 		.then((response) => response.json())
// 		.then((data) => {
// 			console.log({ data });
// 			return data;
// 			//resolve(data);
// 		})
// 		.catch((error) => {
// 			console.log({ error });
// 		});
// };
export const patient_patient_update = async (credentials, rol) => {
	// Validar datos
	if (
		!credentials ||
		!rol ||
		!credentials.FirstName ||
		!credentials.LastName ||
		!credentials.PersonEmail ||
		!credentials.PersonBirthdate ||
		!credentials.HealthCloudGA__Gender__pc ||
		!credentials.DNI__c ||
		!credentials.BillingStreet ||
		!credentials.BillingState ||
		!credentials.BillingCountry ||
		!credentials.R1_streetName__c ||
		!credentials.PersonMobilePhone ||
		!credentials.Cobertura_Salud__c ||
		!credentials.Patolog_as2__r.records ||
		!credentials.Id
	) {
		console.error("Datos incompletos o incorrectos");
		return;
	}

	const body = {
		asr: {
			account: {
				FirstName: credentials.FirstName,
				LastName: credentials.LastName,
				PersonEmail: credentials.PersonEmail,
				PersonBirthdate: credentials.PersonBirthdate,
				HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
				DNI__c: credentials.DNI__c,
				BillingStreet: credentials.BillingStreet,
				BillingState: credentials.BillingState.label,
				State__c: credentials.BillingState.id,
				BillingCountry: credentials.BillingCountry.label,
				Country__c: credentials.BillingCountry.id,
				R1_streetName__c: credentials.R1_streetName__c,
				R1_streetNumber__c: credentials.R1_streetNumber__c || "",
				R1_streetDetail__c: credentials.R1_streetDetail__c || "",
				Phone: credentials.Phone || "",
				PersonMobilePhone: credentials.PersonMobilePhone,
				Cobertura_Salud__c: credentials.Cobertura_Salud__c,
				Nro_de_afiliado__c: credentials.Nro_de_afiliado__c || "",
				Esta_usted_diagnosticado_con_una_EPOF__c: credentials.epofChange,
				Id: credentials.Id,
			},
			// diseases: credentials.Patolog_as2__r.records,
			role: rol,
		},
	};

	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.patient_patient_update}`;

	try {
		const response = await fetch(MIDDLEWARE_SERVICE, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Error en la solicitud de actualización del paciente");
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error en la función patient_update:", error);
		throw error;
	}
};

/**
 * [PATCH]
 */
export const professional_account_update = async (credentials, rol) => {
	console.log("doctor_update entry", credentials);
	// let BillingState = credentials.BillingState;
	// let State__c = credentials.State__c;
	// let BillingCountry = credentials.BillingCountry;
	// let Country__c = credentials.Country__c;
	// if (credentials.BillingState.label) {
	// 	BillingState = credentials.BillingState.label;
	// 	State__c = credentials.BillingState.id;
	// }
	// if (credentials.country.label) {
	// 	BillingCountry = credentials.country.label;
	// 	Country__c = credentials.country.id;
	// }
	let body = {
		asr: {
			account: {
				FirstName: credentials.FirstName,
				LastName: credentials.LastName,
				PersonBirthdate: credentials.PersonBirthdate,
				HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
				PersonEmail: credentials.PersonEmail,
				DNI__c: credentials.DNI__c,
				Id: credentials.Id,
				Phone: credentials.Phone,
				PersonMobilePhone: credentials.PersonMobilePhone,
				R1_streetName__c: credentials.R1_streetName__c,
				R1_streetNumber__c: credentials.R1_streetNumber__c ? credentials.R1_streetNumber__c : "",
				R1_streetDetail__c: credentials.R1_streetDetail__c ? credentials.R1_streetDetail__c : "",
				BillingStreet: credentials.BillingStreet,
				BillingState: credentials.BillingState.label,
				State__c: credentials.BillingState.Id,
				BillingCountry: credentials.BillingCountry.label,
				Country__c: credentials.BillingCountry.Id,
			},
			languages: credentials.structlistLanguage,
			role: rol,
		},
	};
	console.log({ body });
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_account_update}`;
	return await fetch(MIDDLEWARE_SERVICE, {
		method: "PATCH",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify(body),
	})
		.then((response) => response.json())
		.then((data) => {
			console.log({ data });
			return data;
			//resolve(data);
		})
		.catch((error) => {
			console.log({ error });
		});
};

/**
 *
 */
export const patient_search_doctor = async (myLimit, myOffset, myEspecialization, myBillingState, myName, cabecera) => {
	if (myEspecialization == "*") {
		myEspecialization = "";
	}
	let status = "";
	let searchedDoctors = [];
	let myHeaders = await generateAuthorizationHeader();
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.patient_patientSearchDoctor}?Limit=${myLimit}&Offset=${myOffset}&Specialization=${myEspecialization}&BillingState=${myBillingState}&Name=${myName}&cabecera=${cabecera}`;
	return await fetch(MIDDLEWARE_SERVICE, { headers: myHeaders })
		.then((data) => {
			return data.json();
		})
		.then((data) => {
			status = data.statusCode;
			if (status == 200) {
				if (data.data.length > 0) {
					searchedDoctors = data.data;
				}
			}
			return { status, searchedDoctors };
		})
		.catch((error) => {
			console.log({ error });
		});
};

export const patient_professional_search = async (data) => {
	let status = "";
	let url = `${HOST}${ENDPOINT.patient_professional_search}`;
	return await fetch(url, {
		method: "PATCH",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify({ data }),
	})
		.then((response) => response.json())
		.then((data) => {
			return data;
		})
		.catch((patchError) => {
			console.log({ patchError });
		});
};

// Patient
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
// Generic

/**
 * GET
 */
export const utils_files_get = async (id) => {
	let status = "";
	console.log({ id });
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.utils_files_get}?Id=${id}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		})
			.then((response) => {
				status = response.status;
				if (status !== 200) {
					return [];
				} else {
					return response.json();
				}
			})
			.then((data) => {
				return { status, data };
			});
	} catch (error) {
		return error;
	}
};

/**
 * [PROMISE]
 * [POST]
 */
export const professional_patients_add = (credentials) => {
	return new Promise(async function (resolve, reject) {
		//console.log({ credentials });
		let body = {
			asr: {
				account: {
					FirstName: credentials.FirstName,
					LastName: credentials.LastName,
					PersonEmail: null,
					PersonBirthdate: credentials.PersonBirthdate,
					HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
					DNI__c: credentials.DNI__c,
					R1_streetName__c: credentials.R1_streetName__c,
					R1_streetNumber__c: credentials.R1_streetNumber__c,
					R1_streetDetail__c: credentials.R1_streetDetail__c,
					BillingState: credentials.BillingState.label,
					State__c: credentials.BillingState.id,
					Country__c: credentials.country.id,
					BillingCountry: credentials.country.label,
					Phone: credentials.Phone,
					PersonMobilePhone: credentials.PersonMobilePhone,
					Cobertura_Salud__c: credentials.Cobertura_Salud__c,
					Nro_de_afiliado__c: credentials.Nro_de_afiliado__c,
					Esta_usted_diagnosticado_con_una_EPOF__c: true,
					RegisteredBy__c: "professional",
					CognitoSalesforce__c: false,
				},
				diseases: credentials.diseases,
				registeredby: [
					{
						doctorId: credentials.doctorId,
						planId: credentials.selectedPlan,
					},
				],
				role: "Patient",
			},
		};
		// {
		// 	asr: {
		// 		account: {
		// 			FirstName: credentials.FirstName,
		// 			LastName: credentials.LastName,
		// 			PersonEmail: username,
		// 			PersonBirthdate: credentials.PersonBirthdate,
		// 			HealthCloudGA__Gender__pc: credentials.HealthCloudGA__Gender__pc,
		// 			DNI__c: credentials.DNI__c,
		// 			BillingState: credentials.BillingState,
		// 			R1_streetName__c: credentials.R1_streetName__c,
		// 			R1_streetNumber__c: credentials.R1_streetNumber__c ? credentials.R1_streetNumber__c : "",
		// 			R1_streetDetail__c: credentials.R1_streetDetail__c ? credentials.R1_streetDetail__c : "",
		// 			Phone: credentials.Phone || "",
		// 			PersonMobilePhone: credentials.PersonMobilePhone,
		// 			Cobertura_Salud__c: credentials.Cobertura_Salud__c,
		// 			Nro_de_afiliado__c: credentials.Nro_de_afiliado__c || "",
		// 			Esta_usted_diagnosticado_con_una_EPOF__c: credentials.epofChange,
		// 		},
		// 		diseases: credentials.diseases,
		// 		registeredby: credentials.registeredby,
		// 		role: rol,
		// 	},
		// };
		const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_patients_add}`;
		try {
			return fetch(MIDDLEWARE_SERVICE, {
				method: "POST",
				headers: await generateAuthorizationHeader(),
				body: JSON.stringify(body),
			})
				.then((response) => response.json())
				.then((data) => {
					resolve(data);
				});
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * [PATCH]
 */
// export const update_activity_detail = async (myBody, registerId) => {
// 	let status = "";
// 	let modalSuccess = false;
// 	let modalError = false;
// 	let url = `${HOST}${ENDPOINT.generic_updateActivityDetail}?activity_id=${registerId}`;
// 	return await fetch(url, {
// 		method: "PATCH",
// 		headers: await generateAuthorizationHeader(),
// 		body: JSON.stringify(myBody),
// 	})
// 		.then((patchResponse) => {
// 			status = patchResponse.status;
// 			return { status };
// 		})
// 		.catch((patchError) => {
// 			console.log({ patchError });
// 		});
// };

/**
 * [PATCH]
 */
export const generic_account_status_update = async (AccountId, statusAccount) => {
	let status = "";
	let myBody = {
		asr: {
			id: AccountId,
			AccountStatus: statusAccount,
		},
	};
	let url = `${HOST}${ENDPOINT.generic_account_status_update}`;
	return await fetch(url, {
		method: "PATCH",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify(myBody),
	})
		.then((patchResponse) => {
			status = patchResponse.status;
			return { status };
		})
		.catch((patchError) => {
			console.log({ patchError });
		});
};

/**
 * [PATCH]
 */
export const generic_interview_detail_update = async (data) => {
	//let status = "";

	let url = `${HOST}${ENDPOINT.generic_interview_detail_update}`;
	let myBody = { data };
	console.log({ myBody });
	return await fetch(url, {
		method: "PATCH",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify(myBody),
	})
		.then((response) => response.json())
		.then((data) => {
			return data;
		})
		.catch((patchError) => {
			console.log({ patchError });
		});
};

/**
 * [PATCH]
 */
export const generic_exam_detail_update = async (data) => {
	let status = "";
	let url = `${HOST}${ENDPOINT.generic_exam_detail_update}`;
	let myBody = { data };
	return await fetch(url, {
		method: "PATCH",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify(myBody),
	})
		.then((patchResponse) => {
			status = patchResponse.status;
			return { status };
		})
		.catch((patchError) => {
			console.log({ patchError });
		});
};

/**
 * [PATCH]
 */

export const professional_questionnaire_update = async (idActivity, data) => {
	console.log("Llamado a update_activity");
	console.log({ idActivity });

	let body = { data };

	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_questionnaire_update}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(body),
		}).then((response) => {
			console.log({ response });
			if (response.status !== 204) {
				return { status: response.status, message: "No se pudo solicitar" };
			} else {
				return { status: response.status, message: "Cargado correctamente" };
			}
		});
	} catch (error) {
		return error;
	}
};

/**
 * [PATCH]
 */
export const questionnaire_details_update_postcomplete = async (data) => {
	let status = "";
	let myBody = { data };
	let url = `${HOST}${ENDPOINT.generic_questionnaire_details_update_postcomplete}`;
	console.time("questionnaire_details_update_postcomplete");
	try {
		const response = await fetch(url, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(myBody),
		});

		if (!response.ok) {
			console.timeEnd("questionnaire_details_update_postcomplete");
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		const responseData = await response.json();
		status = response.status;
		console.timeEnd("questionnaire_details_update_postcomplete");
		return { status, data: responseData };
	} catch (error) {
		console.timeEnd("questionnaire_details_update_postcomplete");
		console.error("Error en la solicitud PATCH:", error);
		throw error;
	}
};

/**
 * [PATCH]
 */
export const generic_interview_detail_complete = async (data) => {
	try {
		const url = `${HOST}${ENDPOINT.generic_interview_detail_complete}`;
		const headers = await generateAuthorizationHeader();
		const myBody = { data };

		const response = await fetch(url, {
			method: "PATCH",
			headers: headers,
			body: JSON.stringify(myBody),
		});

		const status = response.status;
		return { status };
	} catch (error) {
		console.error("Error en generic_interview_detail_complete:", error);
		return { status: "error" };
	}
};

/**
 * [PATCH]
 */
export const generic_interview_detail_notes_add = async (data) => {
	console.time("generic_interview_detail_notes_add");
	console.log({ data });
	try {
		const url = `${HOST}${ENDPOINT.generic_interview_detail_notes_add}`;
		const headers = await generateAuthorizationHeader();
		const myBody = { data };

		const response = await fetch(url, {
			method: "PATCH",
			headers: headers,
			body: JSON.stringify(myBody),
		});

		const status = response.status;
		console.timeEnd("generic_interview_detail_notes_add");
		return { status };
	} catch (error) {
		console.error("Error en generic_interview_detail_notes_add:", error);
		return { status: "error" };
	}
};

/**
 * [PATCH]
 */
export const generic_interview_update = async (data) => {
	let url = `${HOST}${ENDPOINT.generic_interview_update}`;
	let myBody = { data };
	return await fetch(url, {
		method: "PATCH",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify(myBody),
	})
		.then((response) => response.json())
		.then((data) => {
			return data;
		})
		.catch((patchError) => {
			console.log({ patchError });
		});
};

/**
 * [PATCH]
 */
export const generic_interview_detail_get = async (Id, lang) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_interview_detail_get}?Id=${Id}&lang=${lang}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		})
			.then((response) => {
				console.log({ response });
				if (response.status !== 200) {
					return [];
				} else {
					console.log("ApiB - Retorna rta " );
					// let auxResp = response.json();
					let respEndpointAux = response.json();
					console.log('generic_interview_detail_get' , {respEndpointAux} );
					// console.log(auxResp.data);
					return respEndpointAux;
				}
			})
			.then((data) => {
				return { data };
			});
	} catch (error) {
		return error;
	}
};

export const generic_questionnaire_detail_get = async (Id, lang) => {
	console.time("generic_questionnaire_detail_get");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_questionnaire_detail_get}?Id=${Id}&lang=${lang}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		})
			.then((response) => {
				if (response.status !== 200) {
					return [];
				} else {
					return response.json();
				}
			})
			.then((data) => {
				console.log({ data });
				console.timeEnd("generic_questionnaire_detail_get");
				return data;
			});
	} catch (error) {
		console.timeEnd("generic_questionnaire_detail_get");
		return error;
	}
};

/**
 * [PATCH]
 */
export const generic_exam_detail_complete = async (data) => {
	let status = "";
	let url = `${HOST}${ENDPOINT.generic_exam_detail_complete}`;
	let myBody = { data };
	return await fetch(url, {
		method: "PATCH",
		headers: await generateAuthorizationHeader(),
		body: JSON.stringify(myBody),
	})
		.then((patchResponse) => {
			status = patchResponse.status;
			return { status };
		})
		.catch((patchError) => {
			console.log({ patchError });
		});
};

/**
 * [PATCH]
 */
export const generic_exam_detail_get = async (id, lang) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_exam_detail_get}?Id=${id}&lang=${lang}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		})
			.then((response) => {
				console.log({ response });
				if (response.status !== 200) {
					return [];
				} else {
					return response.json();
				}
			})
			.then((data) => {
				return { data };
			});
	} catch (error) {
		return error;
	}
};

/**
 * [PATCH]
 */
// export const update_activity = async (idActivity, data) => {
// 	console.log("Llamado a update_activity");
// 	console.log({ idActivity });

// 	let body = { data };

// 	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_updateActivity}?activity_id=${idActivity}`;
// 	try {
// 		return await fetch(MIDDLEWARE_SERVICE, {
// 			method: "PATCH",
// 			headers: await generateAuthorizationHeader(),
// 			body: JSON.stringify(body),
// 		}).then((response) => {
// 			console.log({ response });
// 			if (response.status !== 204) {
// 				return { status: response.status, message: "No se pudo solicitar" };
// 			} else {
// 				return { status: response.status, message: "Cargado correctamente" };
// 			}
// 		});
// 	} catch (error) {
// 		return error;
// 	}
// };

/**
 * [PATCH]
 */
export const professional_exam_update = async (idActivity, data) => {
	console.log("Llamado a update_activity");
	console.log({ idActivity });

	let body = { data };

	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_exam_update}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((data) => {
				return data;
			})
			.catch((patchError) => {
				console.log({ patchError });
		});
	} catch (error) {
		return error;
	}
};

/**
 * [PATCH]
 */
export const professional_interview_update = async (data) => {
	console.log("Llamado a update_activity");
	//console.log({ idActivity });

	let body = { data };

	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_interview_update}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(body),
		})
			.then((response) => response.json())
			.then((data) => {
				return data;
			})
			.catch((patchError) => {
				console.log({ patchError });
			});
	} catch (error) {
		return error;
	}
};

/**
 * [PATCH]
 */
export const professional_patient_accept_reject = (myBody) => {
	return new Promise(async function (resolve, reject) {
		console.log("professional_patient_accept_reject");
		console.log({ myBody });

		let url = `${HOST}${ENDPOINT.professional_patient_accept_reject}`;
		return await fetch(url, {
			method: "POST",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(myBody),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				resolve(data);
			})
			.catch((patchError) => {
				console.log({ patchError });
				reject(patchError);
			});
	});
};

/**
 *
 */
// export const get_activities_by_type = async (myPatient, myTypoActividad, myPlan, lang = "en") => {
// 	console.log({ myPatient });
// 	console.log({ myTypoActividad });
// 	console.log({ myPlan });
// 	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_getActivitiesByType}?paciente_id=${myPatient}&tipo_actividad=${myTypoActividad}&plan_id=${myPlan}&lang=${lang}`;
// 	console.log({ MIDDLEWARE_SERVICE });
// 	return await fetch(MIDDLEWARE_SERVICE, { headers: await generateAuthorizationHeader() })
// 		.then((data) => {
// 			console.log("getActivityByType - data", data);
// 			if (data.status !== 200) {
// 				return [];
// 			} else {
// 				return data.json();
// 			}
// 		})
// 		.then((result) => {
// 			console.log("getActivityByType - result", result);
// 			return result;
// 		})
// 		.catch((error) => {
// 			console.log("getActivityByType - error", error);
// 		});
// };

/**
 *
 */
export const generic_activities_get = async (myPatient, myTypoActividad, myPlan, account_id, lang = "en") => {
	console.time("get_activities_by_type");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_activities_get}?paciente_id=${myPatient}&tipo_actividad=${myTypoActividad}&plan_id=${myPlan}&account_id=${account_id}&lang=${lang}`;

	try {
		const response = await fetch(MIDDLEWARE_SERVICE, { headers: await generateAuthorizationHeader() });

		if (!response.ok) {
			throw new Error("Failed to fetch activities");
		}

		const data = await response.json();
		console.log("getActivityByType - data", data);
		console.timeEnd("get_activities_by_type");
		return data;
	} catch (error) {
		console.log("getActivityByType - error", error);
		console.timeEnd("get_activities_by_type");
		return [];
	}
};

/**
 * [TEST] addMedication_head  -  generic_medication_add
 */
export const generic_medication_add = async (data) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_medication_add}`;
	return await postServiceReturnJson(MIDDLEWARE_SERVICE, data);
};

/**
 *[TEST]
 */
export const generic_medication_detail_add = async (data) => {
	let MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_medication_detail_add}`;
	return await postServiceReturnJson(MIDDLEWARE_SERVICE, data);
};

/**
 * TODO: NEW FUNCTION   generic_medication_detail_get
 */
export const generic_medication_detail_get = async (id) => {
	console.log("generic_medication_detail_get");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_medication_detail_get}?medicationDetailId=${id}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		}).then((response) => {
			return response.json();
		});
	} catch (error) {
		return error;
	}
};

/**
 * TODO: NEW FUNCTION
 */
export const generic_medication_get_List = async (data) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_medication_get}?patientId=${data.patientId}&planId=${data.planId}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		}).then((response) => {
			return response.json();
		});
	} catch (error) {
		return error;
	}
};

/**
 * [PATCH]
 */
export const generic_medication_detail_update = async (data) => {
	let url = `${HOST}${ENDPOINT.generic_medication_detail_update}`;
	try {
		return await fetch(url, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(data),
		}).then((response) => {
			console.log("Manejar la respuesta");
			console.log({ response });
			return {
				statusCode: response.status,
				message: "Se modifico con exito",
				error: false,
			};
		});
	} catch (error) {
		return {
			statusCode: 403,
			message: "Este usuario no tiene permisos para editar la actividad detalle",
			error: true,
		};
	}
};

/**
 * [PATCH]
 */
export const generic_medication_delete = async (id) => {
	let url = `${HOST}${ENDPOINT.generic_medication_delete}?id_actividad_cabecera=${id}`;
	try {
		return await fetch(url, {
			method: "PATCH",
			headers: await generateAuthorizationHeader(),
		}).then((response) => {
			return response.json();
		});
	} catch (error) {
		return error;
	}
};

/**
 *
 */
// export const careplan_questionnaire = async (id) => {
// 	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_careplanQuestionnaireGet}?actividad_detalle=${id}`;
// 	try {
// 		return await fetch(MIDDLEWARE_SERVICE, {
// 			method: "GET",
// 			headers: await generateAuthorizationHeader(),
// 		}).then((response) => {
// 			return response.json();
// 		});
// 	} catch (error) {
// 		return error;
// 	}
// };

/**
 *
 */
export const generic_questionnaire_results_get = async (id) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_questionnaire_results_get}?Id=${id}`;
	try {
		const response = await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		});
		if (!response.ok) {
			// Lanzar un nuevo error personalizado con el código de estado
			throw new Error("Error al obtener los resultados");
		}
		const data = await response.json();
		console.log("rta quest", data);

		 // Agrupar respuestas por pregunta
        const groupedResults = data.data.response[0].responseSet.reduce((acc, item) => {
            const { questionText, questionID, answerValues, questionDescription} = item;
            const answers = answerValues.map(av => av.answerText).filter(text => text != null && text !== "");

			//Verifico que no sea grilla
			if (questionDescription == "") {
				// Verificar si la pregunta ya está en el acumulador
				const existingQuestion = acc.find(q => q.questionID === questionID);
				if (existingQuestion) {
					// Añadir respuestas a la pregunta existente
					existingQuestion.answerTexts.push(...answers);
				} else {
					let type = "";
					if (questionText.charAt(0) == ".") {
						type = "inform"
					} else
					{
						type = "rta"
					}
					// Crear una nueva entrada para la pregunta
					acc.push({
						questionText,
						questionID,
						answerTexts: answers,
						questionDescription,
						questionType: type
					});
				}
			} else {

				const existingQuestion = acc.find(q => q.questionDescription === questionDescription);
				if (existingQuestion) {
					// Añadir respuestas a la pregunta existente
					existingQuestion.options.push({
						questionText,
						questionID,
						answerTexts: answers,
						questionDescription,
						questionType: "grid-option"
					});
				} else {
					acc.push({
						questionDescription,
						options: [{
							questionText,
							questionID,
							answerTexts: answers,
							questionDescription,
							questionType: "grid-option"
				
						}],
						questionType: "grid"
					});
				}

			}
            
            return acc;
        }, []);

        return { statusCode: response.status, data: groupedResults };
    } catch (error) {
        return { statusCode: 500, error: error.message };
    }
};

/**
 * [200]
 */
// export const careplan_questionnaire_complete = async (data) => {
// 	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_careplanQuestionnaireComplete}`;
// 	return await postServiceReturnJson(MIDDLEWARE_SERVICE, data);
// };

/**
 * [TEST]
 */
export const generic_questionnaire_detail_add = async (data) => {
	//const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_careplanQuestionnaireCreateDetail}?actividad_cabecera_id=${actividad_cabecera_id}&creado_por=${creado_por}&paciente_id=${paciente_id}`;
	console.time("generic_questionnaire_detail_add");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_questionnaire_detail_add}`;
	console.timeEnd("generic_questionnaire_detail_add");
	return await postServiceReturnJson(MIDDLEWARE_SERVICE, { data });
};
/**
 * [PATCH]
 */
export const generic_questionnaire_detail_update = (Body) => {
	return new Promise(async function (resolve, reject) {
		console.time("generic_questionnaire_detail_update");
		console.log({ Body });

		let url = `${HOST}${ENDPOINT.generic_questionnaire_detail_update}`;
		console.log({ url });
		return await fetch(url, {
			method: "POST",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(Body),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.timeEnd("generic_questionnaire_detail_update");
				resolve(data);
			})
			.catch((patchError) => {
				console.log({ patchError });
				reject(patchError);
			});
	});
};

/**
 *
 */
export const generic_clinical_history_get = async (
	paciente_id,
	fecha_desde,
	fecha_hasta,
	tipo_actividad,
	sub_categoria,
	profesional,
	lang
) => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_clinical_history_get}?paciente_id=${paciente_id}&fecha_desde=${fecha_desde}&fecha_hasta=${fecha_hasta}&tipo_actividad=${tipo_actividad}&sub_categoria=${sub_categoria}&profesional=${profesional}&lang=${lang}`;
	let info;
	try {
		await fetch(MIDDLEWARE_SERVICE, { headers: await generateAuthorizationHeader() })
			.then((resp) => resp.json())
			.then((data) => (info = data));
		return info;
	} catch (error) {
		console.log({ error });
	}
	/* await fetch(MIDDLEWARE_SERVICE, { headers:myHeaders })
      .then(data=>{return data.json()})
      .then(response =>{
          console.log('Response Historia Clinica', response)
          if(response.statusCode == 404 || response.error == true){
              myHistoryClinicList = []
              errorMessage = response.message
          }else{
              myHistoryClinicList = response.data
              console.log({myHistoryClinicList})
          }})
      .catch(getClinicHistoryError=>{console.log({getClinicHistoryError})})
      .finally(() => {loading=false}) */
};

// Generic
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
// Professional

/**
 * GET
 */
export const professional_patients_pending_get = async (id, lang = "en") => {
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_patients_pending_get}?lang=${lang}&Id=${id}`;
	return await fetch(MIDDLEWARE_SERVICE, { headers: await generateAuthorizationHeader() })
		.then((data) => {
			return data.json();
		})
		.then((resolve) => {
			//console.log(resolve);
			return resolve.data;
		})
		.catch(async (error) => {
			console.log("ERROR professional_patients_pending_get");
		});
};

/**
 * GET
 */
// export const get_pending_sessions = async (id) => {
// 	try {
// 		let listOfSessions = [];
// 		let status = "";
// 		let myPendingSessionsUrl = `${HOST}${ENDPOINT.doctor_getPendingSessions}?Id=${id}`;
// 		return await fetch(myPendingSessionsUrl, {
// 			headers: await generateAuthorizationHeader(),
// 		})
// 			.then((data) => {
// 				status = data.status;
// 				if (data.status !== 200) {
// 					return [];
// 				} else {
// 					return data.json();
// 				}
// 			})
// 			.then((response) => {
// 				if (response.data) {
// 					listOfSessions = response.data;
// 				}
// 				return { status, listOfSessions };
// 			})
// 			.catch((error) => {
// 				console.log({ error });
// 			});
// 	} catch (errorcat) {
// 		console.log({ errorcat });
// 	}
// };

/**
 *
 */
export const professional_patients_get = async (Id, lang = "en") => {
	console.time("professional_patients_get");
	try {
		let status = "";
		return await fetch(`${HOST}${ENDPOINT.professional_patients_get}?lang=${lang}&Id=${Id}`, {
			headers: await generateAuthorizationHeader(),
		})
			.then((data) => {
				return data.json();
			})
			.then((response) => {
				console.timeEnd("professional_patients_get");
				return response;
			})
			.catch((err) => {
				console.log({ err });
			});
	} catch (error) {
		console.timeEnd("professional_patients_get");
		console.log("Fetch de professional_patients_get :", error);
	}

	// testear nueva version:
	// export const professional_patients_get = async (Id, lang = "en") => {
	// 	try {
	// 	  const response = await fetch(`${HOST}${ENDPOINT.professional_patients_get}?lang=${lang}&Id=${Id}`, {
	// 		headers: await generateAuthorizationHeader(),
	// 	  });
	// 	  return response.json();
	// 	} catch (error) {
	// 	  console.log("Error en professional_patients_get:", error);
	// 	}
	//   };
	  
};

/** */
// export const get_current_sessions = async (id) => {
// 	console.time("get_current_sessions");
// 	let status = "";
// 	let listOfSessions = [];
// 	try {
// 		let mySessionsUrl = `${HOST}${ENDPOINT.doctor_getCurrentSessions}?Id=${id}`;
// 		return await fetch(mySessionsUrl, { headers: await generateAuthorizationHeader() })
// 			.then((data) => {
// 				status = data.status;
// 				if (status == 404) {
// 					return [];
// 				} else {
// 					return data.json();
// 				}
// 			})
// 			.then((sessionsResponse) => {
// 				if (sessionsResponse.data) {
// 					listOfSessions = sessionsResponse.data;
// 				}
// 				console.timeEnd("get_current_sessions");
// 				return { status, listOfSessions };
// 			})
// 			.catch((error) => {
// 				console.timeEnd("get_current_sessions");
// 				console.log({ error });
// 			});
// 	} catch (errorGetSessions) {
// 		console.log({ errorGetSessions });
// 	}
// };

/**
 *
 */
// export const get_activities_by_specialist = async (userId, patientId) => {
// 	console.time("get_activities_by_specialist");
// 	let status = "";
// 	let myActivitys = [];
// 	let url = `${HOST}${ENDPOINT.doctor_getActivityByEspecialist}?Id=${userId}&paciente_id=${patientId}`;
// 	return await fetch(url, { headers: await generateAuthorizationHeader() })
// 		.then((data) => {
// 			status = data.status;
// 			if (status == 404) {
// 				return [];
// 			} else {
// 				return data.json();
// 			}
// 		})
// 		.then((activityResponse) => {
// 			if (Array.isArray(activityResponse)) {
// 				myActivitys = activityResponse;
// 			}
// 			console.timeEnd("get_activities_by_specialist");
// 			return { status, myActivitys };
// 		})
// 		.catch((activityError) => {
// 			console.timeEnd("get_activities_by_specialist");
// 			console.log(activityError);
// 		});
// };

/**
 * [TEST]
 */
// export const addInterviewDetail = async (profesionalSugerido, actividadCabeceraId, patientId, doctorId) => {
// 	console.time("addInterviewDetail");
// 	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.doctor_careplanExtraInterviewDetailAdd}`;
// 	let body = {
// 		cpsr: {
// 			activityId: actividadCabeceraId,
// 			patientId: patientId,
// 			doctorId: doctorId,
// 			profesionalSugerido: profesionalSugerido,
// 		},
// 	};

// 	// NEW
// 	return await postServiceReturnData(MIDDLEWARE_SERVICE, body)
// 		.then((response) => {
// 			console.log({ response });
// 			//response.json();
// 			console.timeEnd("addInterviewDetail");
// 			return response;
// 		})
// 		.catch((err) => {
// 			console.timeEnd("addInterviewDetail");
// 			console.log(err);
// 		});
// };

/**
 * [TEST]
 */
export const professional_interview_add = async (data) => {
	console.time("professional_interview_add");
	console.log({ data });
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_interview_add}`;
	let body = { data };

	// NEW
	return await postServiceReturnData(MIDDLEWARE_SERVICE, body)
		.then((response) => {
			console.log({ response });
			//response.json();
			console.timeEnd("professional_interview_add");
			return response;
		})
		.catch((err) => {
			console.timeEnd("professional_interview_add");
			console.log(err);
		});
};

/**
 * [TEST]
 */
// export const interview_detail_careplan_add = async (data) => {
// 	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.doctor_interview_detail_careplan_add}`;
// 	let body = { data };
// 	console.log({ body });
// 	return await postServiceReturnData(MIDDLEWARE_SERVICE, body)
// 		.then((response) => {
// 			console.log({ response });
// 			return response;
// 		})
// 		.catch((err) => {
// 			console.log(err);
// 		});
// };
/**
 * [interview_detail_careplan_add]
 * @param {object} data - Los datos a enviar en la solicitud.
 * @returns {Promise} - Una promesa que se resuelve con la respuesta de la solicitud.
 */
export const professional_interview_detail_add = async (data) => {
	console.time("professional_interview_detail_add");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_interview_detail_add}`;
	const body = { data };

	try {
		console.log({ body });
		const response = await postServiceReturnData(MIDDLEWARE_SERVICE, body);
		console.log({ response });
		console.timeEnd("professional_interview_detail_add");
		return response;
	} catch (err) {
		console.error(err);
		console.timeEnd("professional_interview_detail_add");
		throw err; // Opcional: propagar el error para manejarlo más arriba si es necesario.
	}
};

/**
 * [professional_sudden_detail_add]
 * @param {object} data - Los datos a enviar en la solicitud.
 * @returns {Promise} - Una promesa que se resuelve con la respuesta de la solicitud.
 */
export const professional_sudden_detail_add = async (sattr) => {
	console.time("professional_sudden_detail_add");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_sudden_detail_add}`;
	const body = { sattr };
	try {
		console.log({ body });
		const response = await postServiceReturnData(MIDDLEWARE_SERVICE, body);
		console.log({ response });
		console.timeEnd("professional_sudden_detail_add");
		return response;
	} catch (err) {
		console.timeEnd("professional_sudden_detail_add");
		console.error(err);
		throw err; // Opcional: propagar el error para manejarlo más arriba si es necesario.
	}
};

/**
 * [TEST]
 */
export const professional_exam_add = async (
	CentroMedicoSugerido,
	aclaraciones,
	diagnostico,
	actividadCabeceraId,
	patientId,
	doctorId
) => {
	console.time("addStudyDetail");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_exam_add}`;
	let body = {
		cpsr: {
			activityId: actividadCabeceraId,
			patientId: patientId,
			doctorId: doctorId,
			centroMedicoSugerido: CentroMedicoSugerido,
			aclaraciones: aclaraciones,
			diagnostico: diagnostico,
		},
	};

	// NEW
	console.timeEnd("addStudyDetail");
	return await postServiceReturnData(MIDDLEWARE_SERVICE, body);

	// REPLACE_POST
	// try {
	// 	return await fetch(MIDDLEWARE_SERVICE, {
	// 		method: "POST",
	// 		headers: await generateAuthorizationHeader(),
	// 		body: JSON.stringify(body),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			return data;
	// 		});
	// } catch (error) {
	// 	return error;
	// }
};

/**
 * [TEST]
 */
export const professional_exam_detail_add = async (data) => {
	console.time("professional_exam_detail_add");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_exam_detail_add}`;
	let body = { data };
	console.timeEnd("professional_exam_detail_add");
	return await postServiceReturnData(MIDDLEWARE_SERVICE, body);
};

/**
 * [TEST]
 * @param {*} type
 * @param {*} myBodyCreate
 * @param {*} selectedActivity
 * @returns
 */
export const createNewActivity = async (type, myBodyCreate, selectedActivity) => {
	console.time("createNewActivity");
	console.log({ myBodyCreate });
	if (type == "CitasEntrevistas") {
		type = "interview";
	} else {
		type = "study";
	}
	let myCreateUrl = `${HOST}/doctors/careplan_extra_${type}_activity_add`;
	myBodyCreate.cpsr.activityId = selectedActivity;

	// NEW

	return await postServiceReturnData(myCreateUrl, myBodyCreate)
		.then((data) => {
			console.log({ data });
			return data;
		})
		.then((response) => {
			console.timeEnd("createNewActivity");
			return response;
		});
};

// Professional
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
// Resources

/**
 * @description
 * Get resources
 */
export const utils_resources_get = async (resourceName, lang = "en", id = 0) => {
	let [object_name, field] = ["", ""];

	if (lang != "en" && lang != "es") {
		lang = "en";
	}

	if (resourceName == "caregiverRoles") {
		object_name = "Account";
		field = "Rol_Cuidador__c";
	} else if (resourceName == "pathologies") {
		object_name = "Patologia_Template__c";
		field = "";
	} else if (resourceName == "monitoringPlans") {
		//object_name = "HealthCloudGA__CarePlanTemplate__c";
		object_name = "CareplanTemplate__c";
		field = "";
	} else if (resourceName == "prepaidMedicine") {
		object_name = "Account";
		field = "Cobertura_Salud__c";
	} else if (resourceName == "specialties") {
		object_name = "Especialidad_Template__c";
		field = "";
	} else if (resourceName == "frecuencies") {
		object_name = "Picklist_Frequency__c";
		field = "";
	} else if (resourceName == "languages") {
		object_name = "Picklist_Language__c";
		field = "";
	} else if (resourceName == "country") {
		object_name = "Country__c";
		field = "";
	} else if (resourceName == "state") {
		object_name = "State__c";
		field = "";
	}

	const resources = localStorage.getItem(resourceName);
	
	if (resources !== null) { 
		try { //Hago un try por si el obj no esta cargado y no rompa
			let resourceJson = JSON.parse(resources);
			console.log("LocalStorageResoure", resourceJson);

			return resourceJson;
		} catch (e) {
			return resources;
		}	
	} else {
		// let url = `${HOST}${ENDPOINT.utils_resources_get}?object_name=${object_name}&field=${field}&lang=${lang}&country=${id}`;
		let url = `${HOST}${ENDPOINT.utils_resources_get}?object_name=${object_name}&field=${field}&lang=${lang}&country=`;

		let result = await getService(url);
		
		localStorage.setItem(resourceName, JSON.stringify(result));
		// console.log("LocalStorageResoure URL", JSON.stringify(result));
		return result;
	}
};

/**
 * @description
 * Get resources
 */
export const utils_resources_get_bkp = async (resourceName, lang = "en", id = 0) => {
	console.time("utils_resources_get");
	let [object_name, field] = ["", ""];

	if (resourceName == "caregiverRoles") {
		object_name = "Account";
		field = "Rol_Cuidador__c";
	} else if (resourceName == "pathologies") {
		object_name = "Patologia_Template__c";
		field = "";
	} else if (resourceName == "monitoringPlans") {
		//object_name = "HealthCloudGA__CarePlanTemplate__c";
		object_name = "CareplanTemplate__c";
		field = "";
	} else if (resourceName == "prepaidMedicine") {
		object_name = "Account";
		field = "Cobertura_Salud__c";
	} else if (resourceName == "specialties") {
		object_name = "Especialidad_Template__c";
		field = "";
	} else if (resourceName == "frecuencies") {
		object_name = "Picklist_Frequency__c";
		field = "";
	} else if (resourceName == "languages") {
		object_name = "Picklist_Language__c";
		field = "";
	} else if (resourceName == "country") {
		object_name = "Country__c";
		field = "";
	} else if (resourceName == "state") {
		object_name = "State__c";
		field = "";
	}
	let url = `${HOST}${ENDPOINT.utils_resources_get}?object_name=${object_name}&field=${field}&lang=${lang}&country=${id}`;

	console.timeEnd("utils_resources_get");
	return await getService(url);
};

/**
 * @description
 * Get resources
 */
export const professional_activities_master_get = async (careplanId, type, lang = "en") => {
	console.log({ careplanId });
	console.log({ type });
	console.log({ lang });
	const option = {
		CitasEntrevistas: "c",
		Cuestionarios: "q",
		Estudios: "e",
		Registros: "r",
	};
	let url = `${HOST}${ENDPOINT.professional_activities_master_get}?type=${option[type]}&careplanFilter=${careplanId}&lang=${lang}`;
	console.log({ url });
	return await getService(url);
};

/**
 * @description
 * Get resources Activity Types
 */
export const getActivityTypes = async (idCarePlan, typeActivity) => {
	let object_name = "RDCom_Actividad__c";
	let url = `${HOST}/resources?object_name=${object_name}&field=&tipo_actividad=${typeActivity}&planId=${idCarePlan}`;
	let response = await fetch(url, { headers: await generateAuthorizationHeader() }).then((data) => {
		return data.json();
	});
	return response.data;
};

/**
 * @description
 * Upload file
 * [POST]
 */
export const utils_files_add = async (data, id = "", object_name = "default", object_name_sf = "default") => {
	console.log("utils_files_add called ", object_name);
	console.log('object_name_sf ', object_name_sf);
	let file_type_interv = "evolucion";
	let file_type_exams = "Orden";
	

	console.log({ data });
	console.log('object_name_sf ',{object_name_sf})
	console.log('object_name ',{object_name})


		if (object_name === "ExamsDetailCareplan__c") {
			// E: "ExamsDetailCareplan__c",					
			data.file_type = file_type_exams;
	

		}	else if (object_name === "InterviewDetailCareplan__c") {
			// C: "InterviewDetailCareplan__c",
			data.file_type = file_type_interv;

		}
	console.log(new Error().stack); // Imprime la pila de llamadas
	console.log({ data });
	if (data.hasOwnProperty("context")) {
		if (id !== "") {
			data.attachment = id;
		}
		data.object_name = object_name;
		//data.context.attachment.type = FILE_TYPE[data.file_type];
	}
	let status = "";
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.utils_files_add}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "POST",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(data),
		})
			.then((data) => {
				status = data.status;
				if (status !== 201) {
					return [];
				} else {
					return data.json();
				}
			})
			.then((data) => {
				return data;
			});
	} catch (error) {
		return error;
	}
};

export const utils_files_update = async (data, id = "") => {
	if (data.hasOwnProperty("context")) {
		if (id !== "") {
			data.attachment = id;
		}
		//data.context.attachment.type = FILE_TYPE[data.file_type];
	}
	let status = "";
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.utils_files_update}`;
	try {
		return await fetch(MIDDLEWARE_SERVICE, {
			method: "POST",
			headers: await generateAuthorizationHeader(),
			body: JSON.stringify(data),
		})
			.then((data) => {
				status = data.status;
				if (status !== 201) {
					return [];
				} else {
					return data.json();
				}
			})
			.then((data) => {
				return data;
			});
	} catch (error) {
		return error;
	}
};

// Resources
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
// Get user info

/**
 *
 * @param {String} email
 * Professional
 * @returns
 */
// export const getDoctorInfo = async (email) => {
// 	let url = `${HOST}${ENDPOINT.doctor_getDoctorInfo}?email=${email}`;
// 	let response = await fetch(url, { headers: await generateAuthorizationHeader() })
// 		.then((data) => {
// 			return data.json();
// 		})
// 		.catch((e) => {
// 			console.log(e);
// 		});
// 	if (response.statusCode == 200) {
// 		return response.data[0];
// 	} else {
// 		return {};
// 	}
// };

/**
 *
 * @param {String} dni
 * Professional
 * @returns
 */
// export const utils_files_get = async (dni) => {
// 	console.log({ dni });
// 	let url = `${HOST}${ENDPOINT.utils_files_get}?param=dni&value=${dni}&fileType=Firma`;
// 	let response = await fetch(url, { headers: await generateAuthorizationHeader() })
// 		.then((data) => {
// 			console.log({ data });
// 			return data.json();
// 		})
// 		.catch((e) => {
// 			console.log(e);
// 		});
// 	if (response.statusCode == 200) {
// 		return response.data[0];
// 	} else {
// 		return {};
// 	}
// };

/**
 *
 * @param {String} dni
 * Professional
 * @returns
 */
export const generic_account_check = async (dni) => {
	console.log("INGRESO generic_account_check");
	console.log({ dni });
	const partes = dni.split("-");
	const parteNumerica = partes[1] ? partes[1] : dni;
	console.log({ parteNumerica });
	let url = `${HOST}${ENDPOINT.generic_account_check}?param=dni&value=${parteNumerica}`;
	let response = await fetch(url, { headers: await generateAuthorizationHeader() })
		.then((data) => {
			console.log({ data });
			return data.json();
		})
		.catch((e) => {
			console.log(e);
		});
	if (response.statusCode == 200) {
		console.log(response.data);
		return response.data;
	} else {
		return {};
	}
};

/**
 *
 * @param {String} dni
 * Professional
 * @returns
 */
export const generic_account_email_check = async (dni, email, rol) => {
	console.log("INGRESO generic_account_email_check");
	console.log({ dni });
	console.log({ email });
	const partes = dni.split("-");
	const parteNumerica = partes[1] ? partes[1] : dni;
	console.log({ parteNumerica });

	let url = `${HOST}${ENDPOINT.generic_account_email_check}?dni=${parteNumerica}&email=${email}&role=${rol}`;
	let response = await fetch(url, { headers: await generateAuthorizationHeader() })
		.then((data) => {
			console.log({ data });
			return data.json();
		})
		.catch((e) => {
			console.log(e);
		});
	if (response.statusCode == 200) {
		return response;
	} else {
		return response;
	}
};

/**
 *
 * @param {String} email
 * Patient generic_account_get
 * @returns
 */
export const generic_account_get = async (email, Id = "", rol, dni = "") => {
	console.time("generic_account_get - apiBackend.js");
	console.log("apiBackend.js", { email });
	console.log("apiBackend.js",{ Id });
	console.log("apiBackend.js",{ rol });
	console.log("apiBackend.js",{ dni });
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.generic_account_get}?email=${email}&Id=${Id}&role=${rol}&dni=${dni}`;
	
	try {

		// Código nuevo SALI 180724
		// Codigo viejo
		const response = await fetch(MIDDLEWARE_SERVICE, {
			method: "GET",
			headers: await generateAuthorizationHeader(),
		});
		
		if (!response.ok) {
			throw new Error(`Error al realizar la solicitud: ${response.status}`);
		}
		console.log("Account Get Response",response);

		const data = await response.json(); 
		
		console.log("Account Get Data",data);

		let resultStatusCode = false;

		if (data.statusCode == 200) {
			resultStatusCode = true
		} 

	 	if (!resultStatusCode) {
			console.log(data)
			throw new Error(`Error en la respuesta del servicio: ${data.statusCode}`);
		}
		
		let dataResponse = {};
	
		if (data.data?.hasOwnProperty("Especialidades__r")) {
			dataResponse = data.data;
		} else {
			dataResponse = data.data?.patient || {};
			dataResponse.Medico_cabecera = data.data?.Medico_cabecera;
		}

		return dataResponse;
	} catch (error) {
		console.error("Error en generic_account_get:", error);
		console.timeEnd("generic_account_get");
		throw error; // Relanzar el error para que sea manejado externamente
	}
};

/**
 *
 * @param {String} email
 * Caregiver
 * @returns
 */
// export const getCaregiverInfo = async (email) => {
// 	console.time("getCaregiverInfo");
// 	let caregiver = {};
// 	let url = `${HOST}${ENDPOINT.caregiver_getCaregiverInfo}?email=${email}`;
// 	return await fetch(url, { headers: await generateAuthorizationHeader() })
// 		.then((data) => {
// 			return data.json();
// 		})
// 		.then((resultado) => {
// 			if (resultado.statusCode == 200) {
// 				caregiver = resultado.data.caregiver[0];
// 			}
// 			console.timeEnd("getCaregiverInfo");
// 			return caregiver;
// 		})
// 		.catch((getDoctorInfoApiError) => {
// 			console.timeEnd("getCaregiverInfo");
// 		});
// };

// Get user info
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
/**--------------------------------------------------------------------------------------------------- */
// PDF

/**
 * PDF
 */
export const professional_medications_pdf_add = async (id_detalle, id_creator, lang) => {
	console.time("professional_medications_pdf_add");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_medications_pdf_add}?lang=${lang}`;
	let body = {
		id_detalle: id_detalle,
		id_creator: id_creator,
	};

	// NEW
	console.timeEnd("professional_medications_pdf_add");
	return await postServiceReturnData(MIDDLEWARE_SERVICE, body);
};

/**
 * PDF
 * [TEST]
 */
export const professional_exam_pdf_add = async (actividadCabeceraId, doctorId, aclaraciones, diagnostico) => {
	console.time("professional_exam_pdf_add");
	const MIDDLEWARE_SERVICE = `${HOST}${ENDPOINT.professional_exam_pdf_add}`;
	let body = {
		id_detalle: actividadCabeceraId,
		id_creator: doctorId,
		aclaraciones: aclaraciones,
		diagnostico: diagnostico,
	};

	// NEW
	console.timeEnd("professional_exam_pdf_add");
	return await postServiceReturnData(MIDDLEWARE_SERVICE, body);
};
